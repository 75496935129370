import { MagnifyingGlass } from '@phosphor-icons/react';
import { createId } from '@paralleldrive/cuid2';
import { useField } from 'formik';
import { useMemo, useState } from 'react';

import { Button } from '../../components/button/Button';
import { Combobox } from '../../components/combobox/Combobox';
import { InputWrapper } from '../../components/InputWrapper';
import { useGetLocationsQuery } from '../../generated/graphql';
import { getFullUrl } from '../../utils/url';
import { openPopup, openTab } from '../../utils/windows';

export type Location = {
  id: number;
  name: string;
  country: string;
  city: string;
};

export interface IMinimalLocation {
  id: number;
  name: string;
  country: string;
  city: string;
}

type ComboValue = IMinimalLocation | { id: 'new' };

const displayLocation = (location: ComboValue) => {
  if (location.id === 'new') {
    return 'Maak locatie aan';
  } else {
    return `${location.name} - ${location.city} ${location.country}`;
  }
};

const locationQueryContext = {
  suspense: false,
};

const createLocationPopup = (): Promise<IMinimalLocation> => {
  const popupId = createId();
  const targetUrl = getFullUrl(`/internal/locations/new?minimal=1&popup-id=${popupId}`);
  const targetWindow = openPopup(targetUrl);
  return new Promise((resolve, reject) => {
    if (targetWindow) {
      // eslint-disable-next-line no-restricted-globals
      window.addEventListener('message', (evt) => {
        const message = evt.data;
        if (typeof message === 'object' && message.id === popupId && message.type === 'created-location') {
          resolve(message.data);
        }
      });
    } else {
      reject(new Error('Could not spawn popup window'));
    }
  });
};

export interface ILocationComboboxProps {
  value: ComboValue | null;
  onChange: (val: IMinimalLocation | null) => void;
  onBlur?: () => void;
  invalidText?: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
  hideInvalid?: boolean;
  hideViewLocation?: boolean;
  isNullable?: boolean;
}

export const LocationCombobox: React.FC<ILocationComboboxProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    invalidText,
    labelText,
    helperText,
    isDisabled,
    hideViewLocation,
    isNullable = true,
    hideInvalid,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetLocationsQuery({
    variables: {
      filters: {
        search: searchValue,
        onlyActive: true,
      },
    },
    context: locationQueryContext,
  });

  const locations: ComboValue[] = useMemo(() => {
    const values: ComboValue[] = data?.locations ? [...data.locations] : [];
    values.push({
      id: 'new',
    });
    return values;
  }, [data?.locations]);

  const openLocation = (id: number) => openTab(getFullUrl(`/internal/locations/${id}/general`));
  return (
    <div className="flex gap-2">
      <InputWrapper
        labelText={labelText ?? 'Locatie'}
        invalidText={invalidText}
        helperText={helperText}
        hideInvalid={hideInvalid}
      >
        <Combobox
          keyName="id"
          placeholder="Zoek een locatie..."
          onQueryChange={setSearchValue}
          display={displayLocation}
          items={locations}
          selectedItem={value}
          onSelect={(val) => {
            if (val?.id !== 'new') {
              onChange(val);
            } else {
              createLocationPopup()
                .then((val) => {
                  onChange(val);
                })
                .catch(console.error);
            }
          }}
          onBlur={onBlur}
          isDisabled={isDisabled}
          isInvalid={!!invalidText}
          isNullable={isNullable}
        />
      </InputWrapper>
      {!!hideViewLocation && (
        <div className="flex items-center">
          <Button
            color="default"
            onTrigger={() => {
              if (value && value.id !== 'new') {
                openLocation(value.id);
              }
            }}
            isDisabled={!value}
          >
            <MagnifyingGlass className="button-icon" />
          </Button>
        </div>
      )}
    </div>
  );
};

export interface ILocationComboboxFieldProps {
  name: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const LocationComboboxField: React.FC<ILocationComboboxFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <LocationCombobox
      value={field.value}
      onChange={(val) => helpers.setValue(val)}
      onBlur={() => helpers.setTouched(true)}
      invalidText={(meta.touched && meta.error) || undefined}
      labelText={labelText}
      helperText={helperText}
      isDisabled={isDisabled}
    />
  );
};
