import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Button } from 'components/button/Button';
import { useUpdateGeneralSettingsMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAllGeneralSettings } from '../all-settings-context';
import { UserComboboxField } from 'src/app/user/components/UserCombobox';
import { captureException } from '@sentry/react';

const generalSettingsSchema = Yup.object().shape({
  defaultDealOwner: Yup.mixed().required('Vereist'),
});

interface IGeneralCustomerSettingsValues {
  defaultDealOwner: {
    id: string;
    name: string;
  } | null;
}

export const GeneralCustomerSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [_updateState, updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const initialValues: IGeneralCustomerSettingsValues = useMemo(() => {
    return {
      defaultDealOwner: settings.defaultDealOwner ?? null,
    };
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={generalSettingsSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const { defaultDealOwner } = patch;
          const result = await updateGeneralSettings({
            data: {
              defaultDealOwnerId: defaultDealOwner?.id,
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Instellingen aangepast');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon algemene instellingen niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <UserComboboxField labelText="Standaard deal eigenaar" name="defaultDealOwner" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<Plus className="button-icon" />}
            >
              Pas instellingen aan
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
