export function moveArrayElement<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
  if (!arr.length || fromIndex < 0 || fromIndex >= arr.length) {
    return arr; // Return original array if invalid input
  }

  if (toIndex < 0) {
    toIndex = 0;
  }

  if (toIndex >= arr.length) {
    toIndex = arr.length - 1;
  }

  const arrCopy = [...arr];
  const element = arrCopy[fromIndex];
  arrCopy.splice(fromIndex, 1);
  arrCopy.splice(toIndex, 0, element);

  return arrCopy;
}
