import { Disposable, DisposableStore } from '../utils/disposable';
import { Emitter } from '../utils/emitter';
import { IAckMessage, IErrorMessage, IMessage } from './types';

const TIMEOUT_MS = 10000;

enum WebsocketState {
  Connecting,
  Open,
  Closed,
}

/** Websocket client aimed at use in the browser */
export class WebsocketClient extends Disposable {
  // eslint-disable-next-line no-restricted-globals
  private ws: WebSocket;
  private buffer: IMessage[] = [];
  private state: WebsocketState = WebsocketState.Closed;

  private disposeWebsocket = new DisposableStore();
  private pingTimeoutRef: number | NodeJS.Timeout | null = null;
  private lastMessageAt: number = Date.now();

  private messageEmitter = new Emitter<IMessage>();
  onMessage = this.messageEmitter.event;

  private errorMessageEmitter = new Emitter<IErrorMessage>();
  onErrorMessage = this.errorMessageEmitter.event;

  constructor(private url: string) {
    super();

    this.state = WebsocketState.Connecting;
    this.ws = this.setupWebsocket();

    this.onDidDispose(() => {
      this.ws.close();
      this.buffer = [];
    });
  }

  scheduleNextPing() {
    if (this.pingTimeoutRef != null) {
      clearTimeout(this.pingTimeoutRef);
    }

    this.pingTimeoutRef = setTimeout(() => {
      if (this.state !== WebsocketState.Open) {
        console.log('Skipping ping, websocket is not open');
        this.scheduleNextPing();
      } else if (Date.now() - TIMEOUT_MS > this.lastMessageAt) {
        console.log('Websocket timed out, reconnecting');
        this.ws.close();
        this.state = WebsocketState.Closed;

        this.reconnect();
        this.scheduleNextPing();
      } else {
        this.ws.send('');
        this.scheduleNextPing();
      }
    }, 1000);
  }

  setupWebsocket() {
    // Clear previous listeners
    this.disposeWebsocket.dispose();

    this.disposeWebsocket = new DisposableStore();
    // eslint-disable-next-line no-restricted-globals
    this.ws = new WebSocket(this.url);

    const openListener = () => {
      console.log('ws opened');

      this.state = WebsocketState.Open;
      this.lastMessageAt = Date.now();
      this.scheduleNextPing();

      while (this.buffer.length) {
        const bufferedMessage = this.buffer.shift();
        if (!bufferedMessage) {
          break;
        }
        this.send(bufferedMessage);
      }
    };
    this.ws.addEventListener('open', openListener);
    this.disposeWebsocket.add(Disposable.create(() => this.ws.removeEventListener('open', openListener)));

    const closeListener = () => {
      console.log('ws closed');
      this.state = WebsocketState.Closed;

      // Reconnect if we should still be connected
      if (!this.isDisposed) {
        this.reconnect();
      }
    };
    this.ws.addEventListener('close', closeListener);
    this.disposeWebsocket.add(Disposable.create(() => this.ws.removeEventListener('close', closeListener)));

    const messageListener = (event: MessageEvent) => {
      this.lastMessageAt = Date.now();
      this.scheduleNextPing();

      // Skip empty/ping frames
      if (!event.data) return;

      try {
        const message = JSON.parse(event.data);
        if (message.method) {
          console.log('ws message received', message);
          const sendAck = () => {
            // Send the ack, closure ensures quick cleanup
            const ackMessage: IAckMessage = {
              ref: message.ref,
              method: message.method,
              ack: true,
            };
            this.ws.send(JSON.stringify(ackMessage));
          };

          if (message.error) {
            sendAck();
            this.errorMessageEmitter.fire(message);
          } else if (message.data) {
            sendAck();
            this.messageEmitter.fire(message);
          } else if (message.ack) {
            // Is an ack message
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    this.ws.addEventListener('message', messageListener);
    this.disposeWebsocket.add(Disposable.create(() => this.ws.removeEventListener('message', messageListener)));

    this.scheduleNextPing();

    return this.ws;
  }

  send(data: IMessage) {
    if (this.isDisposed) {
      throw new Error('WebsocketClient has been disposed, cannot send any more messages');
    }

    if (this.state !== WebsocketState.Open) {
      this.buffer.push(data);
      return;
    }

    this.ws.send(JSON.stringify(data));
  }

  reconnect() {
    if (this.state !== WebsocketState.Closed) {
      return;
    }

    this.state = WebsocketState.Connecting;
    setTimeout(() => {
      this.setupWebsocket();
    }, 1000);
  }
}
