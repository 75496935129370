import * as Dialog from '@radix-ui/react-dialog';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { BaseButtonProps } from '../../../../components/button/BaseButton';
import { ISimpleSelectItem } from '../../../../components/select/SimpleSelect';
import { useTranslation } from '../../../../contexts/translation-context';
import { OrderComplaintType, useInternalLogOrderComplaintMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { FormDialog } from '../../../../components/dialog/FormDialog';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { captureException } from '@sentry/react';

const schema = Yup.object().shape({
  type: Yup.object().required('errors.required'),
  content: Yup.string().required('errors.required'),
});

export interface IOrderComplaintDialogProps {
  orderId: string;
  triggerColor?: BaseButtonProps['color'];
  isDisabled?: boolean;
}

interface OrderComplaintDialogValues {
  type: ISimpleSelectItem;
  content: string;
}

export const LogOrderComplaintDialog: React.FC<IOrderComplaintDialogProps> = (props) => {
  const { orderId, triggerColor, isDisabled } = props;
  const { i18n } = useTranslation();
  const [, logComplaint] = useInternalLogOrderComplaintMutation();

  const handleSubmit = async (values: OrderComplaintDialogValues) => {
    try {
      const result = await logComplaint({
        orderId,
        data: {
          type: values.type.key as OrderComplaintType,
          content: values.content,
        },
      });

      if (result.error) {
        throw result.error;
      }

      toast.success('klacht gelogged');
    } catch (err) {
      captureException(err);
      console.error('Failed to log complaint', err);
      toast.error('Kon klacht niet loggen:' + getDisplayError(err));
    }
  };

  const items: ISimpleSelectItem[] = Object.values(OrderComplaintType).map((type) => ({
    key: type,
    name: i18n(`orderComplaintType.${type}`),
  }));

  const initialValues: OrderComplaintDialogValues = useMemo(() => {
    return {
      type: items.find((v) => v.key === OrderComplaintType.Other) || items[0],
      content: '',
    };
  }, []);

  return (
    <FormDialog
      triggerColor={triggerColor}
      isDisabled={isDisabled}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      triggerText="Log klacht"
      title="Log klacht"
      submitText="Log klacht"
    >
      <Dialog.Description className="mb-4">
        Vul het formulier in om een klacht te loggen voor deze order
      </Dialog.Description>

      <SimpleSelectField items={items} labelText="Reden" name="type" />

      <TextAreaField labelText="Bericht" name="content" />
    </FormDialog>
  );
};
