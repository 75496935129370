import { useField } from 'formik';
import { useClient } from 'urql';
import React, { useEffect, useState } from 'react';
import { IterableElement } from 'type-fest';
import * as Popover from '@radix-ui/react-popover';

import { InputField } from '../../../components/input/InputField';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { COUNTRY_VALUES } from '../../../utils/address';
import {
  FindPostalCodesDocument,
  FindPostalCodesQuery,
  FindPostalCodesQueryVariables,
} from '../../../generated/graphql';
import { useTranslation } from '../../../contexts/translation-context';
import { InputWrapper } from 'components/InputWrapper';
import classNames from '@utils/classnames';

type PostalCodeSuggestion = IterableElement<NonNullable<NonNullable<FindPostalCodesQuery>['findPostalCodes']>>;

export interface IAutocompletePostalcodeValues {
  postalCodeName?: string;
  cityName?: string;
  countryName?: string;
  onAutocomplete?: (postalCode: string, city: string, longitude: number, latitude: number) => void;
}

export const AutocompletePostalcode: React.FC<IAutocompletePostalcodeValues> = (props) => {
  const { postalCodeName = 'postalCode', cityName = 'city', countryName = 'country', onAutocomplete } = props;
  const client = useClient();
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<{
    requestKey: number;
    suggestions: PostalCodeSuggestion[];
  }>({ requestKey: 0, suggestions: [] });
  const { i18n } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  const postalCodeField = useField({ name: postalCodeName });
  const countryField = useField({ name: countryName });
  const cityField = useField({ name: cityName });

  const postalCode = postalCodeField[0].value;
  const country = countryField[0].value;
  useEffect(() => {
    const query = postalCode;
    const countryCode = country?.key;
    if (query && countryCode) {
      const requestKey = state.requestKey + 1;
      setState((val) => {
        return {
          ...val,
          requestKey,
        };
      });

      client
        .query<FindPostalCodesQuery, FindPostalCodesQueryVariables>(FindPostalCodesDocument, {
          query,
          country: countryCode,
        })
        .toPromise()
        .then((val) => {
          const suggestions = val.data?.findPostalCodes;
          setState((prev) => {
            if (prev.requestKey === requestKey) {
              return {
                ...prev,
                suggestions: suggestions ?? [],
              };
            } else {
              return prev;
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [postalCode, country]);

  const postalCodeError = postalCodeField[1].error;
  return (
    <div className="flex gap-4">
      <div style={{ flex: 1 }}>
        <SimpleSelectField
          labelText={i18n('customerPortal.locations.countryCode')}
          items={COUNTRY_VALUES}
          name={countryName}
        />
      </div>
      <div className="relative" style={{ flex: 1 }}>
        <InputWrapper labelText={i18n('customerPortal.locations.postalCode')} invalidText={postalCodeError}>
          <Popover.Root
            open={isOpen}
            onOpenChange={(open) => {
              setIsOpen(open);
            }}
          >
            <Popover.Trigger className="w-full">
              <div>
                <input
                  name={postalCodeName}
                  className={classNames(
                    'p-2 w-full flex bg-offwhite text-dark-01 border-dark-04 border rounded focus:outline-none',
                    {
                      'border-feedback-negative-04': !!postalCodeError,
                    },
                  )}
                  value={postalCodeField[0].value}
                  onChange={postalCodeField[0].onChange}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                  }}
                  onFocus={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();

                    setIsOpen(true);
                  }}
                  autoComplete="off"
                  ref={inputRef}
                />
              </div>
            </Popover.Trigger>

            <Popover.Portal>
              <Popover.Content align="start" autoFocus={false}>
                {state.suggestions.length > 0 && (
                  <div
                    className="absolute z-dropdown bg-white rounded-md focus:outline-none border border-dark-04 animate-in fade-in duration-200 overflow-auto max-h-60"
                    style={{
                      minWidth: 'var(--radix-popover-trigger-width)',
                      marginTop: '0.5rem',
                    }}
                  >
                    {state.suggestions.map((suggestion) => {
                      const label = `${suggestion.postalCode} - ${suggestion.placeName}`;
                      return (
                        <div
                          key={label}
                          className="hover:bg-dark-05 px-4 py-2 cursor-pointer"
                          onClick={() => {
                            postalCodeField[2].setValue(suggestion.postalCode);
                            cityField[2].setValue(suggestion.placeName);

                            onAutocomplete?.(
                              suggestion.postalCode,
                              suggestion.placeName,
                              suggestion.longitude,
                              suggestion.latitude,
                            );

                            setIsOpen(false);
                          }}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </InputWrapper>
      </div>
      <div style={{ flex: 2 }}>
        <InputField labelText={i18n('customerPortal.locations.city')} type="text" name={cityName} />
      </div>
    </div>
  );
};
