import { MagnifyingGlass } from '@phosphor-icons/react';
import { createId } from '@paralleldrive/cuid2';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import { IterableElement } from 'type-fest';

import { Button } from '../../components/button/Button';
import { Combobox } from '../../components/combobox/Combobox';
import { InputWrapper } from '../../components/InputWrapper';
import { GetSuppliersQuery, useGetSuppliersQuery } from '../../generated/graphql';
import { getFullUrl } from '../../utils/url';
import { openTab } from '../../utils/windows';

export type Supplier = IterableElement<GetSuppliersQuery['suppliers']>;

type ComboValue = Supplier | { id: 'new' };

const displaySupplier = (supplier: ComboValue) => {
  if (supplier.id === 'new') {
    return 'Maak vervoerder aan';
  } else {
    if (supplier.deactivationReason) {
      return `${supplier.name} (gedeactiveerd)`;
    }

    return supplier.name;
  }
};

const supplierQueryContext = {
  suspense: false,
};

export interface ISupplierComboboxFieldProps {
  name: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
  isNullable?: boolean;
  showDeactivated?: boolean;
}

const createSupplierPopup = (): Promise<Supplier> => {
  const popupId = createId();
  // eslint-disable-next-line no-restricted-globals
  const targetUrl = new URL(`/internal/suppliers/new?minimal=1&popup-id=${popupId}`, window.location.href).href;
  // eslint-disable-next-line no-restricted-globals
  const targetWindow = window.open(targetUrl, 'popup', 'width=800,height=800');
  return new Promise((resolve, reject) => {
    if (targetWindow) {
      // eslint-disable-next-line no-restricted-globals
      window.addEventListener('message', (evt) => {
        const message = evt.data;
        if (typeof message === 'object' && message.id === popupId && message.type === 'created-supplier') {
          resolve(message.data);
        }
      });
    } else {
      reject(new Error('Could not spawn popup window'));
    }
  });
};

export function SupplierComboboxField(props: ISupplierComboboxFieldProps) {
  const { name, helperText, isDisabled, isNullable, labelText, showDeactivated } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetSuppliersQuery({
    variables: {
      filters: {
        search: searchValue,
      },
    },
    context: supplierQueryContext,
  });

  const suppliers: ComboValue[] = useMemo(() => {
    const values: ComboValue[] = data?.suppliers
      ? [...data.suppliers].filter((v) => {
          if (v.deactivationReason && !showDeactivated) {
            return false;
          }

          return true;
        })
      : [];
    values.push({
      id: 'new',
    });
    return values;
  }, [data?.suppliers]);

  const [field, meta, helpers] = useField({ name });
  const selectedItem = field.value ?? null;

  const openSupplier = (id: number) => openTab(getFullUrl(`/internal/suppliers/${selectedItem.id}/general`));
  return (
    <div className="flex gap-2">
      <InputWrapper
        labelText={labelText ?? 'Vervoerder'}
        invalidText={meta.touched ? meta.error : undefined}
        helperText={helperText}
      >
        <Combobox
          keyName="id"
          placeholder="Zoek een vervoerder..."
          onQueryChange={setSearchValue}
          display={displaySupplier}
          items={suppliers}
          selectedItem={selectedItem}
          onSelect={(val) => {
            if (val?.id !== 'new') {
              helpers.setValue(val);
            } else {
              createSupplierPopup()
                .then((val) => {
                  helpers.setValue(val);
                })
                .catch(console.error);
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          isDisabled={isDisabled}
          isInvalid={Boolean(meta.touched && meta.error)}
          isNullable={isNullable}
        />
      </InputWrapper>
      <div className="flex items-center">
        <Button
          color="default"
          onTrigger={() => {
            if (selectedItem) {
              openSupplier(selectedItem.id);
            }
          }}
          isDisabled={!selectedItem}
        >
          <MagnifyingGlass className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}
