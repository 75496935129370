import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { FormikProvider, useFormik } from 'formik';
import { useUpdateOrderLinePurchaseMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { ProductTypeComboboxField, productTypeToComboboxItem } from '../../productType/ProductTypeComboboxField';
import { useOrderLine } from '../orderLine/orderLineContext';
import { OrderLinePurchase } from './OrderPurchases';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../vatRate/VatRateComboboxField';
import { captureException } from '@sentry/react';

const createOrderPurchaseSchema = Yup.object().shape({
  productType: Yup.mixed().nullable().required('Vereist'),
  vatRate: Yup.mixed().nullable().required('Vereist'),
  amount: Yup.number().required('Vereist'),
  unitPrice: Yup.number().required('Vereist'),
  externalNote: Yup.string().nullable(),
});

interface IOrderLinePurchaseValues {
  productType: ISimpleComboboxItem | null;
  vatRate: ISimpleComboboxItem | null;
  amount: string;
  unitPrice: string;
  externalNote: string;
}

interface IPageContentProps {
  orderPurchase: OrderLinePurchase;
}

const PageContent: React.FC<IPageContentProps> = (props) => {
  const { orderPurchase } = props;

  const navigate = useNavigate();
  const [, updateOrderPurchaseMutation] = useUpdateOrderLinePurchaseMutation();

  const initialValues: IOrderLinePurchaseValues = useMemo(() => {
    return {
      productType: productTypeToComboboxItem(orderPurchase.productType),
      vatRate: vatRateToComboboxItem(orderPurchase.vatRate),
      amount: formatNumber(orderPurchase.amount, 2),
      unitPrice: formatNumber(orderPurchase.unitPrice, 2),
      externalNote: orderPurchase.externalNote ?? '',
    };
  }, [orderPurchase]);

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrderPurchaseSchema,
    onSubmit: async (values) => {
      try {
        const patch: Partial<typeof values> = diff(initialValues, values);
        const { productType, vatRate, amount, unitPrice, ...otherValues } = patch;
        const result = await updateOrderPurchaseMutation({
          id: orderPurchase.id,
          data: {
            productTypeId: productType != null ? productType.key : undefined,
            vatRateId: vatRate != null ? vatRate.key : undefined,
            amount: amount != null ? parseNumberInput(amount, 2) : undefined,
            unitPrice: unitPrice != null ? parseNumberInput(unitPrice, 2) : undefined,
            ...otherValues,
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate('..');
        }
        toast.success('Aankoop aangepast');
      } catch (err: any) {
        captureException(err);
        toast.error('Kon aankoop niet aanpassen: ' + getDisplayError(err));
      }
    },
  });
  const { handleSubmit, isSubmitting, values } = formikbag;

  return (
    <>
      <h1 className="heading-one mb-4">Pas aankoop aan</h1>

      <FormikProvider value={formikbag}>
        <form onSubmit={handleSubmit}>
          <ProductTypeComboboxField labelText="Product" name="productType" />

          <div className="flex gap-2">
            <div style={{ flex: 2 }}>
              <InputField labelText="Aantal" type="number" name="amount" step="0.01" />
            </div>
            <div style={{ flex: 2 }}>
              <InputField labelText="Eenheidsprijs" type="number" name="unitPrice" step="0.01" />
            </div>
            <div style={{ flex: 3 }}>
              <VatRateComboboxField labelText="Btw Tarief" name="vatRate" />
            </div>
          </div>

          <InputField labelText="Extra info" type="text" name="externalNote" spellCheck={true} />

          <Button
            type="submit"
            color="primary"
            iconLeft={<Plus className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Pas aankoop aan
          </Button>
        </form>
      </FormikProvider>
    </>
  );
};

export const UpdateOrderPurchasePage = () => {
  const { orderLine } = useOrderLine();
  const { orderPurchaseId: _orderPurchaseId } = useParams<{ orderPurchaseId: string }>();
  invariant(_orderPurchaseId);
  const orderPurchaseId = +_orderPurchaseId;

  const orderPurchase = useMemo(() => {
    return orderLine.purchases.find((c) => c.id === orderPurchaseId);
  }, [orderLine, orderPurchaseId]);

  if (!orderPurchase) {
    return <div>Inhoud niet gevonden</div>;
  }

  return <PageContent orderPurchase={orderPurchase} />;
};
