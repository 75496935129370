import { Plus, Trash } from '@phosphor-icons/react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { ITableHeader, Table } from '../../../../components/table/Table';
import { TableActionBar } from '../../../../components/table/TableActionBar';
import { useDeleteOrderLineContentMutation } from '../../../../generated/graphql';
import { useUniqueArrayReducer } from '../../../../hooks/useUniqueArrayReducer';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { Order } from '../order/Order';
import { useOrderLine } from '../orderLine/orderLineContext';
import { LinkButton } from '../../../../components/button/ButtonLink';
import { captureException } from '@sentry/react';

export type OrderLineContent = IterableElement<IterableElement<Order['lines']>['contents']>;

const ORDERLINE_HEADERS: ITableHeader[] = [
  {
    id: 'cargoType',
    name: 'Aard',
  },
  {
    id: 'loadingMeters',
    name: 'LM',
  },
  {
    id: 'packages',
    name: 'Coli',
  },
  {
    id: 'volume',
    name: 'Volume',
  },
  {
    id: 'weight',
    name: 'Gewicht',
  },
  {
    id: 'delete',
    name: '',
  },
];

const OrderLineContentsPage = () => {
  const { orderLine } = useOrderLine();
  const [_res, deleteOrderLineContent] = useDeleteOrderLineContentMutation();
  const [pendingDeletions, mutatePendingDeletion] = useUniqueArrayReducer<number>();

  const contents = orderLine.contents;

  return (
    <div>
      <TableActionBar>
        <div className="flex justify-end">
          <div className="flex">
            <div>
              <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                Voeg inhoud toe
              </LinkButton>
            </div>
          </div>
        </div>
      </TableActionBar>
      <Table
        idKey="id"
        headers={ORDERLINE_HEADERS}
        data={contents}
        mapData={(data) => {
          const deleteLine = async () => {
            mutatePendingDeletion({
              type: 'add',
              value: data.id,
            });
            try {
              const result = await deleteOrderLineContent({
                id: data.id,
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Order inhoud gewist');
            } catch (err) {
              captureException(err);
              toast.error('Kon order inhoud niet wissen: ' + getDisplayError(err));
            }
            mutatePendingDeletion({
              type: 'delete',
              value: data.id,
            });
          };

          return [
            <Link to={`${data.id}`} className="link-text">
              {data.cargoType.nameNl}
            </Link>,
            <div className="whitespace-nowrap">
              {`${formatNumber(data.loadingMeters, 2, {
                decimalSeperator: ',',
              })} m`}
            </div>,
            <div className="whitespace-nowrap">{`${formatNumber(data.packages, 2, {
              decimalSeperator: ',',
            })} ${data.packageType.id}`}</div>,
            <div className="whitespace-nowrap">{`${formatNumber(data.volume, 2, {
              decimalSeperator: ',',
            })} m3`}</div>,
            <div className="whitespace-nowrap">{`${formatNumber(data.weight, 2, {
              decimalSeperator: ',',
            })} kg`}</div>,
            <ConfirmDialog
              triggerColor="danger"
              triggerText={<Trash className="button-icon" />}
              title="Verwijder inhoud"
              submitText="Verwijder"
              description={<div>{`Ben je zeker dat je deze inhoud lijn ${data.cargoType.nameNl} wilt wissen?`}</div>}
              onSubmit={deleteLine}
            />,
          ];
        }}
        isMultiSelect
      />
    </div>
  );
};

export default OrderLineContentsPage;
