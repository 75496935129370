import { PageHeader } from '../../../components/PageHeader';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { LinkCard } from 'components/Card';

export const FinancePage = () => {
  return (
    <>
      <PageHeader title="Financieel" />

      <PageHeading leftSide={<Breadcrumb currentItem="Financieel" />} />

      <div className="px-4">
        <div className="grid lg:grid-cols-3 gap-4">
          <LinkCard title="Verkopen" to="sales" />
          <LinkCard title="Offertes" to="quotations" />
          <LinkCard title="Offerte aanvragen" to="quotation-requests" />
          <LinkCard title="Credit Notas" to="creditnotes" />
          <LinkCard title="Aankopen" to="purchases" />
          <LinkCard title="Self Billing" to="self-billing" />
          <LinkCard title="Debiteurenbeheer" to="payment-reminder" />
        </div>
      </div>
    </>
  );
};
