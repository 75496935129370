// Never import any server-only code here, this is also used on the client side...
import type { TranslationsMap } from './types';

// German version of the translation file
export const translations: TranslationsMap = {
  emails: {
    footer: {
      kindRegards: 'Mit freundlichen Grüßen,',
      questionsOrProblems: 'Fragen oder Probleme?',
      contactUs: 'Kontaktieren Sie uns',
    },
    transportOrder: {
      confirm: 'Bestätigen',
      refuse: 'Ablehnen',
    },
    forgotPassword: {
      resetLink: 'Passwort zurücksetzen',
    },
    userInvite: {
      view: 'Einladung ansehen',
    },
    attachments: {
      quotation: 'Zitat {{reference}}',
    },
    customerComplaintAlert: {
      order: 'Bestellung anzeigen',
    },
  },
  truckDocumentType: {
    Insurance: 'Versicherung',
    Examination: 'Untersuchung',
  },
  trailerTypes: {
    BoxTrailer: 'Kofferanhänger',
    FlatBed: 'Flachbett',
    Mega: 'Mega-Anhänger',
    Refrigerated: 'Kühlanhänger',
    RoadTrain: 'Straßenzug',
    Tautliner: 'Tautliner',
    Joloda: 'Tautliner / Joloda',
  },
  documents: {
    note: {
      extraInformation: 'Zusätzliche Informationen',
    },
    letter: {
      letter: 'Brief',
    },
    paymentReminder: {
      invoiceNumber: 'Rechnungsnummer',
      invoiceDate: 'Rechnungsdatum',
      dueDate: 'Fälligkeitsdatum',
      amount: 'Betrag',
      expiredAmount: 'Überfälliger Betrag',
      currency: 'Währung',
    },
    transportOrder: {
      trailerTypes: 'Anhängertypen',
      transportOrder: 'Transportauftrag',
      date: 'Datum',
      orderRef: 'Referenz der Bestellung',
      load: 'Laden',
      unload: 'Entladen',
      reference: 'Referenz',
      goods: 'Güter',
      description: 'Beschreibung',
      packageType: 'Palletentyp',
      palletCount: 'Paletten',
      weight: 'Gewicht',
      LoadingMeter: 'Lademeter',
      freightRate: 'Frachttarif',
      price: 'Preis',
      vat: 'MwSt',
      questionsOrComments: 'Fragen oder Anmerkungen?',
      contactFileManager: 'Zögern Sie nicht, Ihren Dateimanager zu kontaktieren',
      anonymousCmr: 'FÜR DIESE BESTELLUNG MUSS EIN ANONYMER CMR ERSTELLT WERDEN.',
      phone: 'Telefon',
      email: 'E-mail',
    },
    planning: {
      planning: 'Planung',
      licensePlate: 'Kennzeichen',
      codeName: 'Codename',
    },
    quotation: {
      quotation: 'Zitat',
      customerNumber: 'Kundennummer',
      reference: 'Referenz',
      date: 'Angebotsdatum',
      dueDate: 'Verfallsdatum',
      from: 'Von',
      to: 'Zu',
      volume: 'Volumen',
      price: 'Preis',
      generalTermsAndConditions: 'Allgemeine Geschäftsbedingungen im Anhang',
      questionsOrComments: 'Bei Fragen kontaktieren Sie uns bitte unter {{email}}',
      trailerTypes: 'Anhängertypen',
    },
    orderConfirmation: {
      orderConfirmation: 'Bestellbestätigung',
      dearCustomer: 'Sehr geehrter Kunde,',
      herebyWeConfirmYourOrder: 'Hiermit bestätigen wir Ihre Bestellung für die folgende Lieferung:',
      date: 'Datum',
      ourReference: 'Unsere Referenz',
      yourReference: 'Ihre Referenz',
      load: 'Laden',
      unload: 'Entladen',
      reference: 'Referenz',
      goods: 'Güter',
      description: 'Beschreibung',
      packageType: 'Palletentyp',
      palletCount: 'Paletten',
      weight: 'Gewicht',
      LoadingMeter: 'Lademeter',
      freightRate: 'Frachttarif',
      price: 'Preis',
      vat: 'MwSt',
      questionsOrComments: 'Fragen oder Anmerkungen?',
      contactFileManager: 'Zögern Sie nicht, Ihren Dateimanager zu kontaktieren',
      phone: 'Telefon',
      email: 'E-mail',
    },
    orderCancellation: {
      orderCancellation: 'Stornierung der Bestellung',
      dearCustomer: 'Sehr geehrter Kunde,',
      orderCancelledText: 'Ihre Bestellung für den unten aufgeführten Transport wurde hiermit storniert:',
      date: 'Datum',
      ourReference: 'Unsere Referenz',
      yourReference: 'Ihre Referenz',
      load: 'Laden',
      unload: 'Entladen',
      reference: 'Referenz',
      goods: 'Güter',
      description: 'Beschreibung',
      packageType: 'Palletentyp',
      palletCount: 'Paletten',
      weight: 'Gewicht',
      LoadingMeter: 'Lademeter',
      freightRate: 'Frachttarif',
      price: 'Preis',
      vat: 'MwSt',
      questionsOrComments: 'Fragen oder Anmerkungen?',
      contactFileManager: 'Zögern Sie nicht, Ihren Dateimanager zu kontaktieren',
      phone: 'Telefon',
      email: 'E-mail',
    },
    invoice: {
      invoice: 'Rechnung',
      customerNumber: 'Kundennummer',
      invoiceNumber: 'Rechnungsnummer',
      invoiceDate: 'Rechnungsdatum',
      dueDate: 'Fälligkeitsdatum',
      load: 'Laden',
      unload: 'Entladen',
      ourReference: 'Unsere Referenz',
      yourReference: 'Ihre Referenz',
      description: 'Beschreibung',
      quantity: 'Menge',
      unitPrice: 'Einzelpreis',
      total: 'Gesamt',
      vat: 'MwSt',
      paymentInformation: 'Zahlungsinformationen',
      amountToPay: 'Zu zahlender Betrag',
      toBePaidBefore: 'Vorher zu bezahlen',
      beneficiary: 'Begünstigter',
      message: 'Mitteilung',
      generalTermsAndConditions: 'Allgemeine Geschäftsbedingungen im Anhang',
      questionsOrComments: 'Bei Fragen wenden Sie sich bitte an die Buchhaltung unter {{email}}',
    },
    sustainabilityReport: {
      sustainabilityReport: 'Nachhaltigkeitsbericht',
      date: 'Datum',
      ourReference: 'Unsere Referenz',
      yourReference: 'Ihre Referenz',
      truck: 'Truck',
      distance: 'Entfernung',
      weight: 'Gewicht',
      emissions: 'Emissionen',
      questionsOrComments: 'Fragen oder Anmerkungen?',
      contactFileManager: 'Zögern Sie nicht, Ihren Dateimanager zu kontaktieren',
      phone: 'Telefon',
      email: 'E-mail',
    },
    globalSustainabilityReport: {
      sustainabilityReport: 'Nachhaltigkeitsbericht',
      date: 'Datum',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      orderCount: 'Anzahl Bestellungen',
      truck: 'Lastwagen',
      totals: 'Gesamt',
      distance: 'Entfernung',
      weight: 'Gewicht',
      emissions: 'CO2-Emissionen',
      averagePerOrder: 'Durchschnitt pro Bestellung',
      framework: 'Einzelheiten zur Emissionsberechnung finden Sie in der Nachhaltigkeitsrichtlinie auf unserer Website.',
      questionsOrComments: 'Fragen oder Anmerkungen?',
      contactFileManager: 'Kontaktieren Sie gerne Ihren Sachbearbeiter',
      phone: 'Tel.',
      email: 'E-Mail',
    },
    selfBill: {
      title: 'Facture automatique',
      cashDiscount: 'Skonto 2,5%',
      taxableAmount: 'Steuerpflichtiger Betrag',
      totalWithDiscount: 'Total mit Rabatt-Bargeld',
      totalWithoutDiscount: 'Total ohne Rabatt-Bargeld',
      totalTitleWithDiscount: 'Barzahlung an 7 Tagen (2,5%)',
      totalTitleWithoutDiscount: 'Zahlung innerhalb von 60 Tagen',
      or: 'ODER',
    },
    creditNote: {
      creditNote: 'Gutschrift',
      customerNumber: 'Kundennummer',
      creditNoteNumber: 'Gutschriftsnummer',
      date: 'Datum',
      dueDate: 'Fälligkeitsdatum',
      load: 'Laden',
      unload: 'Entladen',
      ourReference: 'Unsere Referenz',
      yourReference: 'Ihre Referenz',
      description: 'Beschreibung',
      quantity: 'Menge',
      unitPrice: 'Einzelpreis',
      total: 'Gesamt',
      vat: 'MwSt',
      paymentInformation: 'Zahlungsinformationen',
      amountToPay: 'Zu zahlender Betrag',
      toBePaidBefore: 'Vorher zu bezahlen',
      payer: 'Zahler',
      message: 'Mitteilung',
      generalTermsAndConditions: 'Allgemeine Geschäftsbedingungen im Anhang',
      questionsOrComments: 'Bei Fragen wenden Sie sich bitte an die Buchhaltung unter {{email}}',
    },
    totals: {
      totalExclVat: 'Gesamt exkl. MwSt',
      totalVat: 'Gesamt MwSt',
      totalInclVat: 'Gesamt inkl. MwSt',
      vatReverseCharged: 'VAT Reverse Charge, article 44 VAT Directive',
    },
  },
  pages: {
    confirmOrderStates: {
      headingRefused: 'Bestellung wurde abgelehnt',
      headingCancelled: 'Bestellung wurde storniert',
      headingConfirmed: 'Bestellung wurde bestätigt',
      headingUnknownStatus: 'Auftrag wurde bereits ausgeführt oder Status unbekannt',
    },
    confirmOrderPlanningStates: {
      headingRefused: 'Die Terminvereinbarung wurde abgelehnt',
      headingCancelled: 'Der Zeitplan wurde abgesagt',
      headingConfirmed: 'Der Zeitplan wurde bestätigt',
      headingUnknownStatus: 'Die Planung wurde bereits durchgeführt oder der Status ist unbekannt',
    },
    confirmOrder: {
      heading: 'Transportauftrag bestätigen {{order.orderNumber}}',
      orderConfirmed: 'Bestellung bestätigt',
      orderConfirmationError: 'Bestellung konnte nicht bestätigt werden: {{error}}',
      confirmButton: `Transportauftrag bestätigen`,
    },
    confirmPlanning: {
      heading: 'Bestätigen Sie den Zeitplan {{planningDate}}',
      planningConfirmed: 'Zeitplan bestätigt',
      confirmButton: `Bestätigen Sie den Zeitplan`,
    },
    refuseOrder: {
      heading: 'Transportauftrag verweigern {{order.orderNumber}}',
      orderRefused: 'Bestellung abgelehnt',
      orderRefusalError: 'Bestellung konnte nicht abgelehnt werden: {{error}}',
      refuseButton: `Transportauftrag verweigern`,
    },
    refusePlanning: {
      heading: 'Abfallplanung {{planningDate}}',
      planningRefused: 'Terminplanung abgelehnt',
      refuseButton: 'Abfallplanung',
    },
    orderSummary: {
      loadAndUnloadInformation: 'Informationen zum Laden und Entladen',
      load: 'Laden',
      unload: 'Entladen',
      freightRate: 'Frachttarif',
      total: 'Gesamt',
    },
  },
  customerPortal: {
    requestAccount: {
      title: 'Konto anfordern',
      success: 'Anfrage wurde gesendet, wir werden Sie für weitere Schritte kontaktieren.',
      failed: 'Konto konnte nicht angefordert werden: {{error}}',
      name: 'Name',
      email: 'E-Mail',
      companyName: 'Firmenname',
      companyNumber: 'Firmennummer',
      request: 'Anfordern',
      dontHaveAnAccount: 'Sie haben noch kein Konto?',
    },
    invite: {
      title: 'Einladung zum Kundenportal',
      details:
        'Sie wurden zum Kundenportal von TransDirect eingeladen, erstellen Sie Ihr Konto, indem Sie Ihr Passwort eingeben.',
      accepted: 'Einladung wurde akzeptiert, Sie werden in Kürze zur Startseite weitergeleitet.',
      acceptError: 'Einladung konnte nicht angenommen werden: {{error}}',
      acceptButtonText: 'Einladung annehmen',
      password: 'Passwort',
      repeatPassword: 'Passwort wiederholen',
    },
    home: {
      customerPortal: 'Kundenportal',
      title: 'Kundenportal von {{customerName}}',
      hello: 'Hallo,',
      welcome: 'Willkommen im Kundenportal.',
      manageOrders: 'Verwalten Sie alle Ihre Transportaufträge.',
      viewInvoices: 'Rechnungen einfach einsehen und bezahlen.',
      createQuotes: 'Angebote erstellen und verfolgen.',
      viewLocations: 'Alle Transportstandorte anzeigen.',
    },
    locations: {
      locations: 'Orte',
      name: 'Name',
      street: 'Straße',
      number: 'Nummer',
      countryCode: 'Ländercode',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      country: 'Land',
      language: 'Sprache',
      notes: 'Notizen',
      createLocation: 'Ort erstellen',
      newLocation: 'Neuer Ort',
      locationCreated: 'Ort erstellt',
      couldNotCreateLocation: 'Ort konnte nicht erstellt werden: {{error}}',
      findLocation: 'Ort suchen...',
      address: 'Adresse',
      locationNotFound: 'Ort nicht gefunden',
      couldNotLoadLocation: 'Ort konnte nicht geladen werden: {{error}}',
    },
    order: {
      duplicate: 'Duplizieren',
      overview: 'Übersicht',
    },
    orders: {
      orders: 'Bestellungen',
      placeOrder: 'Bestellung aufgeben',
      invoiceRef: 'Rechnungsreferenz',
      orderRef: 'Auftragsreferenz',
      loadLocation: 'Ladeort',
      unloadLocation: 'Entladeort',
      loadDate: 'Ladedatum',
      loadTime: 'Ladezeit',
      unloadDate: 'Entladedatum',
      unloadTime: 'Entladezeit',
      invoiceTotal: 'Rechnungsbetrag',
      status: 'Status',
      invoiceStatus: 'Rechnungsstatus',
      hasCMR: 'Hat CMR',
      creationDate: 'Erstellungsdatum',
      executionDate: 'Ausführungsdatum',
      fileManager: 'Dateimanager',
      cancellationReason: 'Stornierungsgrund',
      stops: 'Stopps',
      deleteStop: 'Stopp löschen',
      purchases: 'Einkäufe',
      total: 'Gesamt',
      trailerTypes: 'Anhängertypen',
      extraNotes: 'Zusätzliche Notizen',
      noContent: 'Kein Inhalt',
      packaging: 'Verpackung',
      coli: 'Coli',
      content: 'Inhalt',
      addContent: 'Inhalt hinzufügen',
      deleteContent: 'Inhalt löschen',
      noStops: 'Keine Stopps',
      noStopsError: 'Fügen Sie mindestens einen Be- und Entladehalt hinzu',
      reference: 'Referenz',
      complaints: 'Beschwerden',
      fileComplaint: 'Beschwerde melden',
      fileComplaintDescription: 'Füllen Sie dieses Formular aus, um eine Beschwerde melden',
      send: 'Senden',
      reason: 'Grund',
      details: 'Details',
      complaintFiled: 'Beschwerde eingereicht',
      couldNotFileComplaint: 'Konnte Beschwerde nicht einreichen: {{error}}',
      placeAnOrder: 'Bestellung aufgeben',
      assignment: 'Auftrag',
      addStop: 'Stopp hinzufügen',
      location: 'Standort',
      date: 'Datum',
      start: 'Start',
      stop: 'Stopp',
      weight: 'Gewicht (kg)',
      volume: 'Volumen (m3)',
      loadMeter: 'Lademeter',
      stopsRequired: 'Mindestens 1 Stopp ist erforderlich',
      contentRequired: 'Mindestens 1 Inhaltselement ist erforderlich',
      truck: 'Truck',
      distance: 'Entfernung',
      emissions: 'Emissionen',
      sustainability: 'Nachhaltigkeit',
    },
    quotes: {
      quotes: 'Angebote',
      quote: 'Angebot',
      quoteReference: 'Angebotsreferenz',
      quoteDate: 'Angebotsdatum',
      expirationDate: 'Verfallsdatum',
      total: 'Gesamt',
      search: 'Suche nach einem Angebot...',
      quotation: 'Angebot {{reference}}',
      notFound: 'Angebot nicht gefunden',
      departurePlace: 'Abfahrtsort',
      destination: 'Ziel',
      distance: 'Entfernung',
      price: 'Preis',
      type: 'Type',
      requests: 'Angebote anfordern',
      request: 'Angebot anfordern',
      notes: 'Notizen',
      lines: 'Angebotszeilen',
      addLine: 'Zeile hinzufügen',
      noLines: 'Keine Angebotszeilen',
      requestTitle: 'Angebot anfordern für {{reference}}',
      requestSuccess: 'Angebot angefordert',
      requestFailed: 'Angebot konnte nicht angefordert werden: {{error}}',
      quotationRequests: 'Angebotsanfragen',
      hasQuote: 'Hat Angebot',
      requestedAt: 'Angefordert am',
      generalInformation: 'Allgemeine Informationen',
      removeLine: 'Zeile entfer nen',
    },
    quoteLine: {
      from: 'Von',
      to: 'Nach',
    },
    invoices: {
      invoices: 'Rechnungen',
      invoiceNumber: 'Rechnungsnummer',
      invoiceDate: 'Rechnungsdatum',
      dueDate: 'Fälligkeitsdatum',
      total: 'Gesamt',
      search: 'Suche nach einer Rechnung...',
      notFound: 'Rechnung nicht gefunden',
      invoice: 'Rechnung {{invoiceNumber}}',
      structuredComment: 'Strukturierter Kommentar',
      reference: 'Referenz',
      sentAt: 'Gesendet am',
      load: 'Beladung',
      unload: 'Entladung',
      description: 'Beschreibung',
      amount: 'Menge',
      unitPrice: 'Stückpreis',
      vat: 'MwSt',
      extraInformation: 'Zusatzinformationen: {{extraInformation}}',
      orderTotal: 'Gesamtbestellwert: €{{total}}',
      totalExclVat: 'Gesamt ohne MwSt:',
      totalVat: 'MwSt gesamt:',
      totalInclVat: 'Gesamtbetrag inkl. MwSt:',
      generalInformation: 'Allgemeine Informationen',
    },
    creditNotes: {
      creditNotes: 'Gutschriften',
      creditNoteNumber: 'Gutschriftsnummer',
      creditNoteDate: 'Gutschriftsdatum',
      dueDate: 'Fälligkeitsdatum',
      total: 'Gesamt',
      search: 'Gutschrift suchen...',
      notFound: 'Gutschrift nicht gefunden',
      creditNote: 'Gutschrift {{creditNoteNumber}}',
      structuredComment: 'Strukturierte Mitteilung',
      reference: 'Referenz',
      sentAt: 'Gesendet am',
      load: 'Laden',
      unload: 'Entladen',
      description: 'Beschreibung',
      amount: 'Menge',
      unitPrice: 'Stückpreis',
      vat: 'MwSt.',
      extraInformation: 'Zusätzliche Informationen: {{extraInformation}}',
      orderTotal: 'Bestellsumme: €{{total}}',
      totalExclVat: 'Gesamtbetrag exkl. MwSt.:',
      totalVat: 'MwSt. Gesamt:',
      totalInclVat: 'Gesamtbetrag inkl. MwSt.:',
      generalInformation: 'Allgemeine Informationen'
    },
    businessInformation: {
      businessInformation: 'Unternehmensinformationen',
      updateBusinessInformation: 'Unternehmensinformationen aktualisieren',
      name: 'Name',
      vatNumber: 'Umsatzsteuer-Identifikationsnummer',
      businessNumber: 'Unternehmensnummer',
      saveChanges: 'Änderungen speichern',
      informationChanged: 'Informationen wurden aktualisiert',
      informationNotChanged: 'Informationen konnten nicht aktualisiert werden: {{error}}',
      requestChanges:
        'Um den Firmennamen, die USt-IdNr. oder die Handelsregisternummer zu aktualisieren, kontaktieren Sie uns bitte.',
    },
    ourInformation: {
      ourInformation: 'Unsere Informationen',
      name: 'Name',
      vatNumber: 'Umsatzsteuer-ID',
      businessNumber: 'Handelsregisternummer',
      address: 'Adresse',
      contact: 'Kontakt',
      phoneNumber: 'Telefonnummer',
      emailTransport: 'Transport E-Mail',
      emailAccounting: 'Buchhaltung E-Mail',
      emailGeneral: 'Allgemeine E-Mail',
    },    
    contacts: {
      contacts: 'Kontakte',
      name: 'Name',
      email: 'E-Mail',
      phone: 'Telefon',
      function: 'Funktion',
      language: 'Sprache',
      notifications: 'Benachrichtigungen',
      newContact: 'Neuer Kontakt',
      contactCreated: 'Kontakt erstellt',
      couldNotCreateContact: 'Konnte Kontakt nicht erstellen: {{error}}',
      createContact: 'Kontakt erstellen',
      notFound: 'Kontakt nicht gefunden',
      updatedContact: 'Kontakt aktualisiert',
      couldNotUpdateContact: 'Konnte Kontakt nicht aktualisieren: {{error}}',
      updateContact: 'Kontakt aktualisieren',
      delete: 'Löschen',
      deleteContact: 'Kontakt löschen',
      deleteContactConfirmation: 'Sind Sie sicher, dass Sie den Kontakt {{name}} löschen möchten?',
      deleteContactError: 'Kontakt konnte nicht gelöscht werden: {{error}}',
      deleteContactSuccess: 'Kontakt gelöscht',
    },
    users: {
      users: 'Benutzer',
      inviteUser: 'Benutzer einladen',
      invitedUsers: 'Eingeladene Benutzer',
      noInvitedUsers: 'Keine eingeladenen Benutzer',
      removeInvite: 'Einladung entfernen',
      confirmRemoveInvite: 'Sind Sie sicher, dass Sie die Einladung für {{email}} entfernen möchten?',
      inviteRemoved: 'Einladung entfernt',
      couldNotRemoveInvite: 'Einladung konnte nicht entfernt werden: {{error}}',
      noUsersFound: 'Keine Benutzer gefunden',
      removeUser: 'Benutzer entfernen',
      confirmRemoveUser: 'Sind Sie sicher, dass Sie den Benutzer {{name}} entfernen möchten?',
      userRemoved: 'Benutzer entfernt',
      couldNotRemoveUser: 'Benutzer konnte nicht entfernt werden: {{error}}',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      userInvited: 'Benutzer eingeladen',
      couldNotInviteUser: 'Benutzer konnte nicht eingeladen werden: {{error}}',
      name: 'Name',
      email: 'E-Mail',
      isActive: 'Ist aktiv?',
    },
    myProfile: {
      myProfile: 'Mein Profil',
      logout: 'Abmelden',
      name: 'Name',
      role: 'Rolle',
      email: 'E-Mail',
      language: 'Sprache',
      languageChanged: 'Sprache aktualisiert',
      languageNotChanged: 'Sprache konnte nicht aktualisiert werden: {{error}}',
      joinedAt: 'Beigetreten am',
      nameChanged: 'Name wurde aktualisiert',
      nameNotChanged: 'Name konnte nicht aktualisiert werden: {{error}}',
    },
    cmrReminder: {
      cmrReminder: 'CMR Erinnerung',
      sendReminder: 'Senden',
      confirmText: 'Sind Sie sicher, dass Sie eine CMR Erinnerung senden möchten?',
      confirmation: 'CMR Erinnerung gesendet',
      error: 'Konnte CMR Erinnerung nicht senden: {{error}}',
    },
    sustainability: {
      sustainability: 'Nachhaltigkeit',
      policy: 'Nachhaltigkeitspolitik',
      viewOverview: 'Nachhaltigkeit ubersicht anzeigen',
      orderCount: 'Anzahl der Bestellungen',
      totalDistance: 'Gesamtstrecke',
      totalWeight: 'Gesamtgewicht',
      totalEmissions: 'Gesamte CO2-Emissionen',
      averageDistance: 'Durchschnittliche Entfernung',
      averageWeight: 'Durchschnittsgewicht',
      averageEmissions: 'Durchschnittliche Emissionen',
    },
    loading: 'Laden...',
  },
  orderStatus: {
    Draft: 'Entwurf',
    Created: 'Erstellt',
    Placed: 'Platziert',
    Confirmed: 'Bestätigt',
    Refused: 'Abgelehnt',
    Executed: 'Ausgeführt',
    Cancelled: 'Storniert',
  },
  orderSaleStatus: {
    Created: 'Erstellt',
    Credited: 'Gutschrift erteilt',
    Incomplete: 'Unvollständig',
    Invoiced: 'In Rechnung gestellt',
  },
  customerContactNotifications: {
    invoice: 'Rechnung',
    orderConfirmation: 'Auftragsbestätigung',
    cmr: 'CMR',
    quotation: 'Angebot',
    dieselSurcharge: 'Dieselzuschlag',
    paymentReminder: 'Zahlungserinnerung',
  },
  errors: {
    required: 'Erforderlich',
    invalidEmail: 'Ungültige E-Mail',
    dateShouldBeInTheFuture: 'Datum sollte in der Zukunft liegen',
    startTimeShouldBeBeforeEndTime: 'Startzeit sollte vor der Endzeit liegen',
    passwordsDontMatch: 'Passwort und Passwortwiederholung stimmen nicht überein.',
    passwordTooShort: 'Das Passwort ist zu kurz.',
  },
  orderComplaintType: {
    Aggression: 'Körperliche/Verbale Aggression',
    DamagedGoods: 'Beschädigte Ware',
    IncorrectDelivery: 'Falsche Lieferung',
    IncorrectInvoice: 'Falsche Rechnung',
    LateDelivery: 'Verspätete Lieferung',
    Other: 'Andere',
  },
  auth: {
    forgotPassword: {
      title: 'Passwort vergessen',
      requestNewPassword: 'Neues Passwort anfordern',
      instructions:
        'Geben Sie Ihre E-Mail-Adresse ein, wenn der Benutzer im System existiert, erhalten Sie in Kürze eine E-Mail, um ein neues Passwort einzurichten.',
      requestFailed: 'Konnte kein neues Passwort anfordern',
      instructionsSent: 'Anweisungen zum Einrichten eines neuen Passworts wurden an Ihre E-Mail-Adresse gesendet.',
    },
    configNewPassword: {
      title: 'Neues Passwort festlegen',
      newPassword: 'Neues Passwort',
      repeatNewPassword: 'Neues Passwort wiederholen',
      confirmButtonText: 'Neues Passwort festlegen',
      passwordDontMatch: 'Passwort und wiederholtes Passwort müssen übereinstimmen',
      tokenNotFound: 'Token nicht gefunden',
      invalidToken: 'Token ist nicht mehr gültig',
      configFailed: 'Konnte Passwort nicht festlegen: {{error}}',
    },
    login: {
      login: 'Login',
      email: 'E-Mail',
      password: 'Passwort',
      forgotPassword: 'Passwort vergessen',
      customerLoginTitle: 'Kunden Login',
      loginWithGoogle: 'Mit Google anmelden',
      incorrectCredentials: 'E-Mail oder Passwort ist falsch',
      loginFailed: 'Anmeldung fehlgeschlagen: {{error}}',
    },
    userNotFound: {
      title: 'Benutzer nicht gefunden',
      description: 'Anmeldung fehlgeschlagen, ein Fehler ist aufgetreten oder der Benutzer existiert nicht mehr.',
      goToLoginPage: 'Zur Anmeldeseite gehen',
    },
  },
  or: 'oder',
  cancel: 'Abbrechen',
  close: 'Schließen',
  moreInfo: 'Mehr Info',
  previous: 'Vorherige',
  next: 'Nächste',
  delete: 'Löschen',
};
