import { CalendarDots, Check, Clock, Trash } from '@phosphor-icons/react';
import { PageHeader } from 'components/PageHeader';
import {
  GetPurchaseQuery,
  OrderDocumentType,
  OrderLineStopType,
  useDeletePurchaseMutation,
  useGetPurchaseQuery,
  useUpdatePurchaseMutation,
} from 'generated/graphql';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';
import classNames from '@utils/classnames';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import diff from 'object-diff';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { DataField } from '../../../../components/DataField';
import { formatDate, formatInputDate, formatInputTime } from '../../../../utils/date';
import { extractIntFromText, formatNumber } from '../../../../utils/number';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { calculateInvoiceTotals } from '../../../sale/utils/calculate';
import { getDisplayError } from '../../../../utils/get-display-error';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { InputField } from '../../../../components/input/InputField';
import { Button } from '../../../../components/button/Button';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';

const updatePurchaseSchema = Yup.object().shape({
  date: Yup.string().min(1, 'Vereist').required('Vereist'),
  invoiceNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export type Purchase = NonNullable<GetPurchaseQuery['purchase']>;

const PurchasePage = () => {
  const navigate = useNavigate();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  invariant(purchaseId);
  const [{ data }] = useGetPurchaseQuery({
    variables: {
      id: purchaseId,
    },
  });
  const [, deletePurchaseInvoice] = useDeletePurchaseMutation();
  const [, updatePurchase] = useUpdatePurchaseMutation();

  const purchase = nullthrows(data?.purchase, 'Aankoop niet gevonden');

  const initialValues = {
    date: formatInputDate(purchase.date),
    invoiceNumber: purchase.invoiceNumber,
  };

  const title = `Aankoop ${purchase.invoiceNumber}`;
  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Aankopen',
              to: '/internal/finance/purchases',
            }}
            currentItem={title}
          />
        }
        rightSide={
          <div className="flex gap-2">
            <FileUploaderButton buttonText="AKF" title="Aankoopfactuur" file={purchase.document} disableUpload />
            <ConfirmDialog
              triggerColor="danger"
              triggerText={<Trash className="button-icon" />}
              title="Aankoopfactuur verwijderen?"
              submitText="Verwijder aankoopfactuur"
              description={<div>Ben je zeker dat je dit aankoopfactuur wilt verwijderen?</div>}
              onSubmit={async () => {
                try {
                  const result = await deletePurchaseInvoice({
                    purchaseId: purchase.id,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  navigate('..');
                  toast.success('Aankoopfactuur verwijderd');
                } catch (err) {
                  captureException(err);
                  console.error(err);
                  toast.error('Kon aankoopfactuur niet verwijderen: ' + getDisplayError(err));
                }
              }}
            />
          </div>
        }
      />

      <div className="px-4">
        <div className="flex gap-4 mb-4">
          <div>
            <DataField title="Klant">
              <span className="whitespace-nowrap">
                {purchase.supplier.name} ({purchase.supplier.id})
              </span>
            </DataField>
          </div>

          <div className="w-full max-w-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={updatePurchaseSchema}
              onSubmit={async (newValues) => {
                try {
                  const patch: Partial<typeof newValues> = diff(initialValues, newValues);
                  const res = await updatePurchase({
                    purchaseId: purchase.id,
                    data: patch,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                  toast.success('Aankoopfactuur aangepast');
                } catch (err: any) {
                  captureException(err);
                  toast.error('Kon aankoopfactuur niet aanpassen: ' + getDisplayError(err));
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <form className="flex gap-4 items-center" onSubmit={handleSubmit}>
                    <InputField labelText="Factuur datum" type="date" name="date" />
                    <InputField labelText="Factuur nummer" type="text" name="invoiceNumber" />
                    <div>
                      <Button type="submit" color="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                        <Check className="button-icon" />
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>

        <div>
          {purchase.orders
            .sort((a, b) => extractIntFromText(a.orderNumber || '') - extractIntFromText(b.orderNumber || ''))
            .map((order) => {
              const orderTotals = calculateInvoiceTotals(
                order.lines
                  .map((l) =>
                    l.purchases.map((p) => {
                      return {
                        ...p,
                        vatRateId: p.vatRate.id,
                      };
                    }),
                  )
                  .flat(),
              );

              const hasCmr = order.documents.some((v) => v.type === OrderDocumentType.Cmr);

              return (
                <div key={`order-${order.orderNumber}`}>
                  <Link to={`/internal/orders/${order.id}/general`} className="heading-two mb-4 flex gap-4">
                    <div>
                      Order {order.orderNumber} - {order.customerRef}
                    </div>

                    <div
                      className={classNames('flex gap-2', {
                        'text-feedback-negative-04': !hasCmr,
                        'text-feedback-positive': hasCmr,
                      })}
                    >
                      {hasCmr ? 'CMR' : 'Geen CMR'}
                    </div>
                  </Link>

                  <div>
                    {order.lines.map((line) => {
                      return (
                        <div key={`line-${line.id}`}>
                          <div>
                            {line.stops
                              .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                              .map((stop) => {
                                return (
                                  <div className="grid grid-cols-4" key={`line-${line.id}-${stop.id}`}>
                                    <div className="font-medium">
                                      {stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}
                                    </div>
                                    <div>
                                      {stop.location.country}-{stop.location.name}
                                    </div>

                                    <div className="flex gap-2 items-center">
                                      <CalendarDots className="w-4 h-4" />
                                      {formatDate(stop.date)}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                      <Clock className="w-4 h-4" />
                                      {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="border-t py-4 my-4 border-dark-04">
                            <div className="grid grid-cols-5 gap-x-4 mb-1">
                              <div className="label-text">Beschrijving</div>
                              <div className="label-text">Aantal</div>
                              <div className="label-text">Eenheidsprijs</div>
                              <div className="label-text">Totaal</div>
                              <div className="label-text">Btw</div>
                            </div>

                            <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                              {line.purchases.map((purchaseLine) => {
                                return (
                                  <React.Fragment key={`sale-${purchaseLine.id}`}>
                                    <div>{purchaseLine.productType.nameNl}</div>
                                    <div>
                                      {formatNumber(purchaseLine.amount, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      {`€ ${formatNumber(purchaseLine.unitPrice, 2, {
                                        decimalSeperator: ',',
                                      })}`}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      {`€ ${formatNumber((purchaseLine.amount * purchaseLine.unitPrice) / 100, 2, {
                                        decimalSeperator: ',',
                                      })}`}
                                    </div>
                                    <div
                                      className={classNames('whitespace-nowrap', {
                                        'text-feedback-negative-04':
                                          purchaseLine.vatRate.id !== purchase.supplier.defaultVatRate.id,
                                      })}
                                    >
                                      {`€ ${formatNumber(
                                        (purchaseLine.amount *
                                          purchaseLine.unitPrice *
                                          purchaseLine.vatRatePercentage) /
                                          1000000,
                                        2,
                                        {
                                          decimalSeperator: ',',
                                        },
                                      )} - ${formatNumber(purchaseLine.vatRatePercentage, 2, {
                                        decimalSeperator: ',',
                                      })}%`}
                                    </div>

                                    {!!purchaseLine.externalNote && (
                                      <div className="col-span-5">
                                        <div className="text-sm">Extra informatie: {purchaseLine.externalNote}</div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="label-text border-b border-dark-04 pb-4 my-4 whitespace-nowrap">
                      {`Order totaal: € ${formatNumber(orderTotals.totalExclVat, 2, {
                        decimalSeperator: ',',
                      })}`}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="grid grid-cols-2">
          <div></div>
          <div className="grid grid-cols-2 text-md gap-x-4">
            <div className="label-text">Totaal excl. btw:</div>
            <div className="whitespace-nowrap">{`€ ${formatNumber(purchase.totalExclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
            <div className="label-text">Totaal btw:</div>
            <div className="whitespace-nowrap">{`€ ${formatNumber(purchase.totalInclVat - purchase.totalExclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
            <div className="label-text">Totaal incl. btw:</div>
            <div className="whitespace-nowrap">{`€ ${formatNumber(purchase.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasePage;
