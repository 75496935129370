import { useGetPurchaseSuppliersQuery, useGetSuppliersQuery } from '../../../../../generated/graphql';
import { useCreatePurchaseCtx } from './context';

export interface Props {
  searchValue: string;
}

export const SupplierSelect: React.FC<Props> = (props) => {
  const { searchValue } = props;
  const { dispatch } = useCreatePurchaseCtx();
  const [data] = useGetPurchaseSuppliersQuery({
    variables: {
      filters: {
        hasOpenOrders: true,
        search: searchValue || undefined,
      },
      take: 100,
    },
  });

  const suppliers = data.data?.suppliers ?? [];

  if (!suppliers.length) {
    return <div>Er zijn geen vervoerders met open orders.</div>;
  }

  return (
    <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
      {suppliers.map((supplier) => {
        return (
          <div
            key={supplier.id}
            className="card p-4"
            data-clickable="true"
            onClick={() => {
              dispatch({
                type: 'SELECT_SUPPLIER',
                supplier,
              });
            }}
          >
            <div className="flex justify-between">
              <div className="card-heading-text">{supplier.name}</div>
              <div className="card-subtle-text">{supplier.vatNumber}</div>
            </div>

            <div>{`${supplier.street} ${supplier.streetNumber}`}</div>
            <div>{`${supplier.postalCode} ${supplier.city}, ${supplier.country}`}</div>

            <div className="mt-2 text-sm">
              <div>
                <span className="font-medium">{supplier.openOrderCount}</span>
                <span className="ml-1">Open Orders</span>
              </div>
              <div>
                <span className="font-medium">{supplier.openOrderCountWithCmr}</span>
                <span className="ml-1">Open Orders met CMR</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
