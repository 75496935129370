import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { getDisplayError } from '@utils/get-display-error';
import { Button } from 'components/button/Button';
import { Card } from 'components/Card';
import { InputField } from 'components/input/InputField';
import {
  CreateTrailerCostInputData,
  TrailerCostPayee,
  useTrailerCostQuery,
  useUpdateTrailerCostMutation,
} from 'generated/graphql';
import { SimpleSelectField } from 'components/select/SimpleSelectField';
import { PAID_BY_OPTIONS } from './constants';
import { formatNumber, parseNumberInput } from '@utils/number';
import { useTrailer } from '../trailerContext';
import { invariant, nullthrows } from '@utils/invariant';
import { CustomerComboboxField, Customer } from 'src/app/customer/CustomerComboboxField';
import { SupplierComboboxField, Supplier } from 'src/app/supplier/SupplierComboboxField';
import { captureException } from '@sentry/react';

const updateTrailerCostSchema = Yup.object().shape({
  amount: Yup.string().min(1, 'Vereist').required('Vereist'),
  description: Yup.string().min(1, 'Vereist').required('Vereist'),
  date: Yup.string().required('Vereist'),
});

export const UpdateTrailerCostForm = () => {
  const navigate = useNavigate();
  const { trailer } = useTrailer();

  const { trailerCostId } = useParams<{ trailerCostId: string }>();
  invariant(trailerCostId);
  const [{ data }] = useTrailerCostQuery({
    variables: {
      id: +trailerCostId,
    },
  });
  const [, updateTrailerCostMutate] = useUpdateTrailerCostMutation();

  const trailerCost = nullthrows(data?.trailerCost, 'Trailer kost niet gevonden');
  const initialValues = {
    date: trailerCost.date,
    description: trailerCost.description,
    amount: formatNumber(trailerCost.amount, 2),
    paidBy: PAID_BY_OPTIONS.find((v) => v.key === trailerCost.paidBy) ?? PAID_BY_OPTIONS[0],
    customer: trailerCost.customer as Customer | null,
    supplier: trailerCost.supplier as Supplier | null,
  };

  return (
    <>
      <div className="px-4">
        <Formik
          initialValues={initialValues}
          validationSchema={updateTrailerCostSchema}
          onSubmit={async (values) => {
            try {
              const inputData: CreateTrailerCostInputData = {
                trailerId: trailer.id,
                amount: parseNumberInput(values.amount, 2),
                date: values.date,
                description: values.description,
                paidBy: values.paidBy.key,
                customerId: values.customer?.id,
                supplierId: values.supplier?.id,
              };

              const result = await updateTrailerCostMutate({
                id: trailerCost.id,
                data: inputData,
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Trailer kost aangemaakt');
              navigate('..');
            } catch (err: any) {
              captureException(err);
              toast.error('Kon trailer kost niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Algemeen">
                <InputField labelText="Datum" type="date" name="date" />
                <InputField labelText="Beschrijving" type="text" name="description" />
                <InputField labelText="Bedrag" type="number" name="amount" step="0.01" />
              </Card>

              <Card title="Betaler">
                <SimpleSelectField labelText="Betaald door" items={[...PAID_BY_OPTIONS]} name="paidBy" />
                {values.paidBy.key === TrailerCostPayee.Customer && (
                  <CustomerComboboxField name="customer" isDisabled={isSubmitting} />
                )}
                {values.paidBy.key === TrailerCostPayee.Supplier && (
                  <SupplierComboboxField name="supplier" isDisabled={isSubmitting} isNullable={true} />
                )}
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Pas trailer kost aan
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
