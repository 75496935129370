import { DownloadSimple, Plus, MagnifyingGlass } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { Suspense, useState } from 'react';

import { BaseButton } from '../../../components/button/BaseButton';
import { useAddOrdersToInvoiceMutation } from '../../../generated/graphql';
import { OrderSelect } from './OrderSelect';
import { Invoice } from '../pages/sale/details';
import { SpinnerBlock } from '../../../components/Spinner';
import { Input } from '../../../components/input/Input';
import { Button } from '../../../components/button/Button';
import toast from 'react-hot-toast';
import { getDisplayError } from '../../../utils/get-display-error';
import { captureException } from '@sentry/react';

export interface IAddOrdersToInvoiceDialogProps {
  invoice: Invoice;
}

export const AddOrdersToInvoiceDialog: React.FC<IAddOrdersToInvoiceDialogProps> = (props) => {
  const { invoice } = props;
  const [open, setOpen] = useState(false);
  const [addOrdersToInvoiceRes, addOrdersToInvoice] = useAddOrdersToInvoiceMutation();
  const [searchValue, setSearchValue] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild disabled={addOrdersToInvoiceRes.fetching}>
        <BaseButton
          color="default"
          iconLeft={<Plus className="button-icon" />}
          isLoading={addOrdersToInvoiceRes.fetching}
        >
          Voeg orders toe
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />

        <Dialog.Content className="dialog-content" style={{ maxWidth: '100vw' }}>
          <Dialog.Title className="heading-one mb-4">Voeg orders to aan deze factuur</Dialog.Title>

          <div className="mb-4">
            <Input
              type="text"
              placeholder={searchValue}
              value={searchValue}
              onChange={setSearchValue}
              iconLeft={<MagnifyingGlass className="input-icon" />}
            />
          </div>

          <div style={{ height: '50vh' }}>
            <Suspense fallback={<SpinnerBlock message="Orders aan het laden..." />}>
              <OrderSelect
                customerId={invoice.customer.id}
                searchValue={searchValue}
                selectedOrderIds={selectedOrderIds}
                onSelectOrder={(o) => {
                  setSelectedOrderIds((prev) => {
                    if (prev.includes(o.id)) {
                      return prev.filter((id) => id !== o.id);
                    } else {
                      return [...prev, o.id];
                    }
                  });
                }}
              />
            </Suspense>
          </div>

          <div className="flex justify-end">
            <Button
              color="primary"
              onTrigger={async () => {
                try {
                  const result = await addOrdersToInvoice({
                    invoiceId: invoice.id,
                    orderIds: selectedOrderIds,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Orders zijn toegevoegd aan de factuur');
                  setOpen(false);
                } catch (err) {
                  captureException(err);
                  toast.error('Kon orders niet toevoegen aan de factuur: ' + getDisplayError(err));
                }
              }}
            >
              Voeg orders toe
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
