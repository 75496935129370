import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';

import { useAuth } from '../../../contexts/auth-context';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { DataField } from '../../../components/DataField';
import { formatDateTime } from '../../../utils/date';
import { SimpleSelect } from '../../../components/select/SimpleSelect';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { Language, useUpdateMyProfileMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTranslation } from '../../../contexts/translation-context';
import { Input } from '../../../components/input/Input';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';

export const CustomerPortalMePage = () => {
  const { me, logout } = useAuth();
  const [, updateProfile] = useUpdateMyProfileMutation();
  const { setLanguage, i18n } = useTranslation();
  const [nameValue, setNameValue] = useState(me.name);
  const [lastPersistedName, setLastPersistedName] = useState(me.name);

  useEffect(() => {
    setNameValue(me.name);
    setLastPersistedName(me.name);
  }, [me.name]);

  const persistNameChange = async () => {
    const trimmedValue = nameValue.trim();
    if (!trimmedValue || lastPersistedName === trimmedValue) {
      return;
    }

    try {
      const res = await updateProfile({
        data: {
          name: trimmedValue,
        },
      });
      if (res.error) {
        throw res.error;
      }
      setLastPersistedName(trimmedValue);
      toast.success(i18n('customerPortal.myProfile.nameChanged'));
    } catch (err) {
      captureException(err);
      console.log(err);
      toast.error(
        i18n('customerPortal.myProfile.nameNotChanged', {
          error: getDisplayError(err),
        }),
      );
    }
  };

  return (
    <div>
      <PageHeader title={me.name} />

      <PageHeading
        leftSide={<Breadcrumb currentItem={i18n('customerPortal.myProfile.myProfile')} />}
        rightSide={
          <div>
            <Button color="danger" onTrigger={() => logout()}>
              {i18n('customerPortal.myProfile.logout')}
            </Button>
          </div>
        }
      />

      <div className="px-4">
        <div className="flex gap-8">
          <div>{me.image && <img src={me.image} className="w-24 h-24 rounded" />}</div>
          <div className="grid gap-2">
            <DataField title={i18n('customerPortal.myProfile.name')}>
              <Input
                type="text"
                value={nameValue}
                onChange={(newValue) => {
                  setNameValue(newValue);
                }}
                onBlur={() => {
                  persistNameChange();
                }}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    persistNameChange();
                  }
                }}
              />
            </DataField>
            <DataField title={i18n('customerPortal.myProfile.email')}>{me.email}</DataField>
            <DataField title={i18n('customerPortal.myProfile.language')}>
              <SimpleSelect
                items={LANGUAGE_VALUES}
                selectedItem={LANGUAGE_VALUES.find((v) => v.key === me.language) ?? null}
                onSelect={async (newValue) => {
                  if (!newValue?.key) return;

                  try {
                    const res = await updateProfile({
                      data: {
                        language: newValue.key as Language,
                      },
                    });
                    if (res.error) {
                      throw res.error;
                    }
                    setLanguage(newValue.key);
                    toast.success(i18n('customerPortal.myProfile.languageChanged'));
                  } catch (err) {
                    captureException(err);
                    console.log(err);
                    toast.error(
                      i18n('customerPortal.myProfile.languageNotChanged', {
                        error: getDisplayError(err),
                      }),
                    );
                  }
                }}
              />
            </DataField>
            <DataField title={i18n('customerPortal.myProfile.joinedAt')}>{formatDateTime(me.createdAt)}</DataField>
          </div>
        </div>
      </div>
    </div>
  );
};
