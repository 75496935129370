import { IterableElement } from 'type-fest';
import toast from 'react-hot-toast';
import { Trash } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Customer, useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useDeleteCustomerUserInviteMutation } from '../../../generated/graphql';
import { useTranslation } from '../../../contexts/translation-context';
import { ITableHeader, Table } from 'components/table/Table';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';

export type CustomerUser = IterableElement<Customer['users']>;
export type CustomerUserInvite = IterableElement<Customer['invitedUsers']>;

export const CustomerPortalUsersPage = () => {
  const { customer } = useCustomerPortalCustomer();
  const [, deleteInvite] = useDeleteCustomerUserInviteMutation();
  const { i18n } = useTranslation();

  const tableHeaders: ITableHeader[] = [
    {
      id: 'name',
      name: i18n('customerPortal.users.name'),
    },
    {
      id: 'address',
      name: i18n('customerPortal.users.email'),
    },
    {
      id: 'postalCode',
      name: i18n('customerPortal.users.isActive'),
    },
  ];

  return (
    <div>
      <PageHeader title={i18n('customerPortal.users.users')} />

      <PageHeading leftSide={<Breadcrumb currentItem={i18n('customerPortal.users.users')} />} />

      <div className="px-4">
        <h2 className="heading-two mb-2">{i18n('customerPortal.users.invitedUsers')}</h2>

        {!customer.invitedUsers.length && <div>{i18n('customerPortal.users.noInvitedUsers')}</div>}

        <div className="grid gap-4 grid-cols-2">
          {customer.invitedUsers.map((u) => {
            return (
              <div className="card p-4 flex justify-between items-center" key={u.id}>
                <div className="card-heading-text">{u.email}</div>

                <ConfirmDialog
                  triggerColor="danger"
                  triggerText={<Trash className="button-icon" />}
                  title={i18n('customerPortal.users.removeInvite')}
                  submitText={i18n('customerPortal.users.removeInvite')}
                  description={i18n('customerPortal.users.confirmRemoveInvite', {
                    email: u.email,
                  })}
                  onSubmit={async () => {
                    try {
                      const result = await deleteInvite({
                        inviteId: u.id,
                        customerId: customer.id,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success(i18n('customerPortal.users.inviteRemoved'));
                    } catch (err) {
                      captureException(err);
                      toast.error(
                        i18n('customerPortal.users.couldNotRemoveInvite', {
                          error: getDisplayError(err),
                        }),
                      );
                    }
                  }}
                />
              </div>
            );
          })}
        </div>

        <h2 className="heading-two mb-2 mt-8">{i18n('customerPortal.users.users')}</h2>

        {!customer.users.length && <div>{i18n('customerPortal.users.noUsersFound')}</div>}

        <Table
          idKey="id"
          headers={tableHeaders}
          data={customer.users.map((v) => v.user)}
          mapData={(user) => {
            return [
              user.name,
              user.email,
              user.isActive ? i18n('customerPortal.users.active') : i18n('customerPortal.users.inactive'),
            ];
          }}
        />
      </div>
    </div>
  );
};
