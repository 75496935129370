import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { StatusText } from '../../../components/StatusText';
import { ITableHeader, Table } from '../../../components/table/Table';
import { GetInvoicesDocument, GetInvoicesQuery, GetInvoicesQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { InvoiceStatusToColor, InvoiceStatusToText } from '../utils/status';
import { ExportInvoicesButton } from './ExportSalesButton';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/ButtonLink';
import { PageHeading } from 'components/PageHeading';
import { useDebouncedSearchParams } from 'src/hooks/useDebouncedSearchParams';

const INVOICES_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Factuur Nummer',
  },
  {
    id: 'customerRef',
    name: 'Klant referentie',
  },
  {
    id: 'date',
    name: 'Factuur Datum',
  },
  {
    id: 'dueDate',
    name: 'Vervaldatum',
  },
  {
    id: 'customer',
    name: 'Klant',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
];

export const SalesInvoicesPage = () => {
  const [searchParams, setSearchParams] = useDebouncedSearchParams();
  const [searchValue, _setSearchValue] = useState(() => searchParams['search'] ?? '');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetInvoicesQuery, GetInvoicesQueryVariables>(
          GetInvoicesDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.invoices ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const setSearchValue = useCallback(
    (newValue: string) => {
      setSearchParams({ search: newValue });
      _setSearchValue(newValue);
    },
    [_setSearchValue],
  );

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = 'Verkopen';

  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Financieel',
              to: '/internal/finance',
            }}
            currentItem={title}
          />
        }
        rightSide={
          <div className="flex gap-4">
            <div>
              <ExportInvoicesButton />
            </div>
            <div>
              <LinkButton to="new-manual" iconLeft={<Plus className="button-icon" />}>
                Nieuwe manuele verkoop
              </LinkButton>
            </div>
            <div>
              <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                Nieuwe verkoop
              </LinkButton>
            </div>
          </div>
        }
      />

      <div className="flex w-full px-4 mb-8">
        <Input
          type="text"
          placeholder="Zoek een verkoop..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={INVOICES_HEADERS}
        data={page.data}
        mapData={(invoice) => {
          return [
            <Link to={`${invoice.id}`} className="link-text">
              {invoice.invoiceNumber ?? 'DRAFT'}
            </Link>,
            invoice.customerRef ?? '-',
            formatDate(invoice.date),
            invoice.expiresAt ? formatDate(invoice.expiresAt) : '-',
            invoice.customer.name,
            <StatusText color={InvoiceStatusToColor[invoice.status]}>{InvoiceStatusToText[invoice.status]}</StatusText>,
            <div className="whitespace-nowrap">{`€ ${formatNumber(invoice.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>,
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
