import { useField } from 'formik';
import { useState } from 'react';
import { IterableElement } from 'type-fest';

import { Combobox } from '../../../components/combobox/Combobox';
import { InputWrapper } from '../../../components/InputWrapper';
import { GetMinimalCmrOrdersQuery, useGetMinimalCmrOrdersQuery } from '../../../generated/graphql';

export type MinimalOrder = IterableElement<GetMinimalCmrOrdersQuery['orders']>;

const queryContext = {
  suspense: false,
};

export interface IMinimalOrderComboboxFieldProps {
  name: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
}

export function MinimalOrderComboboxField(props: IMinimalOrderComboboxFieldProps) {
  const { name, labelText = 'Order', helperText, isDisabled } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetMinimalCmrOrdersQuery({
    variables: {
      filters: {
        orderNumber: searchValue,
      },
      take: 500,
    },
    context: queryContext,
  });

  const orders: MinimalOrder[] = data?.orders ?? [];
  const [field, meta, helpers] = useField({ name });
  const selectedItem = field.value ?? null;

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <Combobox
        keyName="id"
        placeholder="Selecteer een order"
        onQueryChange={setSearchValue}
        display={(val: MinimalOrder) =>
          `${val.orderNumber ?? 'DRAFT'} (${[val.customer?.name, val.customerRef].filter(Boolean).join(' - ')})`
        }
        items={orders}
        selectedItem={selectedItem}
        onSelect={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
        isNullable={true}
      />
    </InputWrapper>
  );
}
