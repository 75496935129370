import './styles/globals.css';
import './utils/custom-validations';

import './utils/sentry.client';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

// eslint-disable-next-line no-restricted-globals
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
} else {
  console.error('Root node for React is not found');
}
