import { Plus } from '@phosphor-icons/react';
import { Breadcrumb } from 'components/Breadcrumb';
import { Button } from 'components/button/Button';
import { PageHeader } from 'components/PageHeader';
import { TextAreaField } from 'components/textarea/TextAreaField';
import { FormikConsumer, FormikProvider, useFormik } from 'formik';
import { OrderLineStopType, TrailerType, useCreateOrderMutation, useGetOrderByIdQuery } from 'generated/graphql';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';
import * as Yup from 'yup';

import { InputField } from '../../../../components/input/InputField';
import { parseInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { Customer, CustomerComboboxField } from '../../../customer/CustomerComboboxField';
import { Supplier, SupplierComboboxField } from '../../../supplier/SupplierComboboxField';
import { CreateOrderLineFields, ICreateOrderLineValues } from '../orderLine/CreateOrderLineFields';
import { createOrderLineContentSchema } from '../orderLineContent/CreateOrderLineContent';
import { createOrderLineSaleSchema } from '../orderLineSale/CreateOrderLineSale';
import { createOrderPurchaseSchema } from '../orderPurchase/CreateOrderPurchase';
import { createOrderLineStopSchema, initialValues as initialStopValues } from '../orderLineStop/CreateOrderLineStop';
import { STOP_TYPE_ITEMS } from '../orderLineStop/constants';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { TrailerTypes } from './TrailerTypes';
import { cargoTypeToComboboxItem } from '../../cargoType/CargoTypeComboboxField';
import { packageTypeToComboboxItem } from '../../packageType/PackageTypeComboboxField';
import { productTypeToComboboxItem } from '../../productType/ProductTypeComboboxField';
import { vatRateToComboboxItem } from '../../vatRate/VatRateComboboxField';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';
import { captureException } from '@sentry/react';

const createOrderSchema = Yup.object().shape({
  customer: Yup.mixed().nullable().required('Vereist'),
  supplier: Yup.mixed().nullable(),
  customerRef: Yup.string(),
  internalNotes: Yup.string(),
  externalNotes: Yup.string(),
  allowedTrailerTypes: Yup.array(),
  requiresAnonymousCMR: Yup.boolean().required('Vereist'),

  stops: Yup.array(createOrderLineStopSchema).required('Vereist'),
  contents: Yup.array(createOrderLineContentSchema).required('Vereist'),
  purchases: Yup.array(createOrderPurchaseSchema).required('Vereist'),
  sales: Yup.array(createOrderLineSaleSchema).required('Vereist'),
});

interface IOrderValues extends ICreateOrderLineValues {
  customer: Customer | null;
  supplier: Supplier | null;
  customerRef: string;
  internalNotes: string;
  externalNotes: string;
  requiresAnonymousCMR: boolean;
  noPurchase: boolean;
  allowedTrailerTypes: TrailerType[];
}

const CreateOrderPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, createOrderMutation] = useCreateOrderMutation();

  const copyFrom = searchParams.get('copy-from');
  const [{ data }] = useGetOrderByIdQuery({
    variables: {
      id: copyFrom ?? '',
    },
    pause: !copyFrom,
  });

  const initialValues: IOrderValues = useMemo(() => {
    if (data?.order) {
      const order = data.order;
      if (order.lines.length > 0) {
        const line = order.lines[0]!;
        return {
          customer: order.customer ?? null,
          supplier: null,
          customerRef: '',
          internalNotes: order.internalNotes,
          externalNotes: order.externalNotes,
          requiresAnonymousCMR: order.requiresAnonymousCMR,
          noPurchase: order.noPurchase,
          allowedTrailerTypes: order.allowedTrailerTypes,
          stops: line.stops
            .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
            .map((stop) => ({
              type: STOP_TYPE_ITEMS.find((v) => v.key === stop.type)!,
              location: stop.location,
              date: '',
              timeStart: '',
              timeEnd: '',
              reference: '',
              notes: stop.notes,
            })),
          contents: line.contents.map((content) => ({
            cargoType: cargoTypeToComboboxItem(content.cargoType),
            packages: formatNumber(content.packages, 2),
            packageType: packageTypeToComboboxItem(content.packageType),
            weight: formatNumber(content.weight, 2),
            volume: formatNumber(content.volume, 2),
            loadingMeters: formatNumber(content.loadingMeters, 2),
          })),
          purchases: [],
          sales: line.sales.map((sale) => ({
            productType: productTypeToComboboxItem(sale.productType),
            vatRate: vatRateToComboboxItem(sale.vatRate),
            amount: formatNumber(sale.amount, 2),
            unitPrice: formatNumber(sale.unitPrice, 2),
            externalNote: sale.externalNote ?? '',
          })),
        };
      }
    }

    return {
      customer: null,
      supplier: null,
      customerRef: '',
      internalNotes: '',
      externalNotes: '',
      requiresAnonymousCMR: false,
      noPurchase: false,
      allowedTrailerTypes: [],
      stops: [
        {
          ...initialStopValues,
          type: STOP_TYPE_ITEMS.find((item) => item.key === OrderLineStopType.Load)!,
        },
      ],
      contents: [],
      purchases: [],
      sales: [],
    };
  }, []);

  const formikbag = useFormik({
    initialValues: initialValues,
    validationSchema: createOrderSchema,
    onSubmit: async (values) => {
      try {
        const {
          noPurchase,
          customer,
          supplier,
          customerRef,
          internalNotes,
          externalNotes,
          requiresAnonymousCMR,

          stops,
          contents,
          purchases,
          sales,
          allowedTrailerTypes,
        } = values;
        const customerId = customer?.id;
        invariant(customerId);
        const supplierId = supplier?.id;
        const result = await createOrderMutation({
          data: {
            noPurchase,
            customerId,
            supplierId,
            customerRef,
            internalNotes,
            externalNotes,
            requiresAnonymousCMR,
            allowedTrailerTypes,
            lines: [
              {
                stops: stops.map((stop, i) => {
                  const { type: selectedType, location, date, timeStart, timeEnd, reference, notes } = stop;
                  const locationId = location?.id;
                  invariant(locationId);
                  const type = nullthrows(selectedType?.key, 'Stop type is required');

                  return {
                    sequenceIndex: i * 10,
                    type: type as OrderLineStopType,
                    locationId,
                    date,
                    timeStart: parseInputTime(timeStart),
                    timeEnd: parseInputTime(timeEnd),
                    reference,
                    notes,
                  };
                }),

                contents: contents.map((content) => {
                  const { cargoType, packageType, packages, weight, volume, loadingMeters } = content;
                  const cargoTypeId = cargoType?.key;
                  const packageTypeId = packageType?.key;
                  invariant(cargoTypeId);
                  invariant(packageTypeId);

                  return {
                    cargoTypeId,
                    packageTypeId,
                    packages: parseNumberInput(packages, 2),
                    weight: parseNumberInput(weight, 2),
                    volume: parseNumberInput(volume, 2),
                    loadingMeters: parseNumberInput(loadingMeters, 2),
                  };
                }),
                purchases: purchases.map((purchase) => {
                  const { productType, vatRate, amount, unitPrice, externalNote } = purchase;
                  const productTypeId = nullthrows(productType?.key, 'Product type is vereist');
                  const vatRateId = nullthrows(vatRate?.key, 'Product type is vereist');
                  return {
                    productTypeId,
                    vatRateId,
                    amount: parseNumberInput(amount, 2),
                    unitPrice: parseNumberInput(unitPrice, 2),
                    externalNote: externalNote ?? '',
                  };
                }),
                sales: sales.map((sale) => {
                  const { productType, vatRate, amount, unitPrice, externalNote } = sale;
                  const productTypeId = nullthrows(productType?.key, 'Product type is vereist');
                  const vatRateId = nullthrows(vatRate?.key, 'Product type is vereist');
                  return {
                    productTypeId,
                    vatRateId,
                    amount: parseNumberInput(amount, 2),
                    unitPrice: parseNumberInput(unitPrice, 2),
                    externalNote: externalNote ?? '',
                  };
                }),
              },
            ],
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate(`../${result.data.createOrder.id}/general`);
        }
        toast.success('Order aangemaakt');
      } catch (err: any) {
        captureException(err);
        toast.error('Kon order niet aanmaken: ' + getDisplayError(err));
      }
    },
  });

  const customerValue = formikbag.values['customer'];
  useEffect(() => {
    const trailerTypes = formikbag.values['allowedTrailerTypes'];
    if (customerValue && customerValue.defaultTrailerTypes.length && !trailerTypes.length) {
      formikbag.setFieldValue('allowedTrailerTypes', customerValue.defaultTrailerTypes);
    }
  }, [customerValue]);

  return (
    <>
      <PageHeader title="Nieuwe order" />

      <div>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: 'Orders',
                to: '/internal/orders',
              }}
              currentItem="Nieuwe order"
            />
          }
        />

        <div className="px-4">
          <FormikProvider value={formikbag}>
            <FormikConsumer>
              {({ handleSubmit, isSubmitting, values, errors, setFieldValue }) => {
                console.error(errors);
                return (
                  <form onSubmit={handleSubmit} className="grid gap-8">
                    <Card title="Standaard informatie">
                      <CustomerComboboxField name="customer" isDisabled={isSubmitting} />

                      <SupplierComboboxField name="supplier" isDisabled={isSubmitting} />

                      <InputField
                        type="text"
                        labelText="Factuurreferentie klant"
                        name="customerRef"
                        isDisabled={isSubmitting}
                      />

                      <div className="grid grid-cols-2">
                        <CheckboxField labelText="Moet anonieme CMR aanmaken" name="requiresAnonymousCMR" />
                        <CheckboxField labelText="Geen aankopen" name="noPurchase" />
                      </div>
                    </Card>

                    <Card title="Trailer Types">
                      <TrailerTypes
                        value={values.allowedTrailerTypes}
                        onChange={(newTrailerTypes) => {
                          setFieldValue('allowedTrailerTypes', newTrailerTypes);
                        }}
                        isMultiSelect={true}
                      />
                    </Card>

                    <div>
                      <h2 className="section-header">Opdracht</h2>

                      <CreateOrderLineFields
                        noPurchase={values.noPurchase}
                        customer={values.customer}
                        supplier={values.supplier}
                        values={values}
                        isSubmitting={isSubmitting}
                      />
                    </div>

                    <Card title="Notities">
                      <TextAreaField
                        labelText="Interne notities"
                        name="internalNotes"
                        isDisabled={isSubmitting}
                        spellCheck={true}
                      />
                      <TextAreaField
                        labelText="Externe notities (voor vervoerder)"
                        name="externalNotes"
                        isDisabled={isSubmitting}
                        spellCheck={true}
                      />
                    </Card>

                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                        iconLeft={<Plus className="button-icon" />}
                      >
                        Maak order aan
                      </Button>
                    </div>
                  </form>
                );
              }}
            </FormikConsumer>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

export default CreateOrderPage;
