import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinkCard } from 'components/Card';
import { useAuth } from 'contexts/auth-context';

export const CustomerSelectionPage = () => {
  const { me } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (me.customers.length === 1) {
      navigate(`/customer-portal/${me.customers[0].customer.id}`);
    }
  }, [me, navigate]);

  return (
    <main className="content-bg min-h-screen p-8">
      <h1 className="heading-one mb-6">Select customer</h1>

      <div className="grid grid-cols-3 gap-4">
        {me.customers.map(({ customer }) => {
          return <LinkCard to={`/customer-portal/${customer.id}`} title={customer.name} />;
        })}
      </div>
    </main>
  );
};
