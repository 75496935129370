import React, { useEffect, useMemo } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { Check, CaretUp, CaretDown, X, MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react';

import classNames from '@utils/classnames';

interface ISelectItemProps {
  children: React.ReactNode;
  value: string;
  selected: boolean | null;
  className?: string;
  onClick?: () => void;
}

const SelectItem: React.FC<ISelectItemProps> = (props) => {
  const { children, selected, className, onClick } = props;

  return (
    <div
      className={classNames(
        'w-full relative cursor-pointer select-none py-2 pl-3 pr-9 focus:outline-none hover:bg-dark-05 focus:bg-dark-05',
        className,
      )}
      onClick={onClick}
    >
      <div>{children}</div>
      {selected && (
        <div className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', 'text-dark-01')}>
          <Check className="h-5 w-5" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export interface IComboboxProps<V extends Record<string, any>> {
  placeholder?: string;
  inputPlaceholder?: string;
  onQueryChange: (newQuery: string) => void;
  items: V[];
  selectedItem?: V | null;
  onSelect: (value: V | null) => void;
  keyName: string;
  display: (value: V) => string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
}

export function Combobox<V extends Record<string, any>>(props: IComboboxProps<V>) {
  const {
    placeholder = 'Selecteer een item',
    inputPlaceholder = 'Zoeken...',
    keyName,
    onQueryChange,
    items,
    display,
    selectedItem,
    onSelect,
    isDisabled,
    isInvalid,
    isNullable,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const displayValue = useMemo(() => {
    return selectedItem ? display(selectedItem) : '';
  }, [selectedItem]);

  useEffect(() => {
    onQueryChange(query);
  }, [query]);

  return (
    <div className="flex gap-1">
      <Popover.Root
        open={isOpen}
        modal={true}
        onOpenChange={(newOpen) => {
          if (!newOpen) {
            setTimeout(() => {
              setIsOpen(false);
            }, 1);
          } else {
            setIsOpen(true);
          }
        }}
      >
        <Popover.Trigger className="w-full">
          <div
            className={classNames('w-full border bg-offwhite focus:outline-none rounded overflow-hidden', {
              'border-dark-04': !isInvalid && !isDisabled,
              'border-dark-05': isDisabled,
              'border-feedback-negative-04': isInvalid,
            })}
          >
            <div className="flex cursor-pointer w-full items-center">
              <div
                className={classNames(
                  'w-full py-2 pl-3 pr-10 whitespace-nowrap overflow-hidden text-ellipsis text-start',
                  {
                    'text-dark-01': !isDisabled && displayValue,
                    'text-dark-03': isDisabled || !displayValue,
                  },
                )}
              >
                {displayValue || placeholder}
              </div>

              <div
                className="flex-shrink-0 px-2"
                onClick={(evt) => {
                  if (isDisabled) return;

                  evt.stopPropagation();
                  evt.preventDefault();

                  if (isOpen) {
                    setTimeout(() => {
                      setIsOpen(!isOpen);
                    }, 1);
                  }
                }}
              >
                {isOpen ? (
                  <CaretUp
                    className={classNames('h-5 w-5', {
                      'text-dark-01': !isDisabled,
                      'text-dark-05': isDisabled,
                    })}
                    aria-hidden="true"
                  />
                ) : (
                  <CaretDown
                    className={classNames('h-5 w-5', {
                      'text-dark-01': !isDisabled,
                      'text-dark-05': isDisabled,
                    })}
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </div>
        </Popover.Trigger>

        <Popover.Content
          align="start"
          autoFocus={false}
          className="z-dropdown mt-1 max-h-60 w-full overflow-auto bg-offwhite py-1 text-base focus:outline-none rounded border border-dark-04 animate-in fade-in duration-200 pointer-events-auto"
          style={{
            minWidth: 'var(--radix-popover-trigger-width)',
            maxHeight: 'var(--radix-popover-content-available-height)',
          }}
        >
          <div className="flex gap-2 bg-white border-b border-dark-04">
            <input
              className="w-full py-2 pl-3 pr-10 focus:outline-none"
              value={query}
              onChange={(event) => {
                setQuery(event.target.value);
              }}
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
              }}
              placeholder={inputPlaceholder}
              autoComplete="off"
            />

            <div className="flex items-center justify-center px-3">
              <MagnifyingGlassIcon className="w-4 h-4 text-dark-02" />
            </div>
          </div>

          {items.length ? (
            items.map((item) => (
              <SelectItem
                key={item[keyName]}
                value={item[keyName]}
                selected={Boolean(selectedItem && item[keyName] === selectedItem[keyName])}
                onClick={() => {
                  if (isDisabled) return;

                  if (selectedItem === item && isNullable) {
                    onSelect(null);
                    onQueryChange('');
                  } else {
                    onSelect(item);
                    onQueryChange(display(item));
                    setTimeout(() => {
                      setIsOpen(false);
                    }, 1);
                  }
                }}
              >
                {display(item)}
              </SelectItem>
            ))
          ) : (
            <div className="py-2 px-3">Geen items gevonden</div>
          )}
        </Popover.Content>
      </Popover.Root>

      {Boolean(selectedItem && isNullable) && (
        <div
          className="w-8 flex items-center justify-center hover:bg-dark-05 rounded-md cursor-pointer"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            onSelect(null);
            onQueryChange('');
          }}
        >
          <X className="button-icon" />
        </div>
      )}
    </div>
  );
}
