import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { Button } from '../../../components/button/Button';
import { DataField } from '../../../components/DataField';
import { PageHeader } from '../../../components/PageHeader';
import { SimpleSelect } from '../../../components/select/SimpleSelect';
import { useAuth } from '../../../contexts/auth-context';
import { Language, useUpdateMyProfileMutation } from '../../../generated/graphql';
import { formatDateTime } from '../../../utils/date';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { getDisplayError } from '../../../utils/get-display-error';
import { Input } from '../../../components/input/Input';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { captureException } from '@sentry/react';

export interface IMePageProps {}

export const MePage: React.FC<IMePageProps> = () => {
  const { me, logout } = useAuth();
  const [updateProfileState, updateProfile] = useUpdateMyProfileMutation();
  const [nameValue, setNameValue] = useState(me.name);

  useEffect(() => {
    setNameValue(me.name);
  }, [me.name]);

  const persistNameChange = async () => {
    if (!nameValue.trim()) return;

    try {
      const res = await updateProfile({
        data: {
          name: nameValue.trim(),
        },
      });
      if (res.error) {
        throw res.error;
      }
      toast.success('Naam is bijgewerkt');
    } catch (err) {
      captureException(err);
      console.log(err);
      toast.error('Kon naam niet bijwerken: ' + getDisplayError(err));
    }
  };

  return (
    <>
      <PageHeader title="Mijn profiel" />

      <PageHeading
        leftSide={<Breadcrumb currentItem="Mijn profiel" />}
        rightSide={
          <div>
            <Button color="danger" onTrigger={() => logout()}>
              Log uit
            </Button>
          </div>
        }
      />

      <div className="px-4">
        <div className="flex gap-8">
          <div>{me.image && <img src={me.image} className="w-24 h-24 rounded" />}</div>
          <div>
            <DataField title="Naam">
              <Input
                type="text"
                value={nameValue}
                onChange={(newValue) => {
                  setNameValue(newValue);
                }}
                onBlur={() => {
                  persistNameChange();
                }}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    persistNameChange();
                  }
                }}
              />
            </DataField>
            {me.isInternal && <DataField title="Rol">{me.role}</DataField>}
            <DataField title="Email">{me.email}</DataField>
            <DataField title="Taal">
              <SimpleSelect
                items={LANGUAGE_VALUES}
                selectedItem={LANGUAGE_VALUES.find((v) => v.key === me.language) ?? null}
                onSelect={async (newValue) => {
                  try {
                    const res = await updateProfile({
                      data: {
                        language: newValue?.key as Language,
                      },
                    });
                    if (res.error) {
                      throw res.error;
                    }
                    toast.success('Taal is bijgewerkt');
                  } catch (err) {
                    captureException(err);
                    console.log(err);
                    toast.error('Kon taal niet bijwerken: ' + getDisplayError(err));
                  }
                }}
              />
            </DataField>
            <DataField title="Lid geworden op">{formatDateTime(me.createdAt)}</DataField>
          </div>
        </div>
      </div>
    </>
  );
};
