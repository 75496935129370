import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useCustomerPortalCreateLocationMutation } from '../../../generated/graphql';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { getDisplayError } from '../../../utils/get-display-error';
import { CustomerPortalCreateLocationForm } from './CustomerPortalCreateLocationForm';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';

export const CustomerPortalCreateLocationPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { customer } = useCustomerPortalCustomer();
  const [, createLocationMutation] = useCustomerPortalCreateLocationMutation();

  return (
    <>
      <PageHeader title={i18n('customerPortal.locations.newLocation')} />

      <div>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: i18n('customerPortal.locations.locations'),
                to: '..',
              }}
              currentItem={i18n('customerPortal.locations.newLocation')}
            />
          }
        />

        <div className="card p-4 mx-4">
          <CustomerPortalCreateLocationForm
            onSubmit={async (values) => {
              try {
                const result = await createLocationMutation({
                  customerId: customer.id,
                  data: values,
                });
                if (result.error) {
                  throw result.error;
                }
                toast.success(i18n('customerPortal.locations.locationCreated'));
                navigate('..');
              } catch (err: any) {
                captureException(err);
                toast.error(
                  i18n('customerPortal.locations.couldNotCreateLocation', {
                    error: getDisplayError(err),
                  }),
                );
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
