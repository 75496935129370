import { Button } from 'components/button/Button';
import { Suspense, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import {
  OrderStatus,
  useConfirmOrderUsingTokenMutation,
  useGetOrdersByTokenQuery,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { parseURLSearchParams } from './utils';
import { SimpleErrorBoundary } from '../../../../components/SimpleErrorBoundary';
import { PageHeader } from '../../../../components/PageHeader';
import { nullthrows } from '../../../../utils/invariant';
import { OrderSummary } from './OrderSummary';
import { useTranslation } from '../../../../contexts/translation-context';
import { captureException } from '@sentry/react';

export interface Props {
  parsed: ReturnType<typeof parseURLSearchParams>;
}

const OrderConfirmationPageContent: React.FC<Props> = (props) => {
  const { parsed } = props;
  const tokenId = parsed.orderId ?? parsed.tokenId ?? '';
  const [orderData] = useGetOrdersByTokenQuery({
    variables: {
      id: tokenId,
      token: parsed.token,
    },
  });
  const [confirmOrderState, confirmOrder] = useConfirmOrderUsingTokenMutation();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const orders = nullthrows(orderData.data?.ordersByToken, 'Orders not found');
  const { i18n } = useTranslation();

  const planningDate = parsed.planningDate ?? '';

  if (!orders.length) {
    throw new Error('Orders not found');
  }

  const handleClick = async () => {
    try {
      const result = await confirmOrder({
        id: tokenId,
        token: parsed.token,
        isConfirm: true,
      });
      if (result.error) {
        throw result.error;
      }
      setIsConfirmed(true);
      toast.success(
        i18n(planningDate ? 'pages.confirmPlanning.planningConfirmed' : 'pages.confirmOrder.orderConfirmed'),
      );
    } catch (err) {
      captureException(err);
      console.error(err);
      toast.error(i18n('pages.confirmOrder.orderConfirmationError', { error: getDisplayError(err) }));
    }
  };

  const orderNumbers = orders.map((order) => order.orderNumber);
  const generalHeading = planningDate
    ? i18n('pages.confirmPlanning.heading', {
        planningDate,
      })
    : i18n('pages.confirmOrder.heading', {
        orderNumber: orderNumbers.join(','),
      });

  let existingStatus = OrderStatus.Cancelled;
  for (const order of orders) {
    if (order.status !== existingStatus) {
      if (existingStatus !== OrderStatus.Placed && order.status !== OrderStatus.Cancelled) {
        existingStatus = order.status;
      }
    }
  }

  let headingText = '';
  if (existingStatus === OrderStatus.Refused) {
    headingText = i18n(
      planningDate ? 'pages.confirmOrderPlanningStates.headingRefused' : 'pages.confirmOrderStates.headingRefused',
    );
  } else if (existingStatus === OrderStatus.Cancelled) {
    headingText = i18n(
      planningDate ? 'pages.confirmOrderPlanningStates.headingCancelled' : 'pages.confirmOrderStates.headingCancelled',
    );
  } else if (existingStatus === OrderStatus.Confirmed || isConfirmed) {
    headingText = i18n(
      planningDate ? 'pages.confirmOrderPlanningStates.headingConfirmed' : 'pages.confirmOrderStates.headingConfirmed',
    );
  } else if (existingStatus === OrderStatus.Placed && !isConfirmed) {
    headingText = generalHeading;
  } else {
    headingText = i18n(
      planningDate
        ? 'pages.confirmOrderPlanningStates.headingUnknownStatus'
        : 'pages.confirmOrderStates.headingUnknownStatus',
    );
  }

  return (
    <div>
      <PageHeader title={generalHeading} />

      <h1 className="heading-one mb-4">{headingText}</h1>

      {orders.map((order) => {
        return <OrderSummary key={order.id} order={order} />;
      })}

      {existingStatus === OrderStatus.Placed && !isConfirmed && (
        <Button
          color="primary"
          onTrigger={handleClick}
          isDisabled={confirmOrderState.fetching}
          isLoading={confirmOrderState.fetching}
        >
          {i18n(planningDate ? 'pages.confirmPlanning.confirmButton' : 'pages.confirmOrder.confirmButton')}
        </Button>
      )}
    </div>
  );
};

const OrderConfirmationPage = () => {
  const [searchParams] = useSearchParams();
  const parsed = parseURLSearchParams(searchParams);

  return (
    <div>
      <div className="w-full mx-auto p-12 max-w-3xl">
        <SimpleErrorBoundary>
          <Suspense>
            <OrderConfirmationPageContent parsed={parsed} />
          </Suspense>
        </SimpleErrorBoundary>
      </div>
    </div>
  );
};

export default OrderConfirmationPage;
