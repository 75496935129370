import { useEffect, useState } from 'react';
import { useClient } from 'urql';

import { nullthrows } from '../../../utils/invariant';
import { getDisplayError } from '../../../utils/get-display-error';
import { PdfPreview, IPdfPreviewProps } from '../../../components/pdf';
import { SpinnerBlock } from '../../../components/Spinner';
import {
  GetDocumentLinkDocument,
  GetDocumentLinkQuery,
  GetDocumentLinkQueryVariables,
} from '../../../generated/graphql';
import { Button } from '../../../components/button/Button';

export interface IDocumentPreviewProps {
  document: { id: string; hash: string; name: string };
  rotations?: IPdfPreviewProps['rotations'];
  onRotate?: IPdfPreviewProps['onRotate'];
}

export const DocumentPreview: React.FC<IDocumentPreviewProps> = (props) => {
  const { document, rotations, onRotate } = props;
  const client = useClient();
  const [error, setError] = useState(null);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await client
        .query<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>(GetDocumentLinkDocument, {
          id: document.id,
          hash: document.hash,
        })
        .toPromise();
      if (result.error) {
        throw result.error;
      }

      setUrl(nullthrows(result.data?.documentLink, 'Document link not returned'));
    };

    fetch().catch((err) => setError(getDisplayError(err)));
  });

  if (url) {
    if (document.name.endsWith('.pdf')) {
      return <PdfPreview file={url} filename={document.name} rotations={rotations} onRotate={onRotate} />;
    } else {
      return (
        <div className="border border-dark-05 w-full h-full flex flex-col gap-4 justify-center items-center">
          <div className="text-md font-medium">Preview niet ondersteund voor {document.name}</div>
          <div>
            <Button
              onTrigger={() => {
                // eslint-disable-next-line no-restricted-globals
                window.open(url, '_blank');
              }}
            >
              Download bestand
            </Button>
          </div>
        </div>
      );
    }
  }

  if (error) {
    return <div>Could not load preview: {error}</div>;
  }

  return <SpinnerBlock message="Loading preview..." />;
};
