import * as Dialog from '@radix-ui/react-dialog';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { BaseButtonProps } from '../../../components/button/BaseButton';
import { FormDialog } from '../../../components/dialog/FormDialog';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { OrderComplaintType, useCustomerPortalFileComplaintMutation } from '../../../generated/graphql';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import toast from 'react-hot-toast';
import { useTranslation } from '../../../contexts/translation-context';
import { getDisplayError } from '../../../utils/get-display-error';
import { captureException } from '@sentry/react';

const schema = Yup.object().shape({
  type: Yup.object().required('errors.required'),
  content: Yup.string().required('errors.required'),
});

export interface IOrderComplaintDialogProps {
  orderId: string;
  triggerColor?: BaseButtonProps['color'];
  isDisabled?: boolean;
}

interface OrderComplaintDialogValues {
  type: ISimpleSelectItem;
  content: string;
}

export const OrderComplaintDialog: React.FC<IOrderComplaintDialogProps> = (props) => {
  const { orderId, triggerColor, isDisabled } = props;
  const { i18n } = useTranslation();
  const [, fileComplaint] = useCustomerPortalFileComplaintMutation();

  const handleSubmit = async (values: OrderComplaintDialogValues) => {
    try {
      const result = await fileComplaint({
        orderId,
        data: {
          type: values.type.key as OrderComplaintType,
          content: values.content,
        },
      });

      if (result.error) {
        throw result.error;
      }

      toast.success(i18n('customerPortal.orders.complaintFiled'));
    } catch (err) {
      captureException(err);
      console.error('Failed to send complaint', err);
      toast.error(
        i18n('customerPortal.orders.couldNotFileComplaint', {
          error: getDisplayError(err),
        }),
      );
    }
  };

  const items: ISimpleSelectItem[] = Object.values(OrderComplaintType).map((type) => ({
    key: type,
    name: i18n(`orderComplaintType.${type}`),
  }));

  const initialValues: OrderComplaintDialogValues = useMemo(() => {
    return {
      type: items.find((v) => v.key === OrderComplaintType.Other) || items[0],
      content: '',
    };
  }, []);

  return (
    <FormDialog
      triggerColor={triggerColor}
      isDisabled={isDisabled}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      triggerText={i18n('customerPortal.orders.fileComplaint')}
      title={i18n('customerPortal.orders.fileComplaint')}
      submitText={i18n('customerPortal.orders.send')}
    >
      <Dialog.Description className="mb-4">{i18n('customerPortal.orders.fileComplaintDescription')}</Dialog.Description>

      <SimpleSelectField items={items} labelText={i18n('customerPortal.orders.reason')} name="type" />

      <TextAreaField labelText={i18n('customerPortal.orders.details')} name="content" />
    </FormDialog>
  );
};
