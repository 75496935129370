import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useState } from 'react';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useForgotPasswordMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useTranslation } from '../../../contexts/translation-context';
import { captureException } from '@sentry/react';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Vereist'),
});

const ForgotPasswordPage = () => {
  const [, forgotPassword] = useForgotPasswordMutation();
  const { i18n, lang } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);

  return (
    <div className="h-screen">
      <PageHeader title={i18n('auth.forgotPassword.title')} />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center mx-auto max-w-lg">
          <h1 className="heading-one mb-8 mt-32">{i18n('auth.forgotPassword.title')}</h1>

          <div className="w-full">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={async (values) => {
                try {
                  const { email } = values;
                  const result = await forgotPassword({
                    email,
                    lang,
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.forgotPassword;
                  if (!succeeded) {
                    throw new Error(i18n('auth.forgotPassword.requestFailed'));
                  }

                  setEmailSent(true);
                  toast.success(i18n('auth.forgotPassword.instructionsSent'));
                } catch (err: any) {
                  captureException(err);
                  console.error('Failed to request new password: ' + getDisplayError(err));
                  toast.error(i18n('auth.forgotPassword.requestFailed'));
                }
              }}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <InputField labelText="Email" type="email" name="email" />

                  <div className="flex justify-between">
                    <LinkButton to="/login">{i18n('cancel')}</LinkButton>

                    <Button color="default" isDisabled={isSubmitting} isLoading={isSubmitting}>
                      {i18n('auth.forgotPassword.requestNewPassword')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <div className="text-center mt-8">
            {emailSent ? i18n('auth.forgotPassword.instructionsSent') : i18n('auth.forgotPassword.instructions')}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPasswordPage;
