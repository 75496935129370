import { ArrowClockwise } from '@phosphor-icons/react';
import { Breadcrumb } from 'components/Breadcrumb';
import { PageHeader } from 'components/PageHeader';
import { ITabItem, Tabs } from 'components/tabs/Tabs';
import {
  CustomerDocumentType,
  GetCustomerByIdQuery,
  UserRole,
  useGetCustomerByIdQuery,
  useLinkCustomerDocumentMutation,
  useUnlinkCustomerDocumentMutation,
  useUpdateCustomerMutation,
} from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useParams, useResolvedPath } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { DataField } from '../../../../components/DataField';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { InputDialog } from '../../../../components/dialog/InputDialog';
import { getDisplayError } from '../../../../utils/get-display-error';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { MultiFileUploaderButton } from '../../../document/components/MultiFileUploaderButton';
import { CustomerProvider } from './customerContext';
import { useAuth } from '../../../../contexts/auth-context';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';

export type Customer = NonNullable<GetCustomerByIdQuery['customer']>;

const CustomerPage = () => {
  const { me } = useAuth();
  const [, linkDocument] = useLinkCustomerDocumentMutation();
  const [, unlinkDocument] = useUnlinkCustomerDocumentMutation();
  const { customerId } = useParams<{ customerId: string }>();
  const [, updateCustomer] = useUpdateCustomerMutation();
  invariant(customerId);
  const [{ data, error }, refetch] = useGetCustomerByIdQuery({
    variables: {
      id: +customerId,
    },
  });

  const generalRoute = useResolvedPath('general');
  const contactsRoute = useResolvedPath('contacts');
  const usersRoute = useResolvedPath('users');
  const auditLogsRoute = useResolvedPath('audit-logs');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: 'Contacten',
        path: contactsRoute.pathname,
      },
      {
        title: 'Gebruikers klantenportaal',
        path: usersRoute.pathname,
      },
    ];

    if (me.role === UserRole.Admin) {
      res.push({
        title: 'Audit logs',
        path: auditLogsRoute.pathname,
      });
    }

    return res;
  }, []);

  const refreshData = useCallback(() => {
    return refetch({
      requestPolicy: 'network-only',
    });
  }, [refetch]);

  const customer = data?.customer;
  if (!customer) {
    if (error) {
      return <div>Kon klant niet laden: {error.message}</div>;
    } else {
      return <div>Klant niet gevonden</div>;
    }
  } else {
    return (
      <>
        <PageHeader title={customer.name} />

        <CustomerProvider customer={customer} refreshData={refreshData}>
          <PageHeading
            leftSide={
              <Breadcrumb
                parentItem={{
                  name: 'Klanten',
                  to: '/internal/customers',
                }}
                currentItem={customer.name}
              />
            }
            rightSide={
              <div className="flex gap-2">
                {customer.deactivationReason ? (
                  <ConfirmDialog
                    triggerText="Heractiveer klant"
                    title="Heractiveer klant"
                    submitText="Heractiveer"
                    description="Ben je zeker dat je de klant wilt heractiveren?"
                    onSubmit={async () => {
                      try {
                        const result = await updateCustomer({
                          id: customer.id,
                          data: {
                            deactivationReason: null,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Klant is geheractiveerd');
                      } catch (err) {
                        captureException(err);
                        toast.error('Klant heractiveren mislukt: ' + getDisplayError(err));
                      }
                    }}
                  />
                ) : (
                  <InputDialog
                    triggerText="Deactiveer klant"
                    title="Deactiveer klant"
                    submitText="Deactiveer"
                    description="Ben je zeker dat je de klant wilt deactiveren?"
                    labelText="Reden"
                    validation={Yup.string().min(5, 'Minimum 5 karakters')}
                    onSubmit={async (value: string) => {
                      try {
                        const result = await updateCustomer({
                          id: customer.id,
                          data: {
                            deactivationReason: value,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Klant is gedeactiveerd');
                      } catch (err) {
                        captureException(err);
                        toast.error('Klant deactiveren mislukt: ' + getDisplayError(err));
                        throw err;
                      }
                    }}
                  />
                )}
                <Button color="default" onTrigger={refreshData}>
                  <ArrowClockwise className="button-icon" />
                </Button>
              </div>
            }
          />

          <div className="px-4">
            <div className="flex justify-between items-center my-4">
              {!!customer.deactivationReason && (
                <div className="w-full flex mt-2">
                  <DataField title="Deactivatie reden">{customer.deactivationReason}</DataField>
                </div>
              )}

              <div className="flex gap-2 self-end">
                <FileUploaderButton
                  buttonText="Klantenfiche"
                  title="Klantenfiche"
                  initialName={'Klantenfiche'}
                  file={customer.documents.find((d) => d.type === CustomerDocumentType.CustomerFile)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      customerId: customer.id,
                      documentId: document.id,
                      type: CustomerDocumentType.CustomerFile,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <FileUploaderButton
                  buttonText="Akkoord e-facturatie"
                  title="Akkoord e-facturatie"
                  initialName={'Akkoord e-facturatie'}
                  file={customer.documents.find((d) => d.type === CustomerDocumentType.EInvoiceAgreement)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      customerId: customer.id,
                      documentId: document.id,
                      type: CustomerDocumentType.EInvoiceAgreement,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <FileUploaderButton
                  buttonText="Transport Instructies"
                  title="Transport Instructies"
                  initialName={'Transport Instructions'}
                  file={customer.documents.find((d) => d.type === CustomerDocumentType.TransportInstructions)?.document}
                  onSubmit={async (document) => {
                    const result = await linkDocument({
                      customerId: customer.id,
                      documentId: document.id,
                      type: CustomerDocumentType.TransportInstructions,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                  }}
                />

                <MultiFileUploaderButton
                  buttonText="Andere"
                  title="Andere"
                  files={customer.documents.filter((d) => d.type === CustomerDocumentType.Other).map((f) => f.document)}
                  onSubmit={async (documents) => {
                    let count = 0;
                    await Promise.allSettled(
                      documents.map(async (doc) => {
                        try {
                          const result = await linkDocument({
                            customerId: customer.id,
                            documentId: doc.id,
                            type: CustomerDocumentType.Other,
                          });
                          if (result.error) {
                            throw result.error;
                          }
                          count += 1;
                          toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                        } catch (err) {
                          captureException(err);
                          toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                        }
                      }),
                    );
                  }}
                  unlinkDocument={async (docId: string) => {
                    try {
                      const result = await unlinkDocument({
                        customerId: customer.id,
                        documentId: docId,
                        type: CustomerDocumentType.Other,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Bestand gewist');
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                    }
                  }}
                />
              </div>
            </div>

            <Tabs items={tabItems} />
          </div>
        </CustomerProvider>
      </>
    );
  }
};

export default CustomerPage;
