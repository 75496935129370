import { MagnifyingGlass } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetCustomerPortalCreditNotesDocument,
  GetCustomerPortalCreditNotesQuery,
  GetCustomerPortalCreditNotesQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { calculateInvoiceTotals } from '../../sale/utils/calculate';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';

export const CustomerPortalCreditNotesPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const { i18n } = useTranslation();
  const client = useClient();
  const { customer } = useCustomerPortalCustomer();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomerPortalCreditNotesQuery, GetCustomerPortalCreditNotesQueryVariables>(
          GetCustomerPortalCreditNotesDocument,
          {
            customerId: customer.id,
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalCreditNotes ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const tableHeaders: ITableHeader[] = [
    {
      id: 'id',
      name: i18n('customerPortal.creditNotes.creditNoteNumber'),
    },
    {
      id: 'date',
      name: i18n('customerPortal.creditNotes.creditNoteDate'),
    },
    {
      id: 'dueDate',
      name: i18n('customerPortal.creditNotes.dueDate'),
    },
    {
      id: 'total',
      name: i18n('customerPortal.creditNotes.total'),
    },
  ];

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = i18n('customerPortal.creditNotes.creditNotes');
  return (
    <>
      <PageHeader title={title} />

      <PageHeading leftSide={<Breadcrumb currentItem={title} />} />

      <div className="flex w-full mb-8 px-4">
        <Input
          type="text"
          placeholder={i18n('customerPortal.creditNotes.search')}
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={tableHeaders}
        data={page.data}
        mapData={(creditNote) => {
          const totals = calculateInvoiceTotals([
            ...creditNote.creditNoteOrders
              .map((o) =>
                o.lines
                  .map((l) => {
                    return {
                      ...l,
                      vatRateId: l.vatRate.id,
                    };
                  })
                  .flat(),
              )
              .flat(),
            ...creditNote.manualLines.map((v) => {
              return {
                ...v,
                vatRateId: v.vatRate.id,
              };
            }),
          ]);

          return [
            <Link to={`${creditNote.id}`} className="link-text">
              {creditNote.creditNoteNumber ?? 'DRAFT'}
            </Link>,
            formatDate(creditNote.date),
            creditNote.expiresAt ? formatDate(creditNote.expiresAt) : '-',
            <div className="whitespace-nowrap">{`€ ${formatNumber(totals.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>,
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
