import {
  OrderDocumentType,
  OrderLineStopType,
  SortDirection,
  useGetOrdersQuery,
} from '../../../../../generated/graphql';
import classNames from '@utils/classnames';
import { CalendarDots, Check, Clock, X } from '@phosphor-icons/react';
import { formatDate, formatInputTime } from '../../../../../utils/date';
import { useCreatePurchaseCtx } from './context';
import { nullthrows } from '../../../../../utils/invariant';

export interface Props {
  searchValue: string;
}

export const OrderSelect: React.FC<Props> = (props) => {
  const { searchValue } = props;
  const { state, dispatch } = useCreatePurchaseCtx();
  const supplier = nullthrows(state.selectedSupplier, 'No supplier selected');
  const [data] = useGetOrdersQuery({
    variables: {
      filters: {
        supplierId: supplier.id,
        purchaseId: null,
        orderNumber: searchValue || undefined,
      },
      orderBy: {
        orderNumber: SortDirection.Asc,
      },
      take: 100,
    },
    requestPolicy: 'network-only',
  });
  const orders = data.data?.orders ?? [];
  const selectedOrderIds = new Set(state.orders.map((o) => o.id));

  return (
    <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3">
      {!orders.length && <div>Geen orders gevonden</div>}

      {orders.map((o) => {
        const isSelected = selectedOrderIds.has(o.id);
        return (
          <div
            key={o.id}
            className={classNames('card p-4', {
              'selected-card': isSelected,
            })}
            data-clickable="true"
            onClick={() => {
              dispatch({
                type: 'SELECT_ORDER',
                order: o,
              });
            }}
          >
            <div className="card-heading-text">{o.orderNumber}</div>

            {o.lines.map((line) => {
              return (
                <div>
                  {line.stops
                    .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                    .map((stop) => {
                      return (
                        <div className="grid grid-cols-2 my-1">
                          <div className="font-medium">{stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}</div>
                          <div>
                            {stop.location.country}-{stop.location.name}
                          </div>

                          <div className="flex gap-2 items-center">
                            <CalendarDots className="w-4 h-4" />
                            {formatDate(stop.date)}
                          </div>
                          <div className="flex gap-2 items-center">
                            <Clock className="w-4 h-4" />
                            {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}

            <div className="flex font-medium items-center">
              CMR{' '}
              {!!o.documents.find((d) => d.type === OrderDocumentType.Cmr) ? (
                <Check className="w-6 h-6 text-feedback-positive" />
              ) : (
                <X className="w-6 h-6 text-feedback-negative-04" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
