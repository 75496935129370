import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTranslation } from '../../../contexts/translation-context';
import { DataField } from '../../../components/DataField';
import { PageHeading } from 'components/PageHeading';
import { formatVatNumber } from '@utils/vat-number';
import { useOurContactInformationQuery } from 'generated/graphql';
import { formatPhoneNumber } from '@utils/phonenumber';

export const OurInformationPage = () => {
  const { i18n } = useTranslation();
  const [{ data }] = useOurContactInformationQuery();

  const information = data?.ourContactInformation;
  if (!information) {
    return null;
  }

  return (
    <div>
      <PageHeader title={i18n('customerPortal.ourInformation.ourInformation')} />

      <PageHeading leftSide={<Breadcrumb currentItem={i18n('customerPortal.ourInformation.ourInformation')} />} />

      <div className="px-4">
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.name')}>{information.name}</DataField>
        </div>

        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.vatNumber')}>
            {formatVatNumber(information.vatNumber)}
          </DataField>
        </div>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.businessNumber')}>
            {information.companyNumber}
          </DataField>
        </div>

        <div className="mb-2">
          <DataField
            title={i18n('customerPortal.ourInformation.address')}
          >{`${information.street} ${information.streetNumber}, ${information.postalCode} ${information.city}, ${information.country}`}</DataField>
        </div>

        <h2 className="heading-three mt-4 mb-2">{i18n('customerPortal.ourInformation.contact')}</h2>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.phoneNumber')}>
            {formatPhoneNumber(information.phoneNumber)}
          </DataField>
        </div>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.emailTransport')}>
            {information.emailTransport}
          </DataField>
        </div>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.emailAccounting')}>
            {information.emailAccounting}
          </DataField>
        </div>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.ourInformation.emailGeneral')}>{information.emailGeneral}</DataField>
        </div>
      </div>
    </div>
  );
};
