import React from 'react';
import * as Select from '@radix-ui/react-select';
import { X, Check, CaretDown } from '@phosphor-icons/react';

import classNames from '@utils/classnames';

interface ISelectItemProps {
  children: React.ReactNode;
  value: string;
  selected: boolean | null;
  className?: string;
}

const SelectItem = React.forwardRef<HTMLDivElement | null, ISelectItemProps>((props, forwardedRef) => {
  const { children, selected, className, ...otherProps } = props;

  return (
    <Select.Item
      className={classNames(
        'relative cursor-pointer select-none py-2 pl-3 pr-9 focus:outline-none hover:bg-dark-05 focus:bg-dark-05',
        className,
      )}
      {...otherProps}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      {selected && (
        <Select.ItemIndicator
          className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', 'text-dark-01')}
        >
          <Check className="h-5 w-5" aria-hidden="true" />
        </Select.ItemIndicator>
      )}
    </Select.Item>
  );
});

export interface ISelectProps<V extends Record<string, any>> {
  placeholder?: string;
  items: V[];
  selectedItem: V | null;
  onSelect: (value: V | null) => void;
  keyName: string;
  display: (value: V) => string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isOptional?: boolean;
}

export function CustomSelect<V extends Record<string, any>>(props: ISelectProps<V>) {
  const { placeholder, keyName, items, display, selectedItem, onSelect, isDisabled, isInvalid, onBlur, isOptional } =
    props;
  const [key, setKey] = React.useState(0);

  return (
    <div className="w-full flex gap-1">
      <div className="w-full">
        <Select.Root
          value={selectedItem ? selectedItem[keyName] : null}
          onValueChange={(value) => {
            const item = items.find((item) => item[keyName] === value);
            onSelect(item || null);
          }}
          onOpenChange={(open) => {
            if (!open) {
              onBlur?.();
            }
          }}
          disabled={isDisabled}
          required={!isOptional}
          key={key}
        >
          <Select.Trigger
            className={classNames(
              'flex justify-between items-center w-full border bg-offwhite py-2 px-3 focus:border-orange-04 focus:outline-none rounded',
              {
                'border-dark-04': !isInvalid && !isDisabled,
                'border-dark-05': isDisabled,
                'border-feedback-negative-04': isInvalid,
              },
            )}
          >
            <Select.Value placeholder={selectedItem ? display(selectedItem) : (placeholder ?? '')} />
            <Select.Icon>
              <CaretDown
                className={classNames('h-5 w-5', {
                  'text-dark-01': !isDisabled,
                  'text-dark-05': isDisabled,
                })}
                aria-hidden="true"
              />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content
              className="z-dropdown mt-1 max-h-60 w-full overflow-auto bg-offwhite py-1 text-base focus:outline-none rounded border border-dark-04"
              style={{
                minWidth: 'var(--radix-select-trigger-width)',
                maxHeight: 'var(--radix-select-content-available-height)',
              }}
              position="popper"
            >
              <Select.Viewport>
                {items.map((item) => (
                  <SelectItem
                    key={item[keyName]}
                    value={item[keyName]}
                    selected={selectedItem && item[keyName] === selectedItem[keyName]}
                  >
                    {display(item)}
                  </SelectItem>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>

      {Boolean(selectedItem && isOptional) && (
        <div
          className="w-8 flex items-center justify-center hover:bg-dark-05 rounded-md cursor-pointer"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            onSelect(null);
            setKey((key) => key + 1);
          }}
        >
          <X className="button-icon" />
        </div>
      )}
    </div>
  );
}

export { CustomSelect as Select };
