import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { useUpdateGeneralSettingsMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAllGeneralSettings } from '../all-settings-context';
import { InputField } from '../../../components/input/InputField';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../order/vatRate/VatRateComboboxField';
import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { captureException } from '@sentry/react';

const invoiceSettingsSchema = Yup.object().shape({
  invoiceNumber: Yup.string().required('Vereist'),
});

interface IInvoiceSettingsValues {
  invoiceNumber: string;
  creditNoteNumber: string;
  reverseChargedVatRate: ISimpleComboboxItem | null;
}

const InvoiceSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [_updateState, updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const initialValues: IInvoiceSettingsValues = useMemo(() => {
    return {
      invoiceNumber: '' + settings.invoiceNumber,
      creditNoteNumber: '' + settings.creditNoteNumber,
      reverseChargedVatRate: settings.reverseChargedVatRate
        ? vatRateToComboboxItem(settings.reverseChargedVatRate)
        : null,
    };
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={invoiceSettingsSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const { invoiceNumber, creditNoteNumber, reverseChargedVatRate, ...otherValues } = patch;
          const result = await updateGeneralSettings({
            data: {
              invoiceNumber: invoiceNumber ? +invoiceNumber : undefined,
              creditNoteNumber: creditNoteNumber ? +creditNoteNumber : undefined,
              reverseChargedVatRateId: reverseChargedVatRate ? reverseChargedVatRate?.key : undefined,
              ...otherValues,
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Instellingen aangepast');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon algemene instellingen niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Volgende verkoopfactuur nummer" type="number" name="invoiceNumber" />

            <InputField labelText="Volgende credit nota nummer" type="number" name="creditNoteNumber" />

            <VatRateComboboxField labelText="Btw type voor verlegde btw" name="reverseChargedVatRate" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<Plus className="button-icon" />}
            >
              Pas instellingen aan
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default InvoiceSettingsPage;
