import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { useMeQuery } from '../../../generated/graphql';
import { GoogleAuthButton } from '../components/GoogleAuthButton';

export const InternalLoginPage = () => {
  const navigate = useNavigate();
  const [{ data }] = useMeQuery({
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.me) {
      navigate('/internal');
    }
  }, [data?.me]);

  // eslint-disable-next-line no-restricted-globals
  const redirectUri = window.location.origin;
  return (
    <>
      <PageHeader title="Interne Login" />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center mx-auto max-w-lg">
          <img style={{ width: '16rem' }} className="mt-16 mb-16" src="/static/logo-dark.svg" />

          <div className="w-full flex">
            <GoogleAuthButton redirectUri={redirectUri} />
          </div>
        </div>
      </main>
    </>
  );
};
