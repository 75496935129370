export function getFullUrl(path: string): string {
  // eslint-disable-next-line no-restricted-globals
  return new URL(path, window.location.href).href;
}

export function urlJoin(...args: string[]): string {
  try {
    const url = new URL(args.join('/'));
    url.pathname = url.pathname.replace(/\/+/g, '/');
    return url.toString();
  } catch (err) {
    return args.join('/').replace(/\/+/g, '/');
  }
}
