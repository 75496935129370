import { Plus, MagnifyingGlass } from '@phosphor-icons/react';
import { createId } from '@paralleldrive/cuid2';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import { IterableElement } from 'type-fest';

import { Button } from '../../../components/button/Button';
import { Combobox } from '../../../components/combobox/Combobox';
import { InputWrapper } from '../../../components/InputWrapper';
import { Country, GetLocationsQuery, useGetCustomerPortalLocationsQuery } from '../../../generated/graphql';
import { getFullUrl } from '../../../utils/url';
import { openPopup } from '../../../utils/windows';
import { LocationPreviewDialog } from './CustomerPortalLocationPreviewDialog';
import { CustomerPortalCreateLocationDialog } from './CustomerPortalCreateLocationDialog';
import { useTranslation } from '../../../contexts/translation-context';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';

export type Location = IterableElement<GetLocationsQuery['locations']>;

export interface IMinimalLocation {
  id: number;
  name: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  country: Country;
}

type ComboValue = IMinimalLocation;

const displayLocation = (location: ComboValue) => {
  return `${location.name} - ${location.city} ${location.country}`;
};

const locationQueryContext = {
  suspense: false,
};

const createLocationPopup = (): Promise<IMinimalLocation> => {
  const popupId = createId();
  const targetUrl = getFullUrl(`/internal/locations/new?minimal=1&popup-id=${popupId}`);
  const targetWindow = openPopup(targetUrl);
  return new Promise((resolve, reject) => {
    if (targetWindow) {
      // eslint-disable-next-line no-restricted-globals
      window.addEventListener('message', (evt) => {
        const message = evt.data;
        if (typeof message === 'object' && message.id === popupId && message.type === 'created-location') {
          resolve(message.data);
        }
      });
    } else {
      reject(new Error('Could not spawn popup window'));
    }
  });
};

export interface ILocationComboboxProps {
  value: ComboValue | null;
  onChange: (val: IMinimalLocation | null) => void;
  onBlur?: () => void;
  invalidText?: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
  hideInvalid?: boolean;
  isNullable?: boolean;
}

export const CustomerPortalLocationCombobox: React.FC<ILocationComboboxProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    invalidText,
    labelText,
    helperText,
    isDisabled,
    isNullable = true,
    hideInvalid,
  } = props;
  const { i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [showLocationPreview, setShowLocationPreview] = useState(false);
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const { customer } = useCustomerPortalCustomer();
  const [{ data }] = useGetCustomerPortalLocationsQuery({
    variables: {
      customerId: customer.id,
      search: searchValue,
    },
    context: locationQueryContext,
  });

  const locations: ComboValue[] = useMemo(() => {
    return data?.customerPortalLocations ? [...data.customerPortalLocations] : [];
  }, [data?.customerPortalLocations]);

  return (
    <div className="flex gap-2">
      <InputWrapper
        labelText={labelText ?? 'Locatie'}
        invalidText={invalidText}
        helperText={helperText}
        hideInvalid={hideInvalid}
      >
        <Combobox
          keyName="id"
          placeholder={i18n('customerPortal.locations.findLocation')}
          onQueryChange={setSearchValue}
          display={displayLocation}
          items={locations}
          selectedItem={value}
          onSelect={(val) => {
            onChange(val);
            setShowLocationPreview(false);
          }}
          onBlur={onBlur}
          isDisabled={isDisabled}
          isInvalid={!!invalidText}
          isNullable={isNullable}
        />
      </InputWrapper>

      {value && (
        <div className="flex items-center">
          <Button
            color="default"
            onTrigger={() => {
              setShowLocationPreview(true);
            }}
          >
            <MagnifyingGlass className="button-icon" />
          </Button>
          <LocationPreviewDialog location={value} isOpen={showLocationPreview} setIsOpen={setShowLocationPreview} />
        </div>
      )}

      <div className="flex items-center">
        <Button
          color="default"
          onTrigger={() => {
            setShowCreationDialog(true);
          }}
        >
          <Plus className="button-icon" />
        </Button>
        <CustomerPortalCreateLocationDialog
          isOpen={showCreationDialog}
          setIsOpen={setShowCreationDialog}
          onCreated={(data) => {
            onChange(data);
            setShowCreationDialog(false);
            setShowLocationPreview(false);
          }}
        />
      </div>
    </div>
  );
};

export interface ICustomerPortalLocationComboboxFieldProps {
  name: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const CustomerPortalLocationComboboxField: React.FC<ICustomerPortalLocationComboboxFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <CustomerPortalLocationCombobox
      value={field.value}
      onChange={(val) => helpers.setValue(val)}
      onBlur={() => helpers.setTouched(true)}
      invalidText={(meta.touched && meta.error) || undefined}
      labelText={labelText}
      helperText={helperText}
      isDisabled={isDisabled}
    />
  );
};
