import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { useCreateVatRateMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { getDisplayError } from '../../../utils/get-display-error';
import { parseNumberInput } from '../../../utils/number';
import { captureException } from '@sentry/react';

const createVatRateSchema = Yup.object().shape({
  id: Yup.string().required('Vereist'),
  amount: Yup.string().required('Vereist'),
  description: Yup.string().required('Vereist'),
});

interface IVatRateValues {
  id: string;
  amount: string;
  description: string;
}

const initialValues: IVatRateValues = {
  id: '',
  amount: '0.00',
  description: '',
};

interface ICreateVatRateFormProps {
  onComplete?: (value: { id: string; amount: number; description: string }) => void;
  onCancel?: () => void;
}

export const CreateVatRateForm: React.FC<ICreateVatRateFormProps> = (props) => {
  const { onComplete, onCancel } = props;
  const [, createVatRateMutation] = useCreateVatRateMutation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createVatRateSchema}
      onSubmit={async (values) => {
        try {
          const result = await createVatRateMutation({
            data: {
              ...values,
              amount: parseNumberInput(values.amount, 2),
            },
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            onComplete?.({
              ...values,
              amount: parseNumberInput(values.amount, 2),
            });
          }
          toast.success('btw type aangemaakt');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon btw type niet aanmaken: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Code" type="text" name="id" />
            <InputField labelText="Percentage" type="text" name="amount" />
            <InputField labelText="Beschrijving" type="text" name="description" />

            <div className="flex justify-between">
              {onCancel && (
                <div>
                  <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
                    Annuleer
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onTrigger={submitForm}
              >
                Maak btw type aan
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
