import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useDebouncedSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [newValue, setNewValue] = useState(() => {
    const result: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      result[key] = value;
    }
    return result;
  });

  const debouncedSetSearchParams = useCallback(
    (input: Record<string, string>) => {
      setNewValue((prev) => {
        return {
          ...prev,
          ...input,
        };
      });
    },
    [setNewValue],
  );

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setSearchParams(newValue);
    }, 100);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [newValue]);

  return [newValue, debouncedSetSearchParams] as const;
}
