import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { useVatRatesQuery, useUpdateVatRateMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { getDisplayError } from '../../../utils/get-display-error';
import { nullthrows } from '../../../utils/invariant';
import { formatNumber, parseNumberInput } from '../../../utils/number';
import { captureException } from '@sentry/react';

const createVatRateSchema = Yup.object().shape({
  id: Yup.string().required('Vereist'),
  amount: Yup.string().required('Vereist'),
  description: Yup.string().required('Vereist'),
});

interface IVatRateValues {
  id: string;
  amount: string;
  description: string;
}

interface IUpdateVatRateFormProps {
  id: string;
  onComplete?: (values: { id: string; amount: number; description: string }) => void;
  onCancel?: () => void;
}

export const UpdateVatRateForm: React.FC<IUpdateVatRateFormProps> = (props) => {
  const { id, onComplete, onCancel } = props;
  const [{ data }] = useVatRatesQuery({
    requestPolicy: 'cache-and-network',
  });
  const [, updateVatRateMutation] = useUpdateVatRateMutation();

  const vatRate = nullthrows(
    data?.vatRates.find((v) => v.id === id),
    'Vat rate not found',
  );
  const initialValues: IVatRateValues = useMemo(() => {
    return {
      id: vatRate.id,
      amount: formatNumber(vatRate.amount, 2),
      description: vatRate.description,
    };
  }, [vatRate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createVatRateSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const result = await updateVatRateMutation({
            id: vatRate.id,
            data: {
              ...patch,
              amount: patch.amount ? parseNumberInput(patch.amount, 2) : undefined,
            },
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            onComplete?.({
              ...values,
              amount: parseNumberInput(values.amount, 2),
            });
          }
          toast.success('btw type aangepast');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon btw type niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Code" type="text" name="id" />
            <InputField labelText="Percentage" type="text" name="amount" />
            <InputField labelText="Beschrijving" type="text" name="description" />

            <div className="flex justify-between">
              {onCancel && (
                <div>
                  <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
                    Annuleer
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onTrigger={submitForm}
              >
                Pas btw type aan
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
