import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import {
  SupplierTruckDocumentType,
  useLinkSupplierTruckDocumentMutation,
  useUpdateSupplierTruckMutation,
} from 'generated/graphql';
import diff from 'object-diff';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { useSupplier } from '../Supplier/supplierContext';
import { formatInputDate } from '../../../../utils/date';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { InternalUser, UserComboboxField } from '../../../user/components/UserCombobox';
import { captureException } from '@sentry/react';

const updateTruckSchema = Yup.object().shape({
  licensePlate: Yup.string().required('Vereist'),
  isActive: Yup.boolean(),
  insuranceExpiresAt: Yup.string().required('Vereist'),
  examinationExpiresAt: Yup.string().required('Vereist'),
  internalNotes: Yup.string(),
});

export const UpdateSupplierTruckPage = () => {
  const navigate = useNavigate();
  const { supplier } = useSupplier();
  const { supplierTruckId } = useParams<{ supplierTruckId: string }>();
  invariant(supplierTruckId);
  const [, updateTruckMutation] = useUpdateSupplierTruckMutation();
  const [, linkDocument] = useLinkSupplierTruckDocumentMutation();

  const truck = supplier.trucks.find((c) => c.id === supplierTruckId);

  if (!truck) {
    return <div>Trekker niet gevonden</div>;
  } else {
    const initialValues = {
      name: truck.name,
      licensePlate: truck.licensePlate,
      insuranceExpiresAt: formatInputDate(truck.insuranceExpiresAt),
      examinationExpiresAt: formatInputDate(truck.examinationExpiresAt),
      isActive: truck.isActive,
      internalNotes: truck.internalNotes,
      planner: truck.planner as null | InternalUser,
    };

    return (
      <div>
        <div className="flex gap-2 justify-end mb-4">
          <FileUploaderButton
            buttonText="Verzekering"
            title="Verzekering"
            initialName={`Verzekering - ${truck.licensePlate}`}
            file={truck.documents.find((d) => d.type === SupplierTruckDocumentType.Insurance)?.document}
            onSubmit={async (document) => {
              const result = await linkDocument({
                supplierTruckId,
                documentId: document.id,
                type: SupplierTruckDocumentType.Insurance,
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('AB Verzekering opgeladen');
            }}
          />

          <FileUploaderButton
            buttonText="Keuring"
            title="Keuring"
            initialName={`Keuring - ${truck.licensePlate}`}
            file={truck.documents.find((d) => d.type === SupplierTruckDocumentType.Examination)?.document}
            onSubmit={async (document) => {
              const result = await linkDocument({
                supplierTruckId,
                documentId: document.id,
                type: SupplierTruckDocumentType.Examination,
              });
              if (result.error) {
                throw result.error;
              }
            }}
          />
        </div>

        <h1 className="heading-one mb-4">Pas trekker aan</h1>

        <Formik
          initialValues={initialValues}
          validationSchema={updateTruckSchema}
          onSubmit={async (values) => {
            try {
              const patch: Partial<typeof values> = diff(initialValues, values);
              const { planner, ...rest } = patch;
              const updateData = {
                ...rest,
                plannerId: planner ? planner.id : undefined,
              };
              const result = await updateTruckMutation({
                id: supplierTruckId,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                navigate('..');
              }
              toast.success('Trekker aangepast');
            } catch (err: any) {
              captureException(err);
              toast.error('Kon trekker niet aanpassen: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Codenaam" type="text" name="name" />
              <InputField labelText="Nummerplaat" type="text" name="licensePlate" />
              <InputField labelText="Verzekering vervaldag" type="date" name="insuranceExpiresAt" />
              <InputField labelText="Keuring vervaldag" type="date" name="examinationExpiresAt" />
              <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              <CheckboxField labelText="Rijdt actief voor ons" name="isActive" />
              <UserComboboxField labelText="Verantwoordelijke planner" name="planner" />
              <Button
                type="submit"
                color="primary"
                iconLeft={<Plus className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Bewaar aanpassingen
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};
