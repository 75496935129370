import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useSetPasswordMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { nullthrows } from '../../../utils/invariant';
import { useTranslation } from '../../../contexts/translation-context';
import { captureException } from '@sentry/react';

const setPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(12, 'errors.passwordTooShort').required('errors.required'),
  repeatNewPassword: Yup.string()
    .min(12, 'errors.passwordTooShort')
    .required('errors.required')
    .test('passwordsAreEqual', 'errors.passwordsDontMatch', function () {
      return this.parent.newPassword === this.parent.repeatNewPassword;
    }),
});

const SetNewPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [, setPassword] = useSetPasswordMutation();
  const { i18n } = useTranslation();

  return (
    <div className="h-screen">
      <PageHeader title={i18n('auth.configNewPassword.title')} />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center mx-auto max-w-lg">
          <h1 className="heading-one mb-8 mt-32">{i18n('auth.configNewPassword.title')}</h1>

          <div className="w-full">
            <Formik
              initialValues={{
                newPassword: '',
                repeatNewPassword: '',
              }}
              validationSchema={setPasswordSchema}
              onSubmit={async (values) => {
                try {
                  const { newPassword, repeatNewPassword } = values;
                  if (newPassword !== repeatNewPassword) {
                    throw new Error(i18n('auth.configNewPassword.passwordDontMatch'));
                  }

                  const token = nullthrows(searchParams.get('token'), i18n('auth.configNewPassword.tokenNotFound'));
                  const result = await setPassword({
                    token,
                    newPassword,
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.setPassword;
                  if (!succeeded) {
                    throw new Error(i18n('auth.configNewPassword.invalidToken'));
                  }

                  // eslint-disable-next-line no-restricted-globals
                  window.location.href = '/login';
                } catch (err: any) {
                  captureException(err);
                  toast.error(
                    i18n('auth.configNewPassword.configFailed', {
                      error: getDisplayError(err),
                    }),
                  );
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <InputField
                    labelText={i18n('auth.configNewPassword.newPassword')}
                    type="password"
                    name="newPassword"
                  />
                  <InputField
                    labelText={i18n('auth.configNewPassword.repeatNewPassword')}
                    type="password"
                    name="repeatNewPassword"
                  />

                  <div className="flex justify-end">
                    <Button type="submit" color="default" isDisabled={isSubmitting} isLoading={isSubmitting}>
                      {i18n('auth.configNewPassword.confirmButtonText')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SetNewPasswordPage;
