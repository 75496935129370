import * as Sentry from '@sentry/react';

// @ts-ignore
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

function getEnvironment() {
  // eslint-disable-next-line no-restricted-globals
  if (window.location.hostname.includes('portal.stag')) {
    return 'staging';
    // eslint-disable-next-line no-restricted-globals
  } else if (window.location.hostname.includes('ngrok.io') || window.location.hostname.includes('localhost')) {
    return 'development';
  } else {
    return 'production';
  }
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: getEnvironment(),
  integrations: [],
});
