import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { Language, useCreateSupplierContactMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { sleep } from '../../../../utils/sleep';
import { useSupplier } from '../Supplier/supplierContext';
import { captureException } from '@sentry/react';

const createContactSchema = Yup.object().shape({
  name: Yup.string().required('Vereist'),
  email: Yup.string().email('Ongeldig email'),
  phone: Yup.string().phone(),
  function: Yup.string(),
  language: Yup.mixed().nullable().required('Vereist'),
  shouldReceiveTransportOrders: Yup.boolean().required('Vereist'),
  shouldReceiveSelfBillings: Yup.boolean().required('Vereist'),
});

const CreateSupplierContactPage = () => {
  const navigate = useNavigate();
  const { supplier, refreshData: refetchSupplier } = useSupplier();
  const [, createContactMutation] = useCreateSupplierContactMutation();

  return (
    <div>
      <h1 className="heading-one mb-4">Nieuw contact</h1>

      <Formik
        initialValues={{
          name: '',
          email: '',
          function: '',
          phone: '',
          language: LANGUAGE_VALUES.find((v) => v.key === supplier.language)!,
          internalNotes: '',
          shouldReceiveTransportOrders: false,
          shouldReceiveSelfBillings: false,
        }}
        validationSchema={createContactSchema}
        onSubmit={async (values) => {
          try {
            const result = await createContactMutation({
              data: {
                ...values,
                language: values.language.key as Language,
                supplierId: supplier.id,
                phone: formatPhoneNumber(values.phone, false),
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchSupplier();
              await sleep(100);
              navigate('..');
            }
            toast.success('Contact aangemaakt');
          } catch (err: any) {
            captureException(err);
            toast.error('Kon contact niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Naam" type="text" name="name" />
            <InputField labelText="Email" type="text" name="email" />
            <InputField labelText="Telefoon" type="text" name="phone" />
            <InputField labelText="Functie" type="text" name="function" />
            <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
            <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
            <h2 className="heading-two mb-2">Contact geschikt voor</h2>
            <div className="flex gap-4 mb-2">
              <CheckboxField labelText="Transport opdracht" name="shouldReceiveTransportOrders" />
              <CheckboxField labelText="Self billings" name="shouldReceiveSelfBillings" />
            </div>
            <Button
              type="submit"
              color="primary"
              iconLeft={<Plus className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Maak contact aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateSupplierContactPage;
