import { Plus } from '@phosphor-icons/react';
import { Clipboard, Copy, Trash, X } from '@phosphor-icons/react';
import { createId } from '@paralleldrive/cuid2';
import { useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { IterableElement } from 'type-fest';

import { Button } from '../../../../components/button/Button';
import { Input } from '../../../../components/input/Input';
import {
  Day as DayEnum,
  UpdateLocationOpeningTimeInputData,
  useUpdateOpeningHoursMutation,
} from '../../../../generated/graphql';
import { formatInputTime, parseInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { Location } from '../Location';
import { useLocation } from '../Location/locationContext';
import { captureException } from '@sentry/react';

type LocationOpeningTime = IterableElement<Location['openingTimes']>;

type EntryMap = Record<DayEnum, OpeningHourEntry[]>;

type OpeningHoursAction =
  | {
      type: 'change';
      day: DayEnum;
      index: number;
      opening: string;
      closing: string;
    }
  | {
      type: 'add';
      day: DayEnum;
    }
  | {
      type: 'delete';
      day: DayEnum;
      id: string;
    }
  | {
      type: 'validated';
      value: EntryMap;
    }
  | {
      type: 'copy';
      day: DayEnum;
    }
  | {
      type: 'paste';
      day: DayEnum;
    }
  | {
      type: 'cancel-copy';
    };

interface OpeningHourEntry {
  id: string;
  opening: string;
  closing: string;
  error: string;
}

interface OpeningHoursState {
  days: EntryMap;
  copying?: DayEnum;
}

function getInitialState(openingHours: LocationOpeningTime[]): OpeningHoursState {
  const days: EntryMap = {
    [DayEnum.Monday]: [],
    [DayEnum.Tuesday]: [],
    [DayEnum.Wednesday]: [],
    [DayEnum.Thursday]: [],
    [DayEnum.Friday]: [],
    [DayEnum.Saturday]: [],
    [DayEnum.Sunday]: [],
  };

  for (const entry of openingHours) {
    days[entry.day].push({
      id: createId(),
      opening: formatInputTime(entry.opening),
      closing: formatInputTime(entry.closing),
      error: '',
    });
  }

  return { days };
}

function validateValues(values: EntryMap): { days: EntryMap; hasError: boolean } {
  const days: EntryMap = { ...values };
  let hasError = false;
  for (const [key, value] of Object.entries(days)) {
    const sortedValue: OpeningHourEntry[] = value
      .filter((val) => parseInputTime(val.closing) - parseInputTime(val.opening) > 0)
      .sort((a, b) => parseInputTime(a.opening) - parseInputTime(b.opening));

    for (let i = 1; i < sortedValue.length; i++) {
      const prevValue = sortedValue[i - 1];
      const currValue = sortedValue[i];
      if (parseInputTime(currValue.opening) < parseInputTime(prevValue.closing)) {
        prevValue.error = 'Openingstijd valt voor vorige sluitingstijd';
        hasError = true;
      }
    }

    // @ts-ignore
    days[key] = sortedValue;
  }
  return { days, hasError };
}

function openingHoursReducer(state: OpeningHoursState, action: OpeningHoursAction) {
  switch (action.type) {
    case 'add':
      let times = state.days[action.day];
      times = times.filter((val) => parseInputTime(val.closing) - parseInputTime(val.opening) > 0);
      times.push({
        id: createId(),
        opening: '00:00',
        closing: '00:00',
        error: '',
      });
      state.days[action.day] = times;
      return { days: state.days };
    case 'change':
      const foundValue = state.days[action.day][action.index];
      foundValue.opening = action.opening;
      foundValue.closing = action.closing;
      return { days: state.days };
    case 'delete':
      let items = state.days[action.day];
      state.days[action.day] = items.filter((i) => i.id !== action.id);
      return { days: state.days };
    case 'validated':
      return {
        days: action.value,
      };
    case 'copy':
      return {
        ...state,
        copying: action.day,
      };
    case 'paste':
      if (!state.copying) {
        return state;
      }

      const days = { ...state.days };
      days[action.day] = days[state.copying].map((v) => {
        return {
          ...v,
          id: createId(),
        };
      });
      return {
        ...state,
        days,
      };
    case 'cancel-copy':
      return {
        ...state,
        copying: undefined,
      };
    default:
      return state;
  }
}

function flattenDays(days: EntryMap): UpdateLocationOpeningTimeInputData[] {
  const values: UpdateLocationOpeningTimeInputData[] = [];
  for (const [key, value] of Object.entries(days)) {
    for (const entry of value) {
      values.push({
        day: key as DayEnum,
        opening: parseInputTime(entry.opening),
        closing: parseInputTime(entry.closing),
      });
    }
  }
  return values;
}

const LocationOpeningHours = () => {
  const { location } = useLocation();
  const [data, dispatch] = useReducer(openingHoursReducer, getInitialState(location.openingTimes));
  const [isLoading, setIsLoading] = useState(false);
  const [_updateHoursState, updateOpeningHours] = useUpdateOpeningHoursMutation();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { days, hasError } = validateValues(data.days);
      dispatch({
        type: 'validated',
        value: days,
      });

      if (hasError) {
        throw new Error('Validatie fout');
      }

      const flattened = flattenDays(days);
      const result = await updateOpeningHours({
        id: location.id,
        data: flattened,
      });

      if (result.error) {
        throw result.error;
      }

      toast.success('Openingsuren aangepast');
    } catch (err) {
      captureException(err);
      toast.error('Kon openingsuren niet aanpassen: ' + getDisplayError(err));
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="max-w-md">
        {Object.entries(data.days).map(([day, time]) => {
          return (
            <div key={day} className="mb-4">
              <div className="flex justify-between items-center">
                <div className="font-medium">{day}</div>
                <div className="flex gap-1">
                  {!data.copying && (
                    <Button
                      onTrigger={async () => {
                        dispatch({
                          type: 'copy',
                          day: day as DayEnum,
                        });
                      }}
                      isDisabled={isLoading}
                    >
                      <Copy className="w-4 h-4" />
                    </Button>
                  )}
                  {data.copying && data.copying !== day && (
                    <Button
                      onTrigger={async () => {
                        dispatch({
                          type: 'paste',
                          day: day as DayEnum,
                        });
                      }}
                      isDisabled={isLoading}
                    >
                      <Clipboard className="w-4 h-4" />
                    </Button>
                  )}
                  {data.copying && data.copying === day && (
                    <Button
                      onTrigger={async () => {
                        dispatch({
                          type: 'cancel-copy',
                        });
                      }}
                      color="primary"
                      isDisabled={isLoading}
                    >
                      <X className="button-icon" />
                    </Button>
                  )}
                  <Button
                    onTrigger={() => {
                      dispatch({
                        type: 'add',
                        day: day as DayEnum,
                      });
                    }}
                    isDisabled={isLoading}
                  >
                    <Plus className="w-4 h-4" />
                  </Button>
                </div>
              </div>

              <div>
                {time.map((val, idx) => {
                  return (
                    <div key={`${day}-${val.id}`}>
                      <div className="flex gap-4 my-4">
                        <Input
                          type="time"
                          value={val.opening}
                          onChange={(newValue) => {
                            dispatch({
                              type: 'change',
                              day: day as DayEnum,
                              index: idx,
                              opening: newValue,
                              closing: val.closing,
                            });
                          }}
                          isDisabled={isLoading}
                        />
                        <Input
                          type="time"
                          value={val.closing}
                          onChange={(newValue) => {
                            dispatch({
                              type: 'change',
                              day: day as DayEnum,
                              index: idx,
                              opening: val.opening,
                              closing: newValue,
                            });
                          }}
                          isDisabled={isLoading}
                        />
                        <div>
                          <Button
                            color="danger"
                            onTrigger={() => {
                              dispatch({
                                type: 'delete',
                                day: day as DayEnum,
                                id: val.id,
                              });
                            }}
                            isDisabled={isLoading}
                          >
                            <Trash className="button-icon" />
                          </Button>
                        </div>
                      </div>
                      <div className="text-sm pb-1 w-full text-red-600">{val.error ?? <>&zwnj;</>}</div>
                    </div>
                  );
                })}

                {!time.length && <span>Gesloten</span>}
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-8">
        <Button
          color="primary"
          iconLeft={<Plus className="button-icon" />}
          onTrigger={handleSubmit}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Pas openingsuren aan
        </Button>
      </div>
    </div>
  );
};

export default LocationOpeningHours;
