import { IterableElement } from 'type-fest';
import toast from 'react-hot-toast';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Trash } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Customer, useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { ITableHeader, Table } from '../../../components/table/Table';
import { useDeleteCustomerContactsMutation } from '../../../generated/graphql';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { formatPhoneNumber } from '../../../utils/phonenumber';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';

export type CustomerContact = IterableElement<Customer['contacts']>;

export const CustomerPortalContactsPage = () => {
  const { customer, refreshData } = useCustomerPortalCustomer();
  const { i18n } = useTranslation();
  const [, executeDelete] = useDeleteCustomerContactsMutation();

  const refresh = useCallback(() => {
    refreshData();
  }, [refreshData]);

  const tableHeaders: ITableHeader[] = [
    {
      id: 'name',
      name: i18n('customerPortal.contacts.name'),
    },
    {
      id: 'email',
      name: i18n('customerPortal.contacts.email'),
    },
    {
      id: 'phone',
      name: i18n('customerPortal.contacts.phone'),
    },
    {
      id: 'function',
      name: i18n('customerPortal.contacts.function'),
    },
    {
      id: 'language',
      name: i18n('customerPortal.contacts.language'),
    },
    {
      id: 'notifications',
      name: i18n('customerPortal.contacts.notifications'),
    },
    {
      id: 'delete',
      name: i18n('customerPortal.contacts.delete'),
    },
  ];

  return (
    <div>
      <PageHeader title={i18n('customerPortal.contacts.contacts')} />

      <PageHeading
        leftSide={<Breadcrumb currentItem={i18n('customerPortal.contacts.contacts')} />}
        rightSide={
          <div className="flex">
            <div>
              <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                {i18n('customerPortal.contacts.newContact')}
              </LinkButton>
            </div>
          </div>
        }
      />

      <Table
        idKey="id"
        headers={tableHeaders}
        data={customer.contacts}
        mapData={(data) => {
          const notifications = [];
          if (data.shouldReceiveInvoices) {
            notifications.push(i18n('customerContactNotifications.invoice'));
          }
          if (data.shouldReceiveOrderConfirmations) {
            notifications.push(i18n('customerContactNotifications.orderConfirmation'));
          }
          if (data.shouldReceiveCMR) {
            notifications.push(i18n('customerContactNotifications.cmr'));
          }
          if (data.shouldReceiveQuotations) {
            notifications.push(i18n('customerContactNotifications.quotation'));
          }
          if (data.shouldReceiveDieselSurcharge) {
            notifications.push(i18n('customerContactNotifications.dieselSurcharge'));
          }
          return [
            <Link to={`${data.id}`} className="link-text">
              {data.name}
            </Link>,
            data.email,
            formatPhoneNumber(data.phone, true),
            data.function,
            data.language,
            notifications.join(', '),
            <ConfirmDialog
              triggerColor="danger"
              triggerText={<Trash className="button-icon" />}
              title={i18n('customerPortal.contacts.deleteContact')}
              submitText={i18n('customerPortal.contacts.delete')}
              description={i18n('customerPortal.contacts.deleteContactConfirmation', {
                name: data.name,
              })}
              onSubmit={async () => {
                try {
                  const res = await executeDelete({
                    customerId: customer.id,
                    ids: [data.id],
                  });
                  refresh();
                  if (res.error) {
                    throw res.error;
                  }
                  toast.success(i18n('customerPortal.contacts.deleteContactSuccess'));
                } catch (err) {
                  captureException(err);
                  toast.error(
                    i18n('customerPortal.contacts.deleteContactError', {
                      error: getDisplayError(err),
                    }),
                  );
                }
              }}
            />,
          ];
        }}
      />
    </div>
  );
};
