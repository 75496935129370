import { Plus, Trash } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { FieldArray, Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { InputField } from '../../../components/input/InputField';
import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { Country, useUpdateGeneralSettingsMutation } from '../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../utils/address';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAllGeneralSettings } from '../all-settings-context';
import { captureException } from '@sentry/react';

const generalSettingsSchema = Yup.object().shape({
  companyName: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().required('Vereist'),
  phoneNumber: Yup.string().phone().required('Vereist'),
  generalEmail: Yup.string().email('Ongeldig email').required('Vereist'),
  transportEmail: Yup.string().email('Ongeldig email').required('Vereist'),
  financeEmail: Yup.string().email('Ongeldig email').required('Vereist'),
  invoiceBccEmail: Yup.string().email('Ongeldig email').required('Vereist'),
  vatNumber: Yup.string().vatNumber().required('Vereist'),
  numberPrefix: Yup.string(),
  accountRequestContacts: Yup.array().of(Yup.string().email('Ongeldig email')).required('Vereist'),
});

interface IGeneralSettingsValues {
  companyName: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: ISimpleSelectItem;
  phoneNumber: string;
  generalEmail: string;
  transportEmail: string;
  financeEmail: string;
  invoiceBccEmail: string;
  vatNumber: string;
  numberPrefix: string;
  auditLogRetentionDays: string;
  accountRequestContacts: string[];
}

const GeneralSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [_updateState, updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const initialValues: IGeneralSettingsValues = useMemo(() => {
    return {
      companyName: settings.companyName,
      vatNumber: settings.vatNumber,
      companyNumber: settings.companyNumber,
      street: settings.street,
      streetNumber: settings.streetNumber,
      postalCode: settings.postalCode,
      city: settings.city,
      country: COUNTRY_VALUES.find((v) => v.key === settings.country)!,
      phoneNumber: settings.phoneNumber,
      generalEmail: settings.generalEmail,
      transportEmail: settings.transportEmail,
      financeEmail: settings.financeEmail,
      invoiceBccEmail: settings.invoiceBccEmail,
      cmrEmail: settings.cmrEmail,
      numberPrefix: settings.numberPrefix,
      auditLogRetentionDays: '' + settings.auditLogRetentionDays,
      accountRequestContacts: settings.accountRequestContacts,
    };
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={generalSettingsSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const { country, auditLogRetentionDays, ...otherValues } = patch;
          const result = await updateGeneralSettings({
            data: {
              country: country?.key as Country,
              auditLogRetentionDays: auditLogRetentionDays ? +auditLogRetentionDays : undefined,
              accountRequestContacts: otherValues.accountRequestContacts?.map((v) => v.trim()).filter(Boolean) ?? [],
              ...otherValues,
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Instellingen aangepast');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon algemene instellingen niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Naam" type="text" name="companyName" />
            <InputField labelText="BTW Nummer" type="text" name="vatNumber" />
            <InputField labelText="Ondernemingsnummer" type="text" name="companyNumber" />
            <div className="flex gap-4">
              <div style={{ flex: 4 }}>
                <InputField labelText="Straat" type="text" name="street" />
              </div>
              <div style={{ flex: 1 }}>
                <InputField labelText="Nr" type="text" name="streetNumber" />
              </div>
            </div>
            <div className="flex gap-4">
              <div style={{ flex: 1 }}>
                <InputField labelText="Postcode" type="text" name="postalCode" />
              </div>
              <div style={{ flex: 2 }}>
                <InputField labelText="Gemeente" type="text" name="city" />
              </div>
              <div style={{ flex: 1 }}>
                <SimpleSelectField labelText="Landcode" items={COUNTRY_VALUES} name="country" />
              </div>
            </div>

            <div>
              <InputField labelText="Prefix nummering" type="text" name="numberPrefix" />
            </div>

            <InputField labelText="Audit logs bewaartijd in dagen" type="number" name="auditLogRetentionDays" />

            <InputField labelText="E-mail algemeent" type="email" name="generalEmail" />
            <InputField labelText="E-mail financieel" type="email" name="financeEmail" />
            <InputField labelText="E-mail transport" type="email" name="transportEmail" />
            <InputField labelText="E-mail cmr" type="email" name="cmrEmail" />
            <InputField labelText="BCC E-mail facturatie" type="email" name="invoiceBccEmail" />
            <InputField labelText="Telefoonnummer" type="text" name="phoneNumber" />

            <FieldArray
              name="accountRequestContacts"
              render={(arrayHelpers) => (
                <div className="my-4">
                  <div className="flex justify-between">
                    <div className="heading-three">Contacten voor toegangsverzoek klantenportaal</div>
                    <Button onTrigger={() => arrayHelpers.push('')}>Voeg email toe</Button>
                  </div>
                  {values.accountRequestContacts.map((interval, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <InputField labelText="Contact email" type="email" name={`accountRequestContacts.${index}`} />
                      <div>
                        <Button type="button" color="danger" onTrigger={() => arrayHelpers.remove(index)}>
                          <Trash className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<Plus className="button-icon" />}
            >
              Pas instellingen aan
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default GeneralSettingsPage;
