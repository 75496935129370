import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from '@utils/classnames';
import { Plus } from '@phosphor-icons/react';

import { useCreateInvoiceMutation } from '../../../../../generated/graphql';
import { formatNumber } from '../../../../../utils/number';
import { calculateLinesTotalExclVat, calculateLinesTotalVat } from '../../../../order/utils/price';
import { useCreateInvoiceCtx } from './context';
import { formatInputDate } from '../../../../../utils/date';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { Button } from '../../../../../components/button/Button';
import { InputField } from '../../../../../components/input/InputField';
import { nullthrows } from '../../../../../utils/invariant';
import { captureException } from '@sentry/react';

const createInvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.string().min(1, 'Vereist').required('Vereist'),
  customerRef: Yup.string(),
});

export const InvoiceReview = () => {
  const navigate = useNavigate();
  const [_createResult, createInvoiceMutation] = useCreateInvoiceMutation();
  const { state: invoiceState } = useCreateInvoiceCtx();

  const totalExclVat = invoiceState.orders.reduce(
    (acc, order) => acc + calculateLinesTotalExclVat(order.lines.map((l) => l.sales).flat()),
    0,
  );
  const totalVat = invoiceState.orders.reduce(
    (acc, order) => acc + calculateLinesTotalVat(order.lines.map((l) => l.sales).flat()),
    0,
  );

  const customer = nullthrows(invoiceState.selectedCustomer?.customer, 'No customer selected');
  return (
    <div>
      <Formik
        initialValues={{
          customerRef: '',
          invoiceDate: formatInputDate(new Date()),
        }}
        validationSchema={createInvoiceSchema}
        onSubmit={async (values) => {
          try {
            const result = await createInvoiceMutation({
              data: {
                date: values.invoiceDate,
                customerRef: values.customerRef,
                orderIds: invoiceState.orders.map((o) => o.id),
                customerId: customer.id,
              },
            });
            if (result.error) {
              throw result.error;
            }
            toast.success('Factuur aangemaakt');
            navigate('..');
          } catch (err) {
            captureException(err);
            console.error(err);
            toast.error('Kon factuur niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Factuurdatum" type="date" name="invoiceDate" />
              <InputField labelText="Ref klant" type="text" name="customerRef" />

              <div className="my-4 max-w-xl">
                <div className="heading-two mb-2">Orders</div>

                {invoiceState.orders.map((order) => {
                  const totalExclVat = calculateLinesTotalExclVat(order.lines.map((l) => l.sales).flat());
                  const totalVat = calculateLinesTotalVat(order.lines.map((l) => l.sales).flat());

                  return (
                    <div className="mb-2 grid grid-cols-4 gap-x-4 gap-y-1" key={order.id}>
                      <div className="font-medium text-lg col-span-2">{order.orderNumber ?? order.id}</div>
                      <div className="font-medium text-lg whitespace-nowrap">
                        €{' '}
                        {formatNumber(totalExclVat, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>
                      <div className="whitespace-nowrap">
                        €{' '}
                        {formatNumber(totalVat, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>

                      <>
                        {order.lines.map((line) => {
                          return line.sales.map((sale) => {
                            return (
                              <Fragment key={`${order.id}-${line.id}-${sale.id}`}>
                                <div>{sale.productType.nameNl}</div>
                                <div className="text-right whitespace-nowrap">
                                  {formatNumber(sale.amount, 2, {
                                    decimalSeperator: ',',
                                  })}{' '}
                                  x €{' '}
                                  {formatNumber(sale.unitPrice, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div className="font-medium whitespace-nowrap">
                                  €{' '}
                                  {formatNumber((sale.amount * sale.unitPrice) / 100, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div
                                  className={classNames('whitespace-nowrap', {
                                    'text-feedback-negative-04': sale.vatRate.id !== customer.defaultVatRate.id,
                                  })}
                                >
                                  {`€ ${formatNumber(
                                    (sale.amount * sale.unitPrice * sale.vatRatePercentage) / 1000000,
                                    2,
                                    {
                                      decimalSeperator: ',',
                                    },
                                  )} - ${formatNumber(sale.vatRatePercentage, 2, {
                                    decimalSeperator: ',',
                                  })}%`}
                                </div>

                                {!!sale.externalNote && (
                                  <div className="col-span-4">
                                    <div className="text-sm">Extra informatie: {sale.externalNote}</div>
                                  </div>
                                )}
                              </Fragment>
                            );
                          });
                        })}
                      </>

                      <div className="col-span-3 py-1" />
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-2 w-96">
                <div className="font-medium">Totaal excl btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalExclVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
                <div className="font-medium">Totaal btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
                <div className="font-medium">Totaal incl btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalExclVat + totalVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
              </div>

              <div className="mt-4">
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Maak factuurontwerp aan
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
