import { useParams } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { invariant, nullthrows } from '../../../utils/invariant';
import { useGetCustomerPortalQuotationQuery } from '../../../generated/graphql';
import { formatNumber } from '../../../utils/number';
import { DataField } from '../../../components/DataField';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';
import { Button } from 'components/button/Button';
import { DownloadSimple } from '@phosphor-icons/react';
import { useDownloadDocument } from 'src/app/document/hooks/useDownloadDocument';

export const CustomerPortalQuotationPage = () => {
  const { i18n } = useTranslation();
  const { quotationId } = useParams<{ quotationId: string }>();
  invariant(quotationId);

  const [{ data }] = useGetCustomerPortalQuotationQuery({
    variables: {
      id: quotationId,
    },
  });
  const { downloadFile } = useDownloadDocument();

  const quotation = nullthrows(data?.customerPortalQuotation, i18n('customerPortal.quotes.notFound'));
  const title = i18n('customerPortal.quotes.quotation', {
    reference: quotation.reference,
  });
  const totalAmount = quotation.lines.reduce((acc, line) => acc + line.price, 0);

  return (
    <>
      <PageHeader title={title} />

      <div>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: i18n('customerPortal.quotes.quotes'),
                to: '..',
              }}
              currentItem={title}
            />
          }
          rightSide={
            <Button
              color="primary"
              isDisabled={!quotation.document}
              iconLeft={<DownloadSimple className="button-icon" />}
              onTrigger={() => (quotation.document ? downloadFile(quotation.document) : null)}
            >
              Download
            </Button>
          }
        />

        <div className="grid gap-4 px-4">
          <Card title={i18n('customerPortal.quotes.generalInformation')}>
            <div className="grid grid-cols-3 gap-4">
              <DataField title={i18n('customerPortal.quotes.type')}>{quotation.type}</DataField>
              <DataField title={i18n('customerPortal.quotes.quoteReference')}>{quotation.reference}</DataField>
              <DataField title={i18n('customerPortal.quotes.quoteDate')}>
                {formatDate(quotation.quotationDate)}
              </DataField>
              <DataField title={i18n('customerPortal.quotes.expirationDate')}>
                {formatDate(quotation.expiresAt)}
              </DataField>
              <DataField title={i18n('customerPortal.quotes.total')}>
                <span className="whitespace-nowrap">{`€ ${formatNumber(totalAmount, 2, {
                  decimalSeperator: ',',
                })}`}</span>
              </DataField>
            </div>
          </Card>

          {quotation.notes.length > 0 && (
            <Card title={i18n('customerPortal.quotes.notes')}>
              <span className="whitespace-pre-wrap">{quotation.notes}</span>
            </Card>
          )}

          <Card title={i18n('customerPortal.quotes.lines')}>
            <div className="grid grid-cols-8 font-medium mb-2 border-b border-dark-05 pb-2 label-text">
              <div className="col-span-3">{i18n('customerPortal.quotes.departurePlace')}</div>
              <div className="col-span-3">{i18n('customerPortal.quotes.destination')}</div>
              <div className="whitespace-nowrap">{i18n('customerPortal.quotes.distance')}</div>
              <div className="whitespace-nowrap">{i18n('customerPortal.quotes.price')}</div>
            </div>

            {quotation.lines.map((line) => {
              return (
                <div className="my-1" key={line.id}>
                  <div className="grid grid-cols-8">
                    <div className="col-span-3">{`${line.departureCountry}${line.departurePostalCode} ${line.departureCity}`}</div>
                    <div className="col-span-3">{`${line.arrivalCountry}${line.arrivalPostalCode} ${line.arrivalCity}`}</div>
                    <div className="whitespace-nowrap">
                      {`${formatNumber(line.distance, 2, {
                        decimalSeperator: ',',
                      })} km`}
                    </div>
                    <div className="whitespace-nowrap">
                      {`€ ${formatNumber(line.price, 2, {
                        decimalSeperator: ',',
                      })}`}
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-2 mt-2">
                    {line.trailerTypes.map((trailerType) => {
                      return (
                        <div
                          className="bg-dark-05 rounded flex flex-col items-center justify-center px-2 py-1"
                          key={`${line.id}-${trailerType}`}
                        >
                          <TrailerTypeIcon type={trailerType} variant="small" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            <div className="grid grid-cols-8 font-medium mt-2">
              <div className="col-span-7 flex justify-end pr-4">{i18n('customerPortal.quotes.total')}</div>
              <div className="whitespace-nowrap font-medium text-orange-00">{`${formatNumber(totalAmount, 2, {
                decimalSeperator: ',',
              })} EUR`}</div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
