import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Plus } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { InputField } from '../../../../components/input/InputField';
import { PageHeader } from '../../../../components/PageHeader';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { Country, Language, useCreateLocationMutation } from '../../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../../utils/address';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { AutocompletePostalcode } from '../../components/AutocompletePostalcode';
import { IMinimalLocation } from '../../LocationCombobox';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';
import { captureException } from '@sentry/react';

const createLocationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
});

const CreateLocationPage = () => {
  const navigate = useNavigate();
  const [, createLocationMutation] = useCreateLocationMutation();
  const [params] = useSearchParams();
  const { search } = useLocation();

  return (
    <>
      <PageHeader title="Nieuwe locatie" />

      <PageHeading
        leftSide={
          <Breadcrumb
            parentItem={{
              name: 'Locaties',
              to: '/internal/locations',
            }}
            currentItem="Nieuwe locatie"
          />
        }
      />

      <div className="px-4">
        <Formik
          initialValues={{
            name: '',
            street: '',
            streetNumber: '',
            city: '',
            postalCode: '',
            country: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
            language: LANGUAGE_VALUES.find((v) => v.key === Language.Nl)!,
            internalNotes: '',
            externalNotes: '',
            isExternalBooking: false,
          }}
          validationSchema={createLocationSchema}
          onSubmit={async (values) => {
            try {
              const inputData = {
                ...values,
                country: values.country.key as Country,
                language: values.language.key as Language,
              };
              const result = await createLocationMutation({
                data: inputData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                const popupId = params.get('popup-id');
                if (popupId) {
                  const data: IMinimalLocation = result.data.createLocation;

                  // eslint-disable-next-line no-restricted-globals
                  window.opener?.postMessage(
                    {
                      id: popupId,
                      type: 'created-location',
                      data,
                    },
                    '*',
                  );
                }
                navigate(`../${result.data.createLocation.id}/general${search}`);
              }
              toast.success('Locatie aangemaakt');
            } catch (err: any) {
              captureException(err);
              toast.error('Kon locatie niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Algemene informatie">
                <InputField labelText="Naam" type="text" name="name" />
                <div className="flex gap-4">
                  <div style={{ flex: 4 }}>
                    <InputField labelText="Straat" type="text" name="street" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <InputField labelText="Nr" type="text" name="streetNumber" />
                  </div>
                </div>

                <AutocompletePostalcode />

                <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />

                <CheckboxField labelText="Externe boeking" name="isExternalBooking" />
              </Card>

              <Card title="Notities">
                <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
                <TextAreaField labelText="Externe notities (voor vervoerder)" name="externalNotes" spellCheck={true} />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  iconLeft={<Plus className="button-icon" />}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Maak locatie aan
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateLocationPage;
