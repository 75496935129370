import React from 'react';
import { Link } from 'react-router-dom';

export interface ILinkCardProps {
  title: string;
  to: string;
}

export const LinkCard: React.FC<ILinkCardProps> = (props) => {
  const { title, to } = props;

  return (
    <Link to={to}>
      <div className="card p-4" data-clickable="true">
        <div className="card-heading-text">{title}</div>
      </div>
    </Link>
  );
};

export interface ICardProps {
  title: React.ReactNode;
  rightHeading?: React.ReactNode;
  children: React.ReactNode;
}

export const Card: React.FC<ICardProps> = (props) => {
  const { title, rightHeading, children } = props;

  return (
    <div className="card">
      <div className="card-heading flex justify-between items-center">
        <div>{title}</div>
        <div>{rightHeading}</div>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};
