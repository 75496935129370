import { DownloadSimple } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { BaseButton } from '../../../components/button/BaseButton';
import { Button } from '../../../components/button/Button';
import { useExportInvoicesMutation } from '../../../generated/graphql';
import { Formik } from 'formik';
import { InputField } from '../../../components/input/InputField';
import { getDisplayError } from '../../../utils/get-display-error';
import { downloadTextFile } from '../../../utils/download';
import { parseNumberInput } from '../../../utils/number';
import { captureException } from '@sentry/react';

const exportInvoicesSchema = Yup.object().shape({
  startId: Yup.string().min(1, 'Vereist').required('Vereist'),
  endId: Yup.string().min(1, 'Vereist').required('Vereist'),
  financialPeriod: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const ExportInvoicesButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [exportInvoicesRes, exportInvoices] = useExportInvoicesMutation();

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild disabled={exportInvoicesRes.fetching}>
        <BaseButton
          color="default"
          iconLeft={<DownloadSimple className="button-icon" />}
          isLoading={exportInvoicesRes.fetching}
        >
          Exporteer verkopen
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />

        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Exporteer verkopen</Dialog.Title>

          <div>
            <Formik
              initialValues={{ startId: '', endId: '', financialPeriod: '1' }}
              validationSchema={exportInvoicesSchema}
              onSubmit={async ({ startId, endId, financialPeriod }) => {
                try {
                  const result = await exportInvoices({
                    startId,
                    endId,
                    financialPeriod: parseNumberInput(financialPeriod),
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  if (!result.data) {
                    throw new Error('No data returned');
                  }

                  const customers = result.data.exportInvoices.customers;
                  downloadTextFile(customers, `customers-${startId}-${endId}.xml`, 'text/xml');

                  const invoices = result.data.exportInvoices.invoices;
                  downloadTextFile(invoices, `invoices-${startId}-${endId}.xml`, 'text/xml');

                  toast.error('Factuur export download gestart');
                } catch (err) {
                  captureException(err);
                  console.error(err);
                  toast.error('Kon verkopen niet exporteren: ' + getDisplayError(err));
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <InputField labelText="Start nummer" type="text" name="startId" />
                    <InputField labelText="Eind nummer" type="text" name="endId" />
                    <InputField labelText="Financiele Periode" type="number" name="financialPeriod" />

                    <div className="mt-8">
                      <Button
                        type="submit"
                        color="primary"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                        iconLeft={<DownloadSimple className="button-icon" />}
                      >
                        Exporteer verkopen
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
