import { useParams } from 'react-router-dom';
import { ArrowCircleDown, ArrowCircleUp, CalendarDots, Clock, DownloadSimple, Hash } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import {
  OrderDocumentType,
  OrderLineStopType,
  OrderStatus,
  useCustomerPortalCmrReminderMutation,
  useGetCustomerPortalOrderQuery,
} from '../../../generated/graphql';
import { nullthrows } from '../../../utils/invariant';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { OrderStatusTag } from '../../order/components/OrderStatusTag';
import { DataField } from '../../../components/DataField';
import { formatDate, formatDateTime, formatInputTime, sortDate } from '../../../utils/date';
import { useTranslation } from '../../../contexts/translation-context';
import { formatNumber } from '../../../utils/number';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { OrderComplaintDialog } from './CustomerPortalOrderComplaintDialog';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { ConfirmDialog } from 'components/dialog/ConfirmDialog';
import { getDisplayError } from '@utils/get-display-error';
import { LinkButton } from 'components/button/ButtonLink';
import { Card } from 'components/Card';
import { PageHeading } from 'components/PageHeading';
import { RatingDatafield } from 'src/app/order/components/RatingDatafield';
import { captureException } from '@sentry/react';
import { Button } from 'components/button/Button';
import { useDownloadDocument } from 'src/app/document/hooks/useDownloadDocument';

export const CustomerPortalOrderPage = () => {
  const { i18n } = useTranslation();
  const { orderId: _orderId } = useParams<{ orderId: string }>();
  const orderId = nullthrows(_orderId, 'Order ID is required');
  const [{ data }] = useGetCustomerPortalOrderQuery({
    variables: {
      id: orderId,
    },
  });
  const [, sendCmrReminder] = useCustomerPortalCmrReminderMutation();
  const { downloadFile } = useDownloadDocument();

  const cmrDocument = data?.customerPortalOrder?.documents.find((v) => v.type === OrderDocumentType.Cmr)?.document;
  const sustainabilityReportDocument = data?.customerPortalOrder?.documents.find(
    (v) => v.type === OrderDocumentType.SustainabilityReport,
  )?.document;
  const order = nullthrows(data?.customerPortalOrder, 'Order not found');
  const executionDate = order.lines
    .map((l) => {
      return l.stops.filter((s) => s.type === OrderLineStopType.Load).sort((a, b) => sortDate(a.date, b.date))[0]?.date;
    })
    .sort(sortDate)[0];
  const distance = Math.round(order.lines.reduce((acc, curr) => curr.distance + acc, 0) / 1000);
  const emissions = order.lines.reduce((acc, curr) => curr.emissions + acc, 0);

  const canSendCmrReminder =
    order.status === OrderStatus.Executed && !order.documents.find((v) => v.type === OrderDocumentType.Cmr);

  const totalWeight = order.lines.reduce(
    (acc, curr) => curr.contents.reduce((acc, curr) => acc + curr.weight, 0) + acc,
    0,
  );
  return (
    <>
      <PageHeader title={order.orderNumber ?? 'DRAFT'} />

      <PageHeading
        leftSide={
          <div className="flex items-center gap-4">
            <Breadcrumb
              parentItem={{
                name: i18n('customerPortal.orders.orders'),
                to: '..',
              }}
              currentItem={order.orderNumber ?? 'DRAFT'}
            />

            <div>
              <OrderStatusTag status={order.status} />
            </div>
          </div>
        }
        rightSide={
          <div className="flex gap-2">
            <LinkButton color="default-outline" to={`../new?copy-from=${orderId}`}>
              {i18n('customerPortal.order.duplicate')}
            </LinkButton>

            <OrderComplaintDialog
              triggerColor="default-outline"
              orderId={order.id}
              isDisabled={order.status === OrderStatus.Draft || order.status === OrderStatus.Cancelled}
            />

            {canSendCmrReminder && (
              <ConfirmDialog
                triggerText={i18n('customerPortal.cmrReminder.cmrReminder')}
                triggerColor="primary"
                title={i18n('customerPortal.cmrReminder.cmrReminder')}
                submitText={i18n('customerPortal.cmrReminder.sendReminder')}
                description={i18n('customerPortal.cmrReminder.confirmText')}
                onSubmit={async () => {
                  try {
                    const res = await sendCmrReminder({
                      orderId: order.id,
                    });
                    if (res.error) {
                      throw res.error;
                    }
                    toast.success(i18n('customerPortal.cmrReminder.confirmation'));
                  } catch (err: any) {
                    captureException(err);
                    toast.error(
                      i18n('customerPortal.cmrReminder.error', {
                        error: getDisplayError(err),
                      }),
                    );
                    throw err;
                  }
                }}
              />
            )}

            <Button
              color="primary"
              isDisabled={!cmrDocument}
              iconLeft={<DownloadSimple className="button-icon" />}
              onTrigger={() => (cmrDocument ? downloadFile(cmrDocument) : null)}
            >
              CMR
            </Button>
          </div>
        }
      />

      <div className="grid xl:grid-cols-2 gap-4 px-4">
        <Card title={i18n('customerPortal.order.overview')}>
          <div className="grid grid-cols-2 gap-4">
            <DataField title={i18n('customerPortal.orders.invoiceRef')}>
              {order.customerRef || order.orderNumber || '-'}
            </DataField>
            <DataField title={i18n('customerPortal.orders.creationDate')}>{formatDate(order.createdAt)}</DataField>
            {executionDate && (
              <DataField title={i18n('customerPortal.orders.executionDate')}>{formatDate(executionDate)}</DataField>
            )}
            {order.status === OrderStatus.Executed && <RatingDatafield order={order} />}
            {order.creator && (
              <DataField title={i18n('customerPortal.orders.fileManager')}>{order.creator.name}</DataField>
            )}
            {order.cancellationReason && (
              <DataField title={i18n('customerPortal.orders.cancellationReason')}>{order.cancellationReason}</DataField>
            )}
          </div>

          <div className="flex flex-wrap gap-4 mt-4">
            {order.allowedTrailerTypes.map((trailerType) => {
              return (
                <div
                  className="bg-offwhite rounded flex flex-col items-center justify-center p-2 px-4"
                  key={`${order.id}-${trailerType}`}
                >
                  <TrailerTypeIcon type={trailerType} variant="regular" />
                </div>
              );
            })}
          </div>
        </Card>

        <div className="grid gap-4">
          {order.lines.map((l) => {
            const saleTotal = calculateLinesTotalExclVat(l.sales);

            return (
              <div key={l.id} className="grid gap-8">
                <Card title={i18n('customerPortal.orders.stops')}>
                  <div className="grid grid-cols-2 gap-4">
                    {l.stops.map((stop) => {
                      return (
                        <div key={stop.id}>
                          <div className="flex items-center gap-2 mb-2">
                            {stop.type === OrderLineStopType.Load ? (
                              <ArrowCircleUp className="w-6 h-6 text-orange-00" />
                            ) : (
                              <ArrowCircleDown className="w-6 h-6 text-orange-00" />
                            )}

                            <div className="text-dark-03 font-medium">
                              {stop.type === OrderLineStopType.Load
                                ? i18n('pages.orderSummary.load')
                                : i18n('pages.orderSummary.unload')}
                            </div>
                          </div>
                          <div className="mb-1">
                            <div>{stop.location.name}</div>
                            <div className="whitespace-nowrap">{`${stop.location.street} ${stop.location.streetNumber}`}</div>
                            <div>{`${stop.location.country}-${stop.location.postalCode}`}</div>
                          </div>
                          <div className="flex items-center">
                            <Hash className="w-4 h-4 mr-2" />
                            <div>{stop.reference || '-'}</div>
                          </div>
                          <div className="flex items-center">
                            <CalendarDots className="w-4 h-4 mr-2" />
                            <div>{formatDate(stop.date)}</div>
                          </div>
                          <div className="flex items-center">
                            <Clock className="w-4 h-4 mr-2" />
                            <div className="whitespace-nowrap">{`${formatInputTime(stop.timeStart)} - ${formatInputTime(
                              stop.timeEnd,
                            )}`}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Card>

                {!!l.sales.length && (
                  <Card title={i18n('customerPortal.orders.purchases')}>
                    {l.sales.map((sale) => {
                      return (
                        <div key={sale.id} className="grid grid-cols-4">
                          <div className="col-span-3 whitespace-nowrap">
                            {`${formatNumber(sale.amount, 2)} x ${sale.productType.nameNl}`}
                          </div>
                          <div className="whitespace-nowrap">
                            {`${formatNumber((sale.amount * sale.unitPrice) / 100, 2, {
                              decimalSeperator: ',',
                            })} EUR`}
                          </div>
                        </div>
                      );
                    })}

                    <div className="grid grid-cols-4 mb-2 border-t border-dark-06 pt-2 mt-2">
                      <div className="col-span-3 whitespace-nowrap font-medium">
                        {i18n('customerPortal.orders.total')}
                      </div>
                      <div className="whitespace-nowrap font-medium text-orange-00">
                        {`${formatNumber(saleTotal, 2, {
                          decimalSeperator: ',',
                        })} EUR`}
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            );
          })}
        </div>

        {emissions > 0 && (
          <Card
            title={i18n('customerPortal.orders.sustainability')}
            rightHeading={
              <div className="flex justify-between items-center mb-2">
                <Button
                  color="primary"
                  isDisabled={!sustainabilityReportDocument}
                  iconLeft={<DownloadSimple className="button-icon" />}
                  onTrigger={() => (sustainabilityReportDocument ? downloadFile(sustainabilityReportDocument) : null)}
                >
                  Report
                </Button>
              </div>
            }
          >
            <div className="grid grid-cols-2 gap-4">
              <DataField title={i18n('customerPortal.orders.truck')}>Euro 6</DataField>
              <DataField title={i18n('customerPortal.orders.distance')}>{`${distance}km`}</DataField>
              <DataField title={i18n('customerPortal.orders.weight')}>{`${totalWeight / 100}kg`}</DataField>
              <DataField title={i18n('customerPortal.orders.emissions')}>{`${formatNumber(emissions, 3, {
                decimalSeperator: ',',
              })}kg co2eq`}</DataField>
            </div>
          </Card>
        )}

        {order.complaints.length > 0 && (
          <Card title={i18n('customerPortal.orders.complaints')}>
            <div className="grid gap-2">
              {order.complaints.map((v) => {
                return (
                  <div key={v.id}>
                    <div>{v.content}</div>

                    <div className="flex justify-between items-center mt-2">
                      <div className="text-sm text-dark-02">{formatDateTime(v.createdAt)}</div>
                      <div className="px-2 py-1 rounded-md inline-flex items-center bg-dark-04 text-xs">
                        {i18n(`orderComplaintType.${v.type}`)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};
