import { CalendarDots, Clock, DownloadSimple } from '@phosphor-icons/react';
import { PageHeader } from 'components/PageHeader';
import {
  GetCustomerPortalCreditNoteQuery,
  OrderLineStopType,
  useGetCustomerPortalCreditNoteQuery,
} from 'generated/graphql';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';

import { Breadcrumb } from 'components/Breadcrumb';
import { DataField } from 'components/DataField';
import { formatDate, formatInputTime } from '../../../utils/date';
import { calculateInvoiceTotals } from '../../sale/utils/calculate';
import { formatNumber } from '../../../utils/number';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { Card } from 'components/Card';
import { useDownloadDocument } from 'src/app/document/hooks/useDownloadDocument';
import { Button } from 'components/button/Button';

export type Creditnote = NonNullable<GetCustomerPortalCreditNoteQuery['customerPortalCreditNote']>;

export const CustomerPortalCreditNotePage = () => {
  const { creditNoteId } = useParams<{ creditNoteId: string }>();
  invariant(creditNoteId);
  const { i18n } = useTranslation();
  const [{ data }] = useGetCustomerPortalCreditNoteQuery({
    variables: {
      id: creditNoteId,
    },
  });
  const { downloadFile } = useDownloadDocument();

  const creditNote = nullthrows(data?.customerPortalCreditNote, i18n('customerPortal.creditNotes.notFound'));
  const totals = calculateInvoiceTotals([
    ...creditNote.creditNoteOrders
      .map((o) =>
        o.lines
          .map((l) => {
            return {
              ...l,
              vatRateId: l.vatRate.id,
            };
          })
          .flat(),
      )
      .flat(),
    ...creditNote.manualLines.map((v) => {
      return {
        ...v,
        vatRateId: v.vatRate.id,
      };
    }),
  ]);

  const title = i18n('customerPortal.creditNotes.creditNote', {
    creditNoteNumber: creditNote.creditNoteNumber ?? 'DRAFT',
  });
  return (
    <>
      <PageHeader title={title} />

      <div>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: i18n('customerPortal.creditNotes.creditNotes'),
                to: '..',
              }}
              currentItem={title}
            />
          }
          rightSide={
            <Button
              color="primary"
              isDisabled={!creditNote.document}
              iconLeft={<DownloadSimple className="button-icon" />}
              onTrigger={() => (creditNote.document ? downloadFile(creditNote.document) : null)}
            >
              Download
            </Button>
          }
        />

        <div className="grid gap-4 px-4">
          <Card title={i18n('customerPortal.creditNotes.generalInformation')}>
            <div className="grid grid-cols-3 gap-4">
              <DataField title={i18n('customerPortal.creditNotes.structuredComment')}>
                {creditNote.structuredComment}
              </DataField>
              <DataField title={i18n('customerPortal.creditNotes.creditNoteDate')}>{formatDate(creditNote.date)}</DataField>
              {creditNote.sentAt && (
                <DataField title={i18n('customerPortal.creditNotes.sentAt')}>{formatDate(creditNote.sentAt)}</DataField>
              )}
              {creditNote.sentAt && creditNote.expiresAt && (
                <DataField title={i18n('customerPortal.creditNotes.dueDate')}>
                  {formatDate(creditNote.expiresAt)}
                </DataField>
              )}
            </div>
          </Card>

          {creditNote.creditNoteOrders.length > 0 && (
            <>
              <div className="heading-two">Orders</div>

              {creditNote.creditNoteOrders.map((creditNoteOrder) => {
                const order = creditNoteOrder.order;

                const orderTotals = calculateInvoiceTotals(
                  creditNoteOrder.lines
                    .map((l) => {
                      return {
                        ...l,
                        vatRateId: l.vatRate.id,
                      };
                    })
                    .flat(),
                );

                return (
                  <div className="card p-4" key={`order-${order.orderNumber}`}>
                    <div className="flex justify-between">
                      <Link to={`../../orders/${order.id}`} className="heading-three hover:text-orange-00 mb-2">
                        Order {order.orderNumber} - {order.customerRef}
                      </Link>
                    </div>

                    <div>
                      {order.lines.map((line) => {
                        return (
                          <div key={`line-${line.id}`}>
                            <div>
                              {line.stops
                                .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                                .map((stop) => {
                                  return (
                                    <div className="grid grid-cols-4" key={`line-${line.id}-${stop.id}`}>
                                      <div className="font-medium">
                                        {stop.type === OrderLineStopType.Load
                                          ? i18n('customerPortal.creditNotes.load')
                                          : i18n('customerPortal.creditNotes.unload')}
                                      </div>

                                      <div className="font-light">
                                        {stop.location.country}-{stop.location.name}
                                      </div>

                                      <div className="flex gap-2 items-center font-light">
                                        <CalendarDots className="w-4 h-4" />
                                        {formatDate(stop.date)}
                                      </div>
                                      <div className="flex gap-2 items-center font-light">
                                        <Clock className="w-4 h-4" />
                                        {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}

                      <div className="border-t border-b my-4 py-4 border-dark-05">
                        <div className="grid grid-cols-5 gap-x-4 mb-1">
                          <div className="label-text">{i18n('customerPortal.creditNotes.description')}</div>
                          <div className="label-text">{i18n('customerPortal.creditNotes.amount')}</div>
                          <div className="label-text">{i18n('customerPortal.creditNotes.unitPrice')}</div>
                          <div className="label-text">{i18n('customerPortal.creditNotes.total')}</div>
                          <div className="label-text">{i18n('customerPortal.creditNotes.vat')}</div>
                        </div>

                        <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                          {creditNoteOrder.lines.map((line) => {
                            return (
                              <React.Fragment key={`creditnote-line-${line.id}`}>
                                <div>{line.productType.nameNl}</div>
                                <div className="whitespace-nowrap">
                                  {formatNumber(line.amount, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div className="whitespace-nowrap">
                                  €{' '}
                                  {formatNumber(line.unitPrice, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div className="whitespace-nowrap">
                                  €{' '}
                                  {formatNumber((line.amount * line.unitPrice) / 100, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div className="whitespace-nowrap">
                                  €{' '}
                                  {formatNumber((line.amount * line.unitPrice * line.vatRatePercentage) / 1000000, 2, {
                                    decimalSeperator: ',',
                                  })}{' '}
                                  -{' '}
                                  {formatNumber(line.vatRatePercentage, 2, {
                                    decimalSeperator: ',',
                                  })}
                                  %
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>

                      <div className="font-medium uppercase whitespace-nowrap">
                        {i18n('customerPortal.creditNotes.orderTotal', {
                          total: formatNumber(orderTotals.totalExclVat, 2, {
                            decimalSeperator: ',',
                          }),
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {creditNote.manualLines.length > 0 && (
            <div className="border-t border-b my-4 py-4 border-dark-05">
              <div className="grid grid-cols-5 gap-x-4 mb-1">
                <div className="label-text">{i18n('customerPortal.creditNotes.description')}</div>
                <div className="label-text">{i18n('customerPortal.creditNotes.amount')}</div>
                <div className="label-text">{i18n('customerPortal.creditNotes.unitPrice')}</div>
                <div className="label-text">{i18n('customerPortal.creditNotes.total')}</div>
                <div className="label-text">{i18n('customerPortal.creditNotes.vat')}</div>
              </div>

              <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                {creditNote.manualLines.map((line) => {
                  return (
                    <React.Fragment key={`creditnote-line-${line.id}`}>
                      <div>{line.description}</div>
                      <div className="whitespace-nowrap">
                        {formatNumber(line.amount, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>
                      <div className="whitespace-nowrap">
                        €{' '}
                        {formatNumber(line.unitPrice, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>
                      <div className="whitespace-nowrap">
                        €{' '}
                        {formatNumber((line.amount * line.unitPrice) / 100, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>
                      <div className="whitespace-nowrap">
                        €{' '}
                        {formatNumber((line.amount * line.unitPrice * line.vatRatePercentage) / 1000000, 2, {
                          decimalSeperator: ',',
                        })}{' '}
                        -{' '}
                        {formatNumber(line.vatRatePercentage, 2, {
                          decimalSeperator: ',',
                        })}
                        %
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}

          <div className="grid grid-cols-2">
            <div></div>
            <div className="grid grid-cols-2 text-md gap-x-4">
              <div className="label-text">{i18n('customerPortal.creditNotes.totalExclVat')}</div>
              <div className="whitespace-nowrap">
                €{' '}
                {formatNumber(totals.totalExclVat, 2, {
                  decimalSeperator: ',',
                })}
              </div>
              <div className="label-text">{i18n('customerPortal.creditNotes.totalVat')}</div>
              <div className="whitespace-nowrap">
                €{' '}
                {formatNumber(totals.totalVat, 2, {
                  decimalSeperator: ',',
                })}
              </div>
              <div className="label-text">{i18n('customerPortal.creditNotes.totalInclVat')}</div>
              <div className="whitespace-nowrap">
                €{' '}
                {formatNumber(totals.totalInclVat, 2, {
                  decimalSeperator: ',',
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
