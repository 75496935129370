import { Navigate, Outlet, useParams } from 'react-router-dom';

import { AuthConsumer, AuthProvider } from '../../contexts/auth-context';
import { WebsocketProvider } from '../../contexts/websocket-context';
import { NotificationsProvider } from '../notification/pages/context';
import { CustomerPortalCustomerProvider } from './CustomerPortalCustomerContext';
import { CustomerPortalDashboard } from './CustomerPortalDashboard';

export const CustomerPortalWrapper = () => {
  const { customerId: _customerId } = useParams<{ customerId: string }>();
  const parsedCustomerId = +_customerId!;
  if (isNaN(parsedCustomerId)) {
    return <Navigate to="/customer-portal" />;
  }

  return (
    <AuthProvider fallbackLogin="/customer-portal/login">
      <AuthConsumer>
        {(auth) => {
          if (!auth?.me) {
            return <Navigate to="/customer-portal/login" />;
          }

          if (auth.me.isInternal) {
            return <Navigate to="/internal" />;
          }

          if (auth.me.customers.some((v) => v.customer.id === parsedCustomerId)) {
            return null;
          } else {
            return <Navigate to="/" />;
          }
        }}
      </AuthConsumer>

      <WebsocketProvider>
        <NotificationsProvider>
          <CustomerPortalCustomerProvider customerId={parsedCustomerId}>
            <CustomerPortalDashboard>
              <Outlet />
            </CustomerPortalDashboard>
          </CustomerPortalCustomerProvider>
        </NotificationsProvider>
      </WebsocketProvider>
    </AuthProvider>
  );
};
