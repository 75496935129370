import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { FormikProvider, useFormik } from 'formik';
import { useCreateOrderLinePurchaseMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { nullthrows } from '@utils/invariant';
import * as Yup from 'yup';
import { useMemo } from 'react';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { parseNumberInput } from '../../../../utils/number';
import { ProductTypeComboboxField, productTypeToComboboxItem } from '../../productType/ProductTypeComboboxField';
import { useOrderLine } from '../orderLine/orderLineContext';
import { MinimalGeneralSettings, useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { IMinimalVatRate, VatRateComboboxField, vatRateToComboboxItem } from '../../vatRate/VatRateComboboxField';
import { useOrder } from '../order/orderContext';
import { captureException } from '@sentry/react';

export const createOrderPurchaseSchema = Yup.object().shape({
  productType: Yup.mixed().nullable().required('Vereist'),
  vatRate: Yup.mixed().nullable().required('Vereist'),
  amount: Yup.number().required('Vereist'),
  unitPrice: Yup.number().required('Vereist'),
  externalNote: Yup.string().nullable(),
});

export interface IOrderPurchaseValues {
  productType: ISimpleComboboxItem | null;
  vatRate: ISimpleComboboxItem | null;
  amount: string;
  unitPrice: string;
  externalNote: string;
}

export const getInitialValues = (
  settings: MinimalGeneralSettings,
  purchaseCount: number,
  supplier?: { defaultVatRate?: IMinimalVatRate | null } | null,
): IOrderPurchaseValues => {
  const defaultVatRate = supplier?.defaultVatRate ?? settings.defaultVatRate;
  const defaultProductType =
    purchaseCount > 0
      ? purchaseCount > 1
        ? settings.thirdDefaultProductType
        : settings.secondDefaultProductType
      : settings.defaultProductType;
  return {
    productType: defaultProductType ? productTypeToComboboxItem(defaultProductType) : null,
    vatRate: defaultVatRate ? vatRateToComboboxItem(defaultVatRate) : null,
    amount: '1',
    unitPrice: '0',
    externalNote: '',
  };
};

export const CreateOrderPurchasePage = () => {
  const { orderLine, refreshData: refetchOrderLine } = useOrderLine();
  const navigate = useNavigate();
  const { order } = useOrder();
  const { settings } = useMinimalGeneralSettings();
  const [, createOrderPurchaseMutation] = useCreateOrderLinePurchaseMutation();

  const purchases = orderLine.purchases;
  const purchaseCount = purchases.length;
  const initialValues: IOrderPurchaseValues = useMemo(
    () => getInitialValues(settings, purchaseCount, order.supplier),
    [],
  );

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrderPurchaseSchema,
    onSubmit: async (values) => {
      try {
        const { productType, vatRate, amount, unitPrice, externalNote, ...otherValues } = values;

        const productTypeId = nullthrows(productType?.key, 'Product type niet geselecteerd');
        const vatRateId = nullthrows(vatRate?.key, 'Btw type niet geselecteerd');
        const result = await createOrderPurchaseMutation({
          orderLineId: orderLine.id,
          data: {
            productTypeId,
            vatRateId,
            amount: parseNumberInput(amount, 2),
            unitPrice: parseNumberInput(unitPrice, 2),
            externalNote: externalNote ?? '',
            ...otherValues,
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          refetchOrderLine();
          navigate('..');
        }
        toast.success('Aankoop aangemaakt');
      } catch (err: any) {
        captureException(err);
        toast.error('Kon aankoop niet aanmaken: ' + getDisplayError(err));
      }
    },
  });
  const { handleSubmit, isSubmitting, values } = formikbag;

  return (
    <>
      <h1 className="heading-one mb-4">Voeg aankoop toe</h1>

      <FormikProvider value={formikbag}>
        <form onSubmit={handleSubmit}>
          <ProductTypeComboboxField labelText="Product" name="productType" />

          <div className="flex gap-2">
            <div style={{ flex: 2 }}>
              <InputField labelText="Aantal" type="number" name="amount" step="0.01" />
            </div>
            <div style={{ flex: 2 }}>
              <InputField labelText="Eenheidsprijs" type="number" name="unitPrice" step="0.01" />
            </div>
            <div style={{ flex: 3 }}>
              <VatRateComboboxField labelText="Btw Tarief" name="vatRate" />
            </div>
          </div>

          <InputField labelText="Extra info" type="text" name="externalNote" spellCheck={true} />

          <Button
            type="submit"
            color="primary"
            iconLeft={<Plus className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Voeg aankoop toe
          </Button>
        </form>
      </FormikProvider>
    </>
  );
};
