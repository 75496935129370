import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Card } from 'components/Card';
import { useTrailer } from './trailerContext';
import { useUpdateTrailerMutation } from 'generated/graphql';
import { getDisplayError } from '@utils/get-display-error';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { InternalUser, UserComboboxField } from 'src/app/user/components/UserCombobox';
import { captureException } from '@sentry/react';

const updateTrailerSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  licensePlate: Yup.string().min(1, 'Vereist').required('Vereist'),
  brand: Yup.string().min(1, 'Vereist').required('Vereist'),
  serialNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  registrationDate: Yup.string().required('Vereist'),
  insuranceExpirationDate: Yup.string().required('Vereist'),
  inspectionExpirationDate: Yup.string().required('Vereist'),
});

export const UpdateTrailerForm = () => {
  const { trailer } = useTrailer();
  const [, updateTrailerMutate] = useUpdateTrailerMutation();

  const initialValues = useMemo(() => {
    return {
      name: trailer.name,
      licensePlate: trailer.licensePlate,
      brand: trailer.brand,
      serialNumber: trailer.serialNumber,
      registrationDate: trailer.registrationDate,
      insuranceExpirationDate: trailer.insuranceExpirationDate,
      inspectionExpirationDate: trailer.inspectionExpirationDate,
      responsible: trailer.responsible as InternalUser,
    };
  }, [trailer]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={updateTrailerSchema}
        onSubmit={async (newValues) => {
          try {
            const { responsible, ...patch }: Partial<typeof newValues> = diff(initialValues, newValues);
            const updateData = {
              responsibleId: responsible?.id,
              ...patch,
            };

            if (Object.values(updateData).length) {
              const result = await updateTrailerMutate({
                id: trailer.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
            }
            toast.success('Trailer aangepast');
          } catch (err: any) {
            captureException(err);
            toast.error('Kon trailer niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Identificatie">
                <UserComboboxField labelText="Verantwoordelijke" name="responsible" />
                <InputField labelText="ID Code" type="text" name="name" />
                <InputField labelText="Nummerplaat" type="text" name="licensePlate" />
                <InputField labelText="Merk" type="text" name="brand" />
                <InputField labelText="Serienummer" type="text" name="serialNumber" />
                <InputField labelText="Datum eerste registratie" type="date" name="registrationDate" />
              </Card>

              <Card title="Vervaldagen">
                <InputField labelText="Vervaldag verzekering" type="date" name="insuranceExpirationDate" />
                <InputField labelText="Vervaldag keuring" type="date" name="inspectionExpirationDate" />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={!!isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Bewaar aanpassingen
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
