import React from 'react';
import { Link } from 'react-router-dom';

import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from '../../components/Breadcrumb';
import { PageHeader } from '../../components/PageHeader';
import { useTranslation } from '../../contexts/translation-context';
import { useCustomerPortalCustomer } from './CustomerPortalCustomerContext';
import { useAuth } from 'contexts/auth-context';
import { Money, Truck, MapPin, Receipt, Leaf as LeafIcon } from '@phosphor-icons/react';

export interface IHomeCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  to: string;
}

const HomeCard: React.FC<IHomeCardProps> = (props) => {
  const { icon, title, description, to } = props;

  return (
    <Link to={to} className="card p-16">
      <div className="text-dark-03 mb-2 flex justify-center">{icon}</div>
      <div className="font-medium text-dark-02 text-center border-b border-dark-05 pb-4">{title}</div>
      <div className="pt-4 text-center whitespace-normal">{description}</div>
    </Link>
  );
};

export const CustomerPortalHomePage = () => {
  const { me } = useAuth();
  const { customer } = useCustomerPortalCustomer();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader
        title={i18n('customerPortal.home.title', {
          customerName: customer.name,
        })}
      />

      <PageHeading leftSide={<Breadcrumb currentItem={i18n('customerPortal.home.customerPortal')} />} />

      <div className="px-8 mb-8">
        <h1 className="flex gap-1 heading-one mb-4">
          <span>{i18n('customerPortal.home.hello')}</span>
          <span className="text-orange-00">{`${me.name}!`}</span>
        </h1>

        <p>{i18n('customerPortal.home.welcome')}</p>
      </div>

      <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4 px-8">
        <HomeCard
          to={`/customer-portal/${customer.id}/orders`}
          title={i18n('customerPortal.orders.orders')}
          icon={<Truck className="w-8 h-8" />}
          description={i18n('customerPortal.home.manageOrders')}
        />
        <HomeCard
          to={`/customer-portal/${customer.id}/invoices`}
          title={i18n('customerPortal.invoices.invoices')}
          icon={<Money className="w-8 h-8" />}
          description={i18n('customerPortal.home.viewInvoices')}
        />
        <HomeCard
          to={`/customer-portal/${customer.id}/creditnotes`}
          title={i18n('customerPortal.creditNotes.creditNotes')}
          icon={<Money className="w-8 h-8" />}
          description={i18n('customerPortal.home.viewInvoices')}
        />
        <HomeCard
          to={`/customer-portal/${customer.id}/quotations`}
          title={i18n('customerPortal.quotes.quotes')}
          icon={<Receipt className="w-8 h-8" />}
          description={i18n('customerPortal.home.createQuotes')}
        />
        <HomeCard
          to={`/customer-portal/${customer.id}/locations`}
          title={i18n('customerPortal.locations.locations')}
          icon={<MapPin className="w-8 h-8" />}
          description={i18n('customerPortal.home.viewLocations')}
        />
        <HomeCard
          to={`/customer-portal/${customer.id}/sustainability`}
          title={i18n('customerPortal.sustainability.sustainability')}
          icon={<LeafIcon className="w-8 h-8" />}
          description={i18n('customerPortal.sustainability.viewOverview')}
        />
      </div>
    </div>
  );
};
