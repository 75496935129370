import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { Formik } from 'formik';
import { useCreateOrderLineContentMutation } from 'generated/graphql';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { MinimalGeneralSettings, useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { getDisplayError } from '../../../../utils/get-display-error';
import { parseNumberInput } from '../../../../utils/number';
import {
  CargoTypeComboboxField,
  cargoTypeToComboboxItem,
  IMinimalCargoType,
} from '../../cargoType/CargoTypeComboboxField';
import { PackageTypeComboboxField, packageTypeToComboboxItem } from '../../packageType/PackageTypeComboboxField';
import { useOrder } from '../order/orderContext';
import { useOrderLine } from '../orderLine/orderLineContext';
import { captureException } from '@sentry/react';

export const createOrderLineContentSchema = Yup.object().shape({
  cargoType: Yup.mixed().nullable().required('Vereist'),
  packages: Yup.number().required('Vereist'),
  packageType: Yup.mixed().nullable().required('Vereist'),
  weight: Yup.number().required('Vereist'),
  volume: Yup.number().required('Vereist'),
  loadingMeters: Yup.number().required('Vereist'),
});

export interface IOrderLineContentValues {
  cargoType: ISimpleComboboxItem | null;
  packages: string;
  packageType: ISimpleComboboxItem | null;
  weight: string;
  volume: string;
  loadingMeters: string;
}

export const getInitialValues = (
  settings: MinimalGeneralSettings,
  customer?: { defaultCargoType?: IMinimalCargoType | null } | null,
): IOrderLineContentValues => {
  const defaultCargoType = customer?.defaultCargoType ?? settings.defaultCargoType;
  return {
    cargoType: defaultCargoType ? cargoTypeToComboboxItem(defaultCargoType) : null,
    packages: '33',
    packageType: settings.defaultPackageType ? packageTypeToComboboxItem(settings.defaultPackageType) : null,
    weight: '24000',
    volume: '90',
    loadingMeters: '13.60',
  };
};

const CreateOrderLineContentPage = () => {
  const { order } = useOrder();
  const { orderLine, refreshData: refetchOrder } = useOrderLine();
  const navigate = useNavigate();
  const { settings } = useMinimalGeneralSettings();
  const [, createOrderLineContentMutation] = useCreateOrderLineContentMutation();

  const initialValues: IOrderLineContentValues = useMemo(() => {
    return getInitialValues(settings, order?.customer);
  }, [settings]);

  return (
    <>
      <h1 className="heading-one mb-4">Voeg inhoud toe</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={createOrderLineContentSchema}
        onSubmit={async (values) => {
          try {
            const { cargoType, packageType, packages, weight, volume, loadingMeters, ...otherValues } = values;
            const cargoTypeId = cargoType?.key;
            const packageTypeId = packageType?.key;
            invariant(cargoTypeId);
            invariant(packageTypeId);
            const result = await createOrderLineContentMutation({
              orderLineId: orderLine.id,
              data: {
                cargoTypeId,
                packageTypeId,
                packages: parseNumberInput(packages, 2),
                weight: parseNumberInput(weight, 2),
                volume: parseNumberInput(volume, 2),
                loadingMeters: parseNumberInput(loadingMeters, 2),
                ...otherValues,
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchOrder();
              navigate('..');
            }
            toast.success('Inhoud aangemaakt');
          } catch (err: any) {
            captureException(err);
            toast.error('Kon inhoud niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CargoTypeComboboxField labelText="Inhoud" name="cargoType" />
              <div className="flex gap-2">
                <InputField labelText="Coli" type="number" name="packages" step="0.01" />
                <PackageTypeComboboxField labelText="Verpakking" name="packageType" />
              </div>
              <div className="flex gap-2">
                <InputField labelText="Gewicht (kg)" type="number" name="weight" step="0.01" />
                <InputField labelText="Volume (m3)" type="number" name="volume" step="0.01" />
                <InputField labelText="Laadmeter" type="number" name="loadingMeters" step="0.01" />
              </div>

              <Button
                type="submit"
                color="primary"
                iconLeft={<Plus className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Voeg inhoud toe
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateOrderLineContentPage;
