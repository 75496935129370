import { Plus } from '@phosphor-icons/react';
import { Button } from 'components/button/Button';
import { FormikProvider, useFormik } from 'formik';
import { useCreateOrderLineSaleMutation } from 'generated/graphql';
import toast from 'react-hot-toast';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { nullthrows } from '@utils/invariant';
import * as Yup from 'yup';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { ProductTypeComboboxField, productTypeToComboboxItem } from '../../productType/ProductTypeComboboxField';
import { useOrderLine } from '../orderLine/orderLineContext';
import { MinimalGeneralSettings, useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { IMinimalVatRate, VatRateComboboxField, vatRateToComboboxItem } from '../../vatRate/VatRateComboboxField';
import { useOrder } from '../order/orderContext';
import { captureException } from '@sentry/react';

export const createOrderLineSaleSchema = Yup.object().shape({
  productType: Yup.mixed().nullable().required('Vereist'),
  vatRate: Yup.mixed().nullable().required('Vereist'),
  amount: Yup.number().required('Vereist'),
  unitPrice: Yup.number().required('Vereist'),
  externalNote: Yup.string().nullable(),
});

export interface IOrderLineSaleValues {
  productType: ISimpleComboboxItem | null;
  vatRate: ISimpleComboboxItem | null;
  amount: string;
  unitPrice: string;
  externalNote: string;
}

export const getInitialValues = (
  settings: MinimalGeneralSettings,
  saleCount: number,
  customer?: { defaultVatRate?: IMinimalVatRate | null } | null,
): IOrderLineSaleValues => {
  const vatRate = customer?.defaultVatRate ?? settings.defaultVatRate;
  const productType =
    saleCount > 0
      ? saleCount > 1
        ? settings.thirdDefaultProductType
        : settings.secondDefaultProductType
      : settings.defaultProductType;
  return {
    productType: productType ? productTypeToComboboxItem(productType) : null,
    vatRate: vatRate ? vatRateToComboboxItem(vatRate) : null,
    amount: '1',
    unitPrice: '0',
    externalNote: '',
  };
};

export const CreateOrderLineSalePage = () => {
  const { orderLine, refreshData: refetchOrder } = useOrderLine();
  const { order } = useOrder();
  const navigate = useNavigate();
  const { settings } = useMinimalGeneralSettings();
  const [, createOrderLineSaleMutation] = useCreateOrderLineSaleMutation();

  const sales = orderLine.sales;
  const saleCount = sales.length;
  const initialValues = useMemo(() => getInitialValues(settings, saleCount, order.customer), [settings]);

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrderLineSaleSchema,
    onSubmit: async (values) => {
      try {
        const { productType, vatRate, amount, unitPrice, externalNote, ...otherValues } = values;
        const productTypeId = nullthrows(productType?.key, 'Product type is vereist');
        const vatRateId = nullthrows(vatRate?.key, 'Btw type is vereist');
        const result = await createOrderLineSaleMutation({
          orderLineId: orderLine.id,
          data: {
            productTypeId,
            vatRateId,
            amount: parseNumberInput(amount, 2),
            unitPrice: parseNumberInput(unitPrice, 2),
            externalNote: externalNote ?? '',
            ...otherValues,
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          refetchOrder();
          navigate('..');
        }
        toast.success('Verkoop aangemaakt');
      } catch (err: any) {
        captureException(err);
        toast.error('Kon verkoop niet aanmaken: ' + getDisplayError(err));
      }
    },
  });
  const { handleSubmit, isSubmitting, values } = formikbag;

  const dieselSurchargePercentage = order.customer?.dieselSurchargeEnabled
    ? order.customer?.hasCustomDieselSurcharge
      ? (order.customer?.dieselSurchargePercentage ?? 0)
      : settings.dieselSurchargePercentage
    : 0;
  const isDieselSurchargeSelected = values.productType?.key === settings.dieselSurchargeProductType?.id;
  useEffect(() => {
    if (isDieselSurchargeSelected) {
      const baseProduct = sales.find((p) => p.productType.id === settings.dieselSurchargeBaseProductType?.id);
      if (baseProduct) {
        const surchargeValue = (baseProduct.unitPrice / 100) * (dieselSurchargePercentage / 100);
        formikbag.setFieldValue('unitPrice', formatNumber(surchargeValue, 2));
      }
    }
  }, [values.productType]);

  return (
    <>
      <h1 className="heading-one mb-4">Voeg verkoop toe</h1>

      <FormikProvider value={formikbag}>
        <form onSubmit={handleSubmit}>
          <ProductTypeComboboxField labelText="Product" name="productType" />

          <div className="flex gap-2">
            <div style={{ flex: 2 }}>
              <InputField labelText="Aantal" type="number" name="amount" step="0.01" />
            </div>
            <div style={{ flex: 2 }}>
              <InputField labelText="Eenheidsprijs" type="number" name="unitPrice" step="0.01" />
            </div>
            <div style={{ flex: 3 }}>
              <VatRateComboboxField labelText="Btw Tarief" name="vatRate" />
            </div>
          </div>

          <InputField labelText="Extra info" type="text" name="externalNote" spellCheck={true} />

          <Button
            type="submit"
            color="primary"
            iconLeft={<Plus className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Voeg verkoop toe
          </Button>
        </form>

        {isDieselSurchargeSelected && (
          <div className="mt-4 text-sm">{`Diesel toeslag voor klant is ${formatNumber(
            dieselSurchargePercentage,
            2,
          )}%`}</div>
        )}
      </FormikProvider>
    </>
  );
};
