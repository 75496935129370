import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { usePackageTypesQuery, useUpdatePackageTypeMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { getDisplayError } from '../../../utils/get-display-error';
import { nullthrows } from '../../../utils/invariant';
import { captureException } from '@sentry/react';

const createPackageTypeSchema = Yup.object().shape({
  id: Yup.string().required('Vereist'),
  nameNl: Yup.string().required('Vereist'),
  nameFr: Yup.string().required('Vereist'),
  nameEn: Yup.string().required('Vereist'),
  nameDe: Yup.string().required('Vereist'),
});

interface IPackageTypeValues {
  id: string;
  nameNl: string;
  nameFr: string;
  nameEn: string;
  nameDe: string;
}

interface IUpdatePackageTypeFormProps {
  id: string;
  onComplete?: (values: IPackageTypeValues) => void;
  onCancel?: () => void;
}

export const UpdatePackageTypeForm: React.FC<IUpdatePackageTypeFormProps> = (props) => {
  const { id, onComplete, onCancel } = props;
  const [{ data }] = usePackageTypesQuery({
    requestPolicy: 'cache-and-network',
  });
  const [, updatePackageTypeMutation] = useUpdatePackageTypeMutation();

  const packageType = nullthrows(
    data?.packageTypes.find((v) => v.id === id),
    'Package type not found',
  );
  const initialValues: IPackageTypeValues = useMemo(() => {
    return {
      id: packageType.id,
      nameNl: packageType.nameNl,
      nameFr: packageType.nameFr,
      nameEn: packageType.nameEn,
      nameDe: packageType.nameDe,
    };
  }, [packageType]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createPackageTypeSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const result = await updatePackageTypeMutation({
            id: packageType.id,
            data: patch,
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            onComplete?.(values);
          }
          toast.success('Verpakking type aangepast');
        } catch (err: any) {
          captureException(err);
          toast.error('Kon verpakking type niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, submitForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText="Code" type="text" name="id" />
            <InputField labelText="Naam NL" type="text" name="nameNl" />
            <InputField labelText="Naam EN" type="text" name="nameEn" />
            <InputField labelText="Naam FR" type="text" name="nameFr" />
            <InputField labelText="Naam DE" type="text" name="nameDe" />

            <div className="flex justify-between">
              {onCancel && (
                <div>
                  <Button isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
                    Annuleer
                  </Button>
                </div>
              )}
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onTrigger={submitForm}
              >
                Pas verpakking type aan
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
